import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ConnectionService } from '../../services/connection.service';
import { AuthService } from '../../services/auth.service';
import { EventModel, User } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { EventTicketModel, MarketOfferModel, TicketModel } from '../models';
import { ToastService } from '../../services/toast.service';
import { ModalsService } from '../../modals/modals.service';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.scss'],
})
export class MyTicketsComponent implements OnInit {
  user: User;
  eventId: number;
  eventAndTicket: EventTicketModel;
  ticketList: Array<TicketModel>;
  event: EventModel;

  constructor(
    public dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalsService,
    private router: Router,
    private auth: AuthService,
    private toast: ToastService,
    private connection: ConnectionService
  ) {
    this.ticketList = [];
  }

  ngOnInit(): void {
    // Check event ID
    if (this.activatedRoute.snapshot.queryParamMap.has('event')) {
      this.eventId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('event'), 10);
    } else {
      this.router.navigate(['exchange/my-events']);
    }
    // get user
    this.auth.getUserLogged$().subscribe(response => {
      if (typeof response === 'boolean') {
        return;
      }
      this.user = response;
    });
    // get tickets
    this.connection.getCustomerTicketsFromEvent(this.eventId).subscribe(response => {
      this.eventAndTicket = response;
      this.ticketList = response.tickets;
      if (!this.ticketList.length) {
        const title = 'System Message';
        const message = 'You have no eligible tickets to list on the Ticket Exchange.';
        this.modalService.info(title, message, null, () => {});
      }
    });
  }

  // create consign back
  releaseTicket(ticket: TicketModel): void {
    const title = 'Continue?';
    const accept = 'Continue';
    const message = 'Are you sure you wish to list your ticket?';
    this.modalService.info(
      title,
      message,
      accept,
      () => {
        this.connection.postCreateConsignBack(this.eventId, ticket).subscribe(response => {
          this.toast.showSuccess('Ticket has been listed');
          const index = this.ticketList.findIndex(item => {
            return item.id === ticket.id;
          });
          this.ticketList[index].currentlyOnMarketOffer = true;
        });
      },
      'Return'
    );
  }

  // recover ticket from consign back
  recoverTicket(marketOffer: MarketOfferModel, ticket: TicketModel): void {
    if (!marketOffer) {
      return;
    }
    const title = 'Continue?';
    const accept = 'Continue';
    const message = 'Are you sure you wish to delist your ticket?';
    this.modalService.info(
      title,
      message,
      accept,
      () => {
        this.connection.deleteConsignBack(marketOffer.id, ticket.buyer_type.id).subscribe(response => {
          this.toast.showSuccess('Ticket has been recovered');
          const index = this.ticketList.findIndex(item => {
            return item.id === ticket.id;
          });
          this.ticketList[index].currentlyOnMarketOffer = false;
        });
      },
      'Return'
    );
  }
}
