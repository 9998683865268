import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  user;

  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.auth.getUserLogged$().subscribe(user => {
      this.user = user;
    });
  }

  isAdmin() {
    if (this.user) {
      return this.user.hasOwnProperty('admin_takeover');
    }
  }
}
