import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';
import { User } from '../../models';
import { first } from 'rxjs/operators';
import { TDCInfoModel } from '../../models/user.model';

@Component({
  selector: 'app-select-friends',
  templateUrl: './select-friends.component.html',
  styleUrls: ['./select-friends.component.scss'],
})
export class SelectFriendsComponent implements OnInit, OnDestroy {
  fromTransaction: number;
  eventId;
  friendsList: Array<any> = [];
  userData: User;
  venue: string;
  isMembershipBuy: boolean;

  selectedFriends = {};
  customerAssociationsArray = [];

  get friendsSelectedCount() {
    return Object.keys(this.selectedFriends).length;
  }

  getIsUserDisabled(association) {
    if (
      this.dataService.associationsNotAllowed &&
      this.dataService.associationsNotAllowed[this.eventId] &&
      this.dataService.associationsNotAllowed[this.eventId][association.id]
    ) {
      return true;
    }
    return false;
  }

  userIsAllowedBuyMembership(info: TDCInfoModel): boolean {
    return !!(this.isMembershipBuy && info.memberships && info.memberships.length);
  }

  constructor(
    public dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private connection: ConnectionService
  ) {
    this.isMembershipBuy = false;
  }

  ngOnInit(): void {
    this.fromTransaction = this.activatedRoute.snapshot.params.transaction || null;
    // Check event ID
    if (this.activatedRoute.snapshot.params.event) {
      this.eventId = this.activatedRoute.snapshot.params.event;
      this.venue = this.activatedRoute.snapshot.params.venue;
      this.dataService.lastEvent = this.eventId;
    } else {
      this.router.navigate(['buy-tickets']);
    }

    // Get user data
    this.auth
      .getUserLogged$()
      .pipe(first())
      .subscribe(
        response => {
          if (typeof response === 'boolean') return;
          this.userData = response;
        },
        error => {
          console.error(error);
        }
      );

    // Get event information
    if (!Object.values(this.dataService.eventsList).length) {
      this.connection
        .getEventsList()
        .pipe(first())
        .subscribe(response => {
          response.forEach(event => {
            this.dataService.eventsList[event.id] = event;
          });
          // si es invetoried, NO es un membership buy, por lo tanto devolvemos false.
          this.isMembershipBuy = !this.dataService.eventsList[this.eventId].inventoried;
        });
    } else {
      // let's see if it is a non-inventory event to define the rules for choosing friends.
      // si es invetoried, NO es un membership buy, por lo tanto devolvemos false.
      this.isMembershipBuy = !this.dataService.eventsList[this.eventId].inventoried;
    }

    // Get customer associations / friends list
    if (!Object.keys(this.dataService.customerAssociationsFull).length) {
      this.connection.getCustomerAssociations(true).subscribe(
        response => {
          if (response && response.length) {
            response.forEach(association => {
              this.dataService.customerAssociationsFull[association.associate_id] = association;
              this.dataService.customerAssociations[association.associate_id] = association;
            });
          }
          this.customerAssociationsArray = Object.values(this.dataService.customerAssociationsFull);
        },
        error => {
          console.error(error);
        }
      );
    } else {
      this.customerAssociationsArray = Object.values(this.dataService.customerAssociationsFull);
    }
  }

  selectFriend(associateId) {
    if (this.selectedFriends[associateId]) {
      delete this.selectedFriends[associateId];
    } else {
      if (this.isMembershipBuy) this.selectedFriends = {};
      this.selectedFriends[associateId] = true;
    }
  }

  continue() {
    if (this.eventId) {
      this.router.navigate([
        '/buy-tickets/select-seats',
        {
          event: this.eventId,
          associations: Object.keys(this.selectedFriends),
          venue: this.venue,
          transaction: this.fromTransaction,
        },
      ]);
    }
  }

  activeContinue(): boolean {
    let disable = true;
    // si es un evento non inventoried, solo puedes seleccionar una persona (tu mismo o algun colega, pero solo 1)
    if (this.isMembershipBuy && Object.keys(this.selectedFriends).length === 1) {
      disable = false;
    }
    // si no es un evento non inventoried, puedes selecionar tantos como quieras, pero minimo 1. obvio.
    if (!this.isMembershipBuy && Object.keys(this.selectedFriends).length > 0) {
      disable = false;
    }
    return disable;
  }

  ngOnDestroy(): void {
    if (this.selectedFriends[this.userData.tdc_info.id]) {
      this.dataService.customerAssociations[this.userData.tdc_info.id] = this.userData;
      this.dataService.customerAssociations[this.userData.tdc_info.id].associate_id = this.userData.tdc_info.id;
      this.dataService.customerAssociations[this.userData.tdc_info.id].name = this.userData.tdc_info.account_name;
    }
  }
}
