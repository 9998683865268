<div class="h-100">
  <!-- CLASSIC COMPONENT TICKETS SALES -->
  <app-select-seats *ngIf="flowProcess === 'TICKETS' else membership" [isAwayEvent]="isAwayEvent" [fromTransaction]="fromTransaction" [eventId]="eventId"></app-select-seats>

  <!-- IF EVENTS IS NOT INVENTORIED SHOW MEMBERSHIPS BUY COMPONENT-->
  <ng-template #membership>
    <app-memberships *ngIf="flowProcess === 'MEMBERSHIPS'" [event]="event" [fromTransaction]="fromTransaction" [listOfCustomers]="associations"></app-memberships>
  </ng-template>

</div>
