<div id="map-legend" class="visible">
  <div class="map-legend-header">
    <span>Legend</span>
  </div>
  <div class="map-legend-body">
    <ng-container *ngFor="let holdCode of listOfHolCodes">
      <div class="map-legend-body-item">
        <div class="dot" [style.background-color]="holdCode.color"></div>
        <div>{{ holdCode.name | titlecase }}</div>
      </div>
    </ng-container>
  </div>
  <div class="map-legend-footer" (click)="toggleLegend()">
    <div class="flex j-center icon-container">
      <div id="arrow" class="icon arrow arrow-close icon-white"></div>
    </div>
  </div>
</div>
