import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-menu',
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.scss'],
})
export class ButtonMenuComponent implements OnInit {
  open: boolean;

  constructor() {}

  ngOnInit(): void {
    this.open = false;
  }

  openOverlay(div: HTMLDivElement): void {
    const el: HTMLElement = document.getElementById('overlay-menu');
    el.classList.toggle('visible');
    this.open = !this.open;
    div.classList.toggle('change');
  }
}
