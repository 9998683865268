<div class="pb-container container">
  <a [routerLink]="['/exchange/']">Go back</a>
  <div class="content-title" style="margin-bottom: 24px;"><span>Event Schedule</span></div>
  <div class="flex f-row flex-wrap" >
    <div class="badge badge-pill" *ngFor="let venue of venuesAvailables | keyvalue" [class.badge-active]="venue.value.active" (click)="onSelectVenue(venue.key)">
      {{ venue.value.name | titlecase }}
    </div>
  </div>
  <div class="list-header-container desktop">
    <div class="list-element bold"><span>Event</span></div>
    <div class="list-element bold"><span>Venue</span></div>
    <div class="list-element bold"><span>Date & Time</span></div>
    <div class="list-element-button"><span></span></div>
  </div>
  <div>
    <app-event [event]="event" [fromTransaction]="fromTransaction" *ngFor="let event of filteredEventsList"></app-event>
  </div>
</div>
