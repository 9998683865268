import { Inject, Injectable } from '@angular/core';
import { CustomerAssociation } from '../models/customer-association.model';
import { CountryDataModel, CustomerAssociationInverseModel, EventModel, SubCountryDataModel } from '../models';
import { environment } from '../../environments/environment';
import { ExchangeEventModel } from '../exchange-tickets/models';
import { AppConfiguration } from '../models/appconfiguration.model';
import { APP_CONFIG } from '../configuration/app.configuration';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  currencyCode = 'GBP';
  sortedEvents = [];
  sortedExchangeEvents = [];
  eventsList: { [key: string]: EventModel } = {};
  exchangeEventsList: { [key: string]: EventModel } = {};
  // tslint:disable-next-line: object-literal-key-quotes
  venuesAvailables: { [key: string]: { name: string; active: boolean } } = { ALL: { name: 'ALL', active: true } };
  // tslint:disable-next-line: object-literal-key-quotes
  exchangeVenuesAvailables: { [key: string]: { name: string; active: boolean } } = {
    ALL: { name: 'ALL', active: true },
  };
  customerAssociations: { [key: string]: CustomerAssociation } = {};
  customerAssociationsInverse: { [key: string]: CustomerAssociationInverseModel } = {};
  customerAssociationsFull: { [key: number]: CustomerAssociation } = {};
  fromTransaction: number = null;
  priceScalesAvailability = {};
  eventMorfology: { [key: number]: Array<string> } = {};
  reverseEventMorfology: { [key: number]: Array<string> } = {};
  isLoaderActive = false;
  eventsAlreadyPickList: Array<number> = [];
  customerEventsList: { [key: number]: Array<ExchangeEventModel> } = {};
  lastEvent;
  lastVenue: string;
  associationsNotAllowed = {};

  countryList: Array<CountryDataModel> = [];
  subCountryDictionary: { [key: string]: Array<SubCountryDataModel> } = {};

  constructor(@Inject(APP_CONFIG) private appConfig: AppConfiguration) {}

  getCustomerBuyerType(date: string, client: string): string {
    const dateTimestamp = new Date(date).getTime();
    const now = new Date().getTime();
    const yearsDifference = (now - dateTimestamp) / 31536000000;
    const buyerTypeList: Array<{ age: number; buyer_type_name: string }> =
      environment.buyer_types[client].buyer_types_list;
    for (const buier of buyerTypeList) {
      if (yearsDifference >= buier.age) {
        return buier.buyer_type_name;
      }
    }
  }

  getFamilyAreaAdultBuyerTypeId(client: string): number {
    return environment.buyer_types[client].family_area_adult_id;
  }

  getExchangeFamilyAreaBuyerTypeIdList(client: string): Array<number> {
    return environment.exchange[client].family_enclosure_buyer_type_id;
  }

  getExchangeFamilyEnclosureBuyerTypeJunior(client: string): Array<number> {
    return environment.exchange[client].family_enclosure_buyer_type_id_junior;
  }

  getExchangeFamilyEnclosureBuyerTypeSenior(client: string): Array<number> {
    return environment.exchange[client].family_enclosure_buyer_type_id_senior;
  }

  // consigue el buyer type partiendo de la fecha de nacimiento, utilizando los buyer types y sus reglas para exchange
  // son ligeramente diferentes, no va por edad, va por rango de fechas.
  getCustomerBuyerTypeExchange(birthday: string, client: string): string {
    const exchangeBuyerTypeHash = environment.exchange[client].buyer_types as {
      [key: string]: { from: string; to: string };
    };
    const birthdayTime = new Date(birthday).getTime();
    for (const key of Object.keys(exchangeBuyerTypeHash)) {
      if (key === 'ADULT') {
        continue;
      } // si es adulto no hace falta hacer el check, cualquiera puede comprar adulto.
      const dates = exchangeBuyerTypeHash[key];
      const dateFromTimestamp = new Date(dates.from).getTime();
      const dateToTimestamp = dates.to === 'today' ? new Date().getTime() : new Date(dates.to).getTime();
      if (birthdayTime >= dateFromTimestamp && birthdayTime <= dateToTimestamp) {
        return key;
      }
    }
    return 'ADULT';
  }

  // Return hash with hold code by client
  getExchangeHoldCodes(client: string): {
    [key: string]: { code: string; name: string; color: string; buyer_type: string; label: string };
  } {
    return environment.exchange[client].hold_codes;
  }

  // TIMER
  time2string(delta) {
    // Days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // Hours
    const hoursNum = Math.floor(delta / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    delta -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(delta / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    delta -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(delta % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    // return days + 'd ' + hours + ':' + minutes + ':' + seconds;
    return hours + ':' + minutes + ':' + seconds;
  }

  isChelseaFC() {
    return this.appConfig.clientName === 'Chelsea FC';
  }

  getEmailDemo(): string {
    return 'rivercityfc@tickets.com';
  }

  getVenues(): Array<string> {
    const availableVenues = ['baseball park'];
    for (const v of Object.entries(this.appConfig.venue)) {
      const name = v[1].name;
      if (name !== '' && name != null) {
        availableVenues.push(name.toLocaleLowerCase());
      }
    }
    return availableVenues;
  }

  getExchangeVenues(): Array<string> {
    // FIXME: Los venues tendrian que venir de la configuracion del cliente :D
    return ['stamford bridge', 'kingsmeadow', 'baseball park', 'river city stadium'];
  }
}
