import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { first } from 'rxjs/operators';
import { User } from '../../models';
import { AppConfiguration } from 'src/app/models/appconfiguration.model';
import { APP_CONFIG } from 'src/app/configuration/app.configuration';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-select-a-path',
  templateUrl: './select-a-path.component.html',
  styleUrls: ['./select-a-path.component.scss'],
})
export class SelectAPathComponent implements OnInit {
  user: User;
  isMenSeasonTicketHolder = false;

  constructor(private auth: AuthService, private dataService: DataService) {}

  ngOnInit(): void {
    this.auth
      .getUserLogged$()
      .pipe(first())
      .subscribe(response => {
        if (typeof response === 'boolean') {
          return;
        }
        this.user = response;
        // Check membership level
        // The List Season Ticket button should only appear if the Supporter logged in has a valid ‘CFC Men's Season Ticket Holder 2022/2023’ on their account.
        // this membership level id is 1044
        if (this.user.tdc_info.memberships.length && this.user.tdc_info.memberships[0].membership_level.id === 1142) {
          this.isMenSeasonTicketHolder = true;
        }
        if (!this.dataService.isChelseaFC()) {
          this.isMenSeasonTicketHolder = true;
        }
      });
  }
}
