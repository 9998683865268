import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalsService } from '../modals/modals.service';
import { AuthService } from '../services/auth.service';
import { ConnectionService } from '../services/connection.service';
import { DataService } from '../services/data.service';
import { Subject } from 'rxjs';
import {
  EventModel,
  MembershipTransactionCustomerModel,
  MembershipTransactionModel,
  ReservationTransactionModel,
  SaleTransactionCustomerModel,
  SaleTransactionModel,
  User,
} from '../models';
import { ExchangeTransactionModel } from '../exchange-tickets/models';
import { PackageService } from '../services/package.service';
import { AdyenService } from '../adyen/adyen.service';
import { GtmService } from '../services/gtm.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  pending = false;
  userData: User;
  status;
  transactionId;
  expirationTime;
  timerObservable = new Subject<string>();
  timer = '00:00:00';
  interval;
  transactionType: 'reservation' | 'sale' | 'membership' | 'exchange' | 'package';
  interfaceEventStatus = {}; // accordeon
  interfaceBuyerTypeByCustomer = {}; // accordeon de buyer types
  saleTransaction: SaleTransactionModel;
  saleTransactionsListByEvent: {
    [key: string]: {
      event: EventModel;
      transactions: Array<SaleTransactionCustomerModel | MembershipTransactionCustomerModel>;
    };
  };
  btnBack: string = 'Buy more tickets';

  reservationTransaction: ReservationTransactionModel;
  membershipTransaction: MembershipTransactionModel;
  exchangeTransaction: ExchangeTransactionModel;

  get getTimer() {
    return this.timer;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    public dataService: DataService,
    private auth: AuthService,
    private router: Router,
    private modalService: ModalsService,
    private connection: ConnectionService,
    private packageService: PackageService,
    private adyenService: AdyenService,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params) {
      if (this.activatedRoute.snapshot.params.type) {
        this.transactionType = this.activatedRoute.snapshot.params.type;
      }
      if (this.activatedRoute.snapshot.params.status && this.activatedRoute.snapshot.params.transaction) {
        this.status = this.activatedRoute.snapshot.params.status;
        this.transactionId = this.activatedRoute.snapshot.params.transaction;
      } else {
        this.router.navigate(['buy-tickets']);
      }
      if (this.transactionId) {
        let getTransactionFunction = this.connection.getSaleTransaction(this.transactionId);
        let getTransactionSubscription;
        if (this.transactionType === 'reservation') {
          getTransactionFunction = this.connection.getReservationTransaction(this.transactionId);
        }
        if (this.transactionType === 'membership') {
          getTransactionFunction = this.connection.getMembershipTransaction(this.transactionId);
        }
        if (this.transactionType === 'package') {
          getTransactionFunction = this.packageService.getTransaction(this.transactionId);
        }
        if (this.status === 'success' || this.status === 'fap') {
          this.pending = true;
          getTransactionSubscription = getTransactionFunction.subscribe(
            response => {
              if (response) {
                if (this.transactionType === 'reservation') {
                  this.reservationTransaction = response;
                } else if (this.transactionType === 'sale') {
                  if (response.sale_type === 'E') {
                    this.exchangeTransaction = response;
                    this.transactionType = 'exchange';
                  } else {
                    this.saleTransaction = response;
                  }
                } else if (this.transactionType === 'membership') {
                  this.membershipTransaction = response;
                } else if (this.transactionType === 'package') {
                  this.btnBack = 'Buy more packages';
                  this.saleTransaction = response;
                }
                if (response.status === 'OK' || response.status === 'FAP') {
                  this.pending = false;
                  // Filtar por evento en caso de ser una SALE TRANSACTION
                  if (this.saleTransaction) {
                    this.saleTransactionsListByEvent = this.filterSaleTransactionsByEvent([
                      this.saleTransaction,
                      ...this.saleTransaction.related_transactions,
                    ]);
                    this.sendBuyTicketPurchaseGTM();
                  } else if (this.membershipTransaction) {
                    this.saleTransactionsListByEvent = this.filterSaleTransactionsByEvent([this.membershipTransaction]);
                    this.sendMembershipPurchaseGTM();
                  } else if (this.exchangeTransaction) {
                    this.saleTransactionsListByEvent = this.filterSaleTransactionsByEvent([this.exchangeTransaction]);
                  }
                  clearInterval(pendingInterval);
                } else if (response.status === 'CA' || response.status === 'FA') {
                  this.router.navigate(['summary', 'error', { transaction: this.transactionId }]);
                  this.status = 'error';
                  this.pending = false;
                  clearInterval(pendingInterval);
                } else {
                  console.log('Transaction still pending.', this.transactionId);
                }
              }
            },
            error => {
              console.error(error);
              // this.pending = false;
              clearInterval(pendingInterval);
              this.router.navigate(['buy-tickets']);
            }
          );
          const pendingInterval = setInterval(() => {
            getTransactionSubscription.unsubscribe();
            getTransactionFunction.subscribe(
              response => {
                if (response) {
                  if (this.transactionType === 'reservation') {
                    this.reservationTransaction = response;
                  } else if (this.transactionType === 'sale') {
                    if (response.sale_type === 'E') {
                      this.exchangeTransaction = response;
                      this.transactionType = 'exchange';
                    } else {
                      this.saleTransaction = response;
                    }
                  }

                  if (this.transactionType === 'membership' && response.status == 'OK') {
                    this.membershipTransaction = response;
                    this.saleTransactionsListByEvent = this.filterSaleTransactionsByEvent([this.membershipTransaction]);
                    this.sendMembershipPurchaseGTM();
                  }

                  if (
                    (this.transactionType == 'sale' || this.transactionType == 'package') &&
                    response.status == 'OK'
                  ) {
                    this.saleTransaction = response;
                    this.saleTransactionsListByEvent = this.filterSaleTransactionsByEvent([
                      this.saleTransaction,
                      ...this.saleTransaction.related_transactions,
                    ]);
                    this.sendBuyTicketPurchaseGTM();
                  }
                  if (response.status === 'OK') {
                    this.auth.getUserLogged(true);
                    this.pending = false;
                    clearInterval(pendingInterval);
                  } else if (response.status === 'CA' || response.status === 'FA') {
                    this.router.navigate(['summary', 'error', { transaction: this.transactionId }]);
                    this.status = 'error';
                    this.pending = false;
                    clearInterval(pendingInterval);
                  } else {
                    console.log('Transaction still pending.', this.transactionId);
                    const isErrorAyden: boolean = this.checkErrorResponseAdyen();
                    if (isErrorAyden) {
                      clearInterval(pendingInterval);
                    }
                  }
                }
              },
              error => {
                console.error(error);
                // this.pending = false;
                clearInterval(pendingInterval);
                this.router.navigate(['buy-tickets']);
              }
            );
          }, 5000);
        } else if (this.status === 'error') {
          this.pending = true;
          getTransactionFunction.subscribe(
            response => {
              if (response) {
                if (response.status !== 'OK') {
                  if (this.transactionType === 'reservation') {
                    this.reservationTransaction = response;
                    this.expirationTime = this.reservationTransaction.tdc_expiration_time;
                  } else if (this.transactionType === 'sale') {
                    if (response.sale_type === 'E') {
                      this.exchangeTransaction = response;
                      this.transactionType = 'exchange';
                    } else {
                      this.saleTransaction = response;
                    }
                    this.expirationTime = response.tdc_expiration_time;
                  } else if (this.transactionType === 'membership') {
                    this.membershipTransaction = response;
                    this.expirationTime = this.membershipTransaction.tdc_expiration_time;
                  } else if (this.transactionType === 'package') {
                    this.saleTransaction = response;
                    this.expirationTime = response.tdc_expiration_time;
                  }
                  this.status = 'error';
                  this.pending = false;
                  this.setTimer();
                  this.setTimerInterval();
                  this.timerObservable.subscribe(timer => {
                    this.timer = timer;
                  });
                } else {
                  this.router.navigate(['summary', 'success', { transaction: this.transactionId }]);
                }
              }
            },
            error => {
              console.error(error);
              this.pending = false;
              this.router.navigate(['buy-tickets']);
            }
          );
        }
      } else {
        this.router.navigate(['buy-tickets']);
      }
    } else {
      this.router.navigate(['buy-tickets']);
    }

    this.getUser();
    // adyen handle redirection, if we are in this page with sessionid and redirectResult
    // we've been redirected through return url of adyen
    // https://docs.adyen.com/online-payments/build-your-integration?platform=Web&integration=Drop-in&version=5.43.0#handle-the-redirect
    if (this.activatedRoute.snapshot.queryParams.sessionId && this.activatedRoute.snapshot.queryParams.redirectResult) {
      this.adyenService.adyenHandleRedirect(
        this.activatedRoute.snapshot.queryParams.sessionId,
        this.activatedRoute.snapshot.queryParams.redirectResult
      );
    }
  }

  getUser() {
    // Get user data
    this.auth.getUserLogged$().subscribe(
      response => {
        if (typeof response === 'boolean') {
          return;
        }
        this.userData = response;
      },
      error => {
        console.error(error);
      }
    );
  }

  listenRoute() {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      console.log(paramMap);
      this.ngOnInit();
    });
  }

  backHome() {
    this.auth.getUserLogged(true).then(response => {
      if (this.exchangeTransaction) {
        this.router.navigate(['exchange']);
      }
      if (this.transactionType === 'package') {
        this.router.navigate(['buy-packages']);
        return;
      }
      this.router.navigate(['buy-tickets']);
    });
  }

  cancelTransaction() {
    if (this.transactionId) {
      const title = 'Continue?';
      const message = 'Your transaction will be cancelled and your seats will be released. Are you sure?';
      const accept = 'Cancel transaction';
      this.modalService.info(title, message, accept, () => {
        let deleteTransactionFunction = this.connection.deleteSaleTransaction(this.transactionId);
        if (this.transactionType === 'reservation') {
          deleteTransactionFunction = this.connection.deleteReservationTransaction(this.transactionId);
        }
        if (this.transactionType === 'membership') {
          deleteTransactionFunction = this.connection.deleteMembershipTransaction(this.transactionId);
        }
        if (
          this.transactionType === 'exchange' &&
          this.exchangeTransaction &&
          this.exchangeTransaction.sale_type === 'E'
        ) {
          deleteTransactionFunction = this.connection.deleteExchangeTransaction(this.transactionId);
        }
        if (this.transactionType === 'package') {
          deleteTransactionFunction = this.packageService.deletePackageTransaction(this.transactionId);
        }
        deleteTransactionFunction.subscribe(
          response => {
            console.log(response);
            this.backHome();
          },
          error => {
            console.error(error);
            this.backHome();
          }
        );
      });
    }
  }

  continueToPayment() {
    let transactionPaymentDataFunction = this.connection.getSaleTransactionPaymentData(this.transactionId);
    if (this.transactionType === 'reservation') {
      transactionPaymentDataFunction = this.connection.getReservationTransactionPaymentData(this.transactionId);
    }
    if (this.transactionType === 'membership') {
      transactionPaymentDataFunction = this.connection.getMembershipTransactionPaymentData(this.transactionId);
    }
    if (this.transactionType === 'exchange' && this.exchangeTransaction && this.exchangeTransaction.sale_type === 'E') {
      transactionPaymentDataFunction = this.connection.getExchangeTransactionPaymentData(this.transactionId);
    }
    if (this.transactionType === 'package') {
      transactionPaymentDataFunction = this.packageService.patchTransaction(this.transactionId);
    }
    transactionPaymentDataFunction.subscribe(
      response => {
        console.log(response);
        this.modalService.adyenPaymentModal(response.session_id, response.session_data, response.return_url);
        this.listenRoute();
      },
      error => {
        console.error(error);
      }
    );
  }

  time2string(delta): string {
    // Days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // Hours
    const hoursNum = Math.floor(delta / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    delta -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(delta / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    delta -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(delta % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    // return days + 'd ' + hours + ':' + minutes + ':' + seconds;
    return hours + ':' + minutes + ':' + seconds;
  }

  setTimer(): void {
    const appointmentTimestamp = new Date(this.expirationTime).getTime();
    const now = new Date().getTime();
    // let diff = Math.abs(appointmentTimestamp - now) / 1000;
    let diff = (appointmentTimestamp - now) / 1000;
    if (diff <= 0) {
      diff = 0;
      clearInterval(this.interval);
      this.modalService.info(
        'Transaction expired',
        'Your transaction has expired. Please, click the button below to return to the home page.',
        'Return',
        () => {
          this.auth.getUserLogged(true).then(() => {
            this.router.navigate(['buy-tickets']);
          });
        },
        null,
        null,
        null,
        true
      );
    }
    const timeString = this.time2string(diff);
    this.timerObservable.next(timeString);
  }

  setTimerInterval(): void {
    this.interval = setInterval(() => {
      this.setTimer();
    }, 1000);
  }

  // Filter sale transaction by event
  private filterSaleTransactionsByEvent(salesList: Array<SaleTransactionModel | MembershipTransactionModel>): {
    [key: string]: {
      event: EventModel;
      transactions: Array<SaleTransactionCustomerModel | MembershipTransactionCustomerModel>;
    };
  } {
    const result = {};
    for (const sale of salesList) {
      if (!result.hasOwnProperty(sale.event_info.id)) {
        result[sale.event_info.id] = {
          event: sale.event_info,
          transactions: [],
        };
        this.interfaceEventStatus[sale.event_info.id] = false;
      }
      if (this.isSalesOrMembershipTransaction(sale)) {
        result[sale.event_info.id].transactions.push(...sale.sale_transaction_customers);
      } else {
        result[sale.event_info.id].transactions.push(...sale.membership_transaction_customers);
      }

      // prepare the object for accordion operation on template
      // SOLO si es sale transaction
      if (this.isSalesOrMembershipTransaction(sale)) {
        result[sale.event_info.id].transactions.forEach((transaction: SaleTransactionCustomerModel) => {
          let customerName = transaction.name;
          transaction.buyer_type_info.forEach(info => {
            let buyerTypeName = info.buyer_type_name;
            if (!this.interfaceBuyerTypeByCustomer.hasOwnProperty(customerName))
              this.interfaceBuyerTypeByCustomer[transaction.name] = {};
            if (!this.interfaceBuyerTypeByCustomer[customerName].hasOwnProperty(buyerTypeName))
              this.interfaceBuyerTypeByCustomer[customerName][buyerTypeName] = false;
          });
        });
      }
    }

    return result;
  }

  private isSalesOrMembershipTransaction(
    obj: SaleTransactionModel | MembershipTransactionModel
  ): obj is SaleTransactionModel {
    return (obj as SaleTransactionModel).sale_transaction_customers !== undefined;
  }

  showSeatPrice(seats: string): boolean {
    const split = seats.split('-');
    return split.length > 1;
  }

  // En caso de que la URL devuelta por ayden summary/error volvemos a ejecutar el ngOninit y limpiamos el pendingInverval
  checkErrorResponseAdyen(): boolean {
    const status = this.activatedRoute.snapshot.params.status;
    if (status == 'error') {
      this.ngOnInit();
      return true;
    }
    return false;
  }

  sendBuyTicketPurchaseGTM() {
    if (this.transactionType == 'sale') {
      this.gtmService.sendPurchase(this.saleTransaction);
    }
  }

  sendMembershipPurchaseGTM() {
    if (this.transactionType == 'membership') {
      this.gtmService.sendMembershipPurchase(this.membershipTransaction);
    }
  }
}
