import { Injectable } from '@angular/core';
import { PurchaseGTM, PurchaseItem } from '../models/purchase-gtm.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MembershipTransactionModel, SaleTransactionModel } from '../models';
import { AddToCartGTM, AddToCartItem } from '../models/add-to-cart-gtm.model';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  constructor(private gtmService: GoogleTagManagerService) {}

  sendPurchase(saleTransaction: SaleTransactionModel) {
    // We declare the main object and the list of items
    let purchaseItemList: PurchaseItem[] = [];

    // First, we iterate sale_transaction_customers events
    saleTransaction.sale_transaction_customers.forEach(itemTransaction => {
      // We iterate buyerType
      itemTransaction.buyer_type_info.forEach(buyerType => {
        // Body
        let purchaseItem: PurchaseItem = {
          code: saleTransaction.event_info.code,
          item_id: saleTransaction.event_info.id,
          item_name: saleTransaction.event_info.name,
          item_brand: saleTransaction.event_info.event_category,
          item_category: saleTransaction.event_info.description,
          item_category2: saleTransaction.event_info.date,
          item_category5: saleTransaction.event_info.venue,
          index: purchaseItemList.length,
          price: buyerType.total_price,
          currency: 'GBP',
          quantity: 1,
          item_category3: buyerType.seats[0].tdc_section,
          item_category4: buyerType.buyer_type_code,
        };
        purchaseItemList.push(purchaseItem);
      });
    });

    // Then, we iterate related_transactions events
    saleTransaction.related_transactions.forEach(item => {
      item.sale_transaction_customers.forEach(itemTransaction => {
        itemTransaction.buyer_type_info.forEach(buyerType => {
          let purchaseItem: PurchaseItem = {
            code: item.event_info.code,
            item_id: item.event_info.id,
            item_name: item.event_info.name,
            item_brand: item.event_info.event_category,
            item_category: item.event_info.description,
            item_category2: item.event_info.date,
            item_category5: item.event_info.venue,
            index: purchaseItemList.length,

            price: buyerType.total_price,
            currency: 'GBP',
            quantity: 1,
            item_category3: buyerType.seats[0].tdc_section,
            item_category4: buyerType.buyer_type_code,
          };
          purchaseItemList.push(purchaseItem);
        });
      });
    });

    let purchaseGTM: PurchaseGTM = {
      transaction_id: saleTransaction.payment_identifier,
      currency: 'GBP',
      value: saleTransaction.total_price,
      shipping: saleTransaction.delivery_method_price,
      items: purchaseItemList,
    };

    const gtmTag = {
      event: 'purchase',
      data: purchaseGTM,
    };
    this.gtmService.pushTag(gtmTag);
  }

  // We need to send the last object inserted
  addToCartBuyTicket(saleTransaction: SaleTransactionModel) {
    let addToCartItemList: AddToCartItem[] = [];

    // We have only one object
    if (saleTransaction.related_transactions.length == 0) {
      saleTransaction.sale_transaction_customers.forEach(itemTransaction => {
        // We iterate buyerType
        itemTransaction.buyer_type_info.forEach(buyerType => {
          // Body
          let purchaseItem: AddToCartItem = {
            code: saleTransaction.event_info.code,
            item_id: saleTransaction.event_info.id,
            item_name: saleTransaction.event_info.name,
            item_brand: saleTransaction.event_info.event_category,
            item_category: saleTransaction.event_info.description,
            item_category2: saleTransaction.event_info.date,
            item_category5: saleTransaction.event_info.venue,
            index: addToCartItemList.length,
            price: buyerType.total_price,
            currency: 'GBP',
            quantity: 1,
            item_category3: buyerType.seats[0].tdc_section,
            item_category4: buyerType.buyer_type_code,
          };
          addToCartItemList.push(purchaseItem);
        });
      });
    } else {
      // We get the last element of the array
      const lastRelatedTransaction =
        saleTransaction.related_transactions[saleTransaction.related_transactions.length - 1];

      // Then, we iterate related_transactions events
      lastRelatedTransaction.sale_transaction_customers.forEach(itemTransaction => {
        itemTransaction.buyer_type_info.forEach(buyerType => {
          let purchaseItem: PurchaseItem = {
            code: lastRelatedTransaction.event_info.code,
            item_id: lastRelatedTransaction.event_info.id,
            item_name: lastRelatedTransaction.event_info.name,
            item_brand: lastRelatedTransaction.event_info.event_category,
            item_category: lastRelatedTransaction.event_info.description,
            item_category2: lastRelatedTransaction.event_info.date,
            item_category5: lastRelatedTransaction.event_info.venue,
            index: addToCartItemList.length,

            price: buyerType.total_price,
            currency: 'GBP',
            quantity: 1,
            item_category3: buyerType.seats[0].tdc_section,
            item_category4: buyerType.buyer_type_code,
          };
          addToCartItemList.push(purchaseItem);
        });
      });
    }

    // We build the object and send it
    let addToCart: AddToCartGTM = {
      currency: 'GBP',
      value: saleTransaction.total_price,
      items: addToCartItemList,
    };
    const gtmTag = {
      event: 'add_to_cart',
      data: addToCart,
    };
    this.gtmService.pushTag(gtmTag);
  }

  sendMembershipPurchase(membershipTransaction: MembershipTransactionModel) {
    let purchaseItemList: PurchaseItem[] = [];

    let purchaseItem: PurchaseItem = {
      code: membershipTransaction.event_info.code,
      item_id: membershipTransaction.event_info.id,
      item_name: membershipTransaction.event_info.name,
      item_brand: membershipTransaction.event_info.event_category,
      item_category: membershipTransaction.event_info.description,
      item_category2: membershipTransaction.event_info.date,
      item_category5: membershipTransaction.event_info.venue,
      index: purchaseItemList.length,
      price: membershipTransaction.membership_transaction_customers[0].price,
      currency: 'GBP',
      quantity: 1,
      item_category3: membershipTransaction.membership_transaction_customers[0].buyer_type,
      item_category4: null,
    };
    purchaseItemList.push(purchaseItem);

    const gtmTag = {
      event: 'purchase',
      data: purchaseItemList,
    };
    this.gtmService.pushTag(gtmTag);
  }

  addToCartMembership(membershipTransaction: MembershipTransactionModel) {
    let addToCartItemList: AddToCartItem[] = [];

    let purchaseItem: PurchaseItem = {
      code: membershipTransaction.event_info.code,
      item_id: membershipTransaction.event_info.id,
      item_name: membershipTransaction.event_info.name,
      item_brand: membershipTransaction.event_info.event_category,
      item_category: membershipTransaction.event_info.description,
      item_category2: membershipTransaction.event_info.date,
      item_category5: membershipTransaction.event_info.venue,
      index: addToCartItemList.length,
      price: membershipTransaction.membership_transaction_customers[0].price,
      currency: 'GBP',
      quantity: 1,
      item_category3: membershipTransaction.membership_transaction_customers[0].buyer_type,
      item_category4: null,
    };
    addToCartItemList.push(purchaseItem);

    // We build the object and send it
    let addToCart: AddToCartGTM = {
      currency: 'GBP',
      value: membershipTransaction.total_price,
      items: addToCartItemList,
    };
    const gtmTag = {
      event: 'add_to_cart',
      data: addToCart,
    };
    this.gtmService.pushTag(gtmTag);
  }
}
