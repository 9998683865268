import { Component, OnInit } from '@angular/core';
import { EventModel } from '../../models';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ConnectionService } from '../../services/connection.service';

@Component({
  selector: 'app-select-a-event',
  templateUrl: './select-a-event.component.html',
  styleUrls: ['./select-a-event.component.scss'],
})
export class SelectAEventComponent implements OnInit {
  fromTransaction: number;
  eventsList: Array<EventModel> = [];
  venuesAvailables: { [key: string]: { name: string; active: boolean } } = {};
  filteredEventsList: Array<EventModel> = [];

  constructor(
    public dataService: DataService,
    private router: Router,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private connection: ConnectionService
  ) {}

  ngOnInit(): void {
    this.fromTransaction = this.activatedRoute.snapshot.params.transaction || null;
    // Get user data
    this.auth.getUserLogged$().subscribe(
      response => {
        // tslint:disable-next-line: no-string-literal
        if (response['exchange_transactions'] && response['exchange_transactions'].length) {
          // tslint:disable-next-line: no-string-literal
          this.router.navigate(['/exchange/checkout'], {
            queryParams: { transaction: response['exchange_transactions'][0].id },
          });
        }
      },
      error => {
        console.error(error);
      }
    );
    // this.eventsList = Object.values(this.dataService.eventsList);
    this.eventsList = this.dataService.sortedExchangeEvents;
    this.venuesAvailables = this.filterVenuesAvailables(this.dataService.exchangeVenuesAvailables);
    this.filteredEventsList = this.eventsList;
    if (!this.eventsList.length) {
      this.connection.getEventsListExchange().subscribe(response => {
        console.log(response);
        this.dataService.sortedExchangeEvents = response;
        // this.dataService.sortedEvents.push({"id":9999,"name":"Kingsmeadow TEST","description":null,"date":"2022-05-31T15:00:00.000+01:00","venue":"Kingsmeadow"})
        this.dataService.sortedExchangeEvents.forEach(event => {
          this.dataService.exchangeEventsList[event.id] = event;
          // Seleccionamos los venues disponibles en base a los eventos.
          this.dataService.exchangeVenuesAvailables[event.venue] = { name: event.venue, active: false };
        });
        this.venuesAvailables = this.filterVenuesAvailables(this.dataService.exchangeVenuesAvailables);
        this.eventsList = this.dataService.sortedExchangeEvents;
        this.filteredEventsList = this.eventsList;
        console.log(this.filteredEventsList);
      });
    }
  }

  onSelectVenue(venue: string): void {
    const status = this.venuesAvailables[venue].active ? !this.venuesAvailables[venue].active : true; // mejorable seguramente
    this.selectVenue(venue, status);
  }

  selectVenue(venue: string, status: boolean): void {
    // Si es false, estamos deseleccionamos, por lo que debemos auto seleccionar la opcion 'All' y hacer reset de la lista de eventos
    const all = !status;
    this.unSelectVenue(all);
    this.venuesAvailables[venue].active = status;
    this.filterVenue(venue, all); // Apply filter
  }

  private filterVenuesAvailables(venues: { [key: string]: { name: string; active: boolean } }): {
    [key: string]: { name: string; active: boolean };
  } {
    const venuesValid = {};
    const validVenus4Exchange = this.dataService.getExchangeVenues();
    validVenus4Exchange.push('all');
    for (const recinto of Object.entries(venues)) {
      const [key, value] = recinto;
      if (validVenus4Exchange.includes(value.name.toLowerCase())) {
        venuesValid[key] = value;
      }
    }
    return venuesValid;
  }

  unSelectVenue(all: boolean = false): void {
    Object.keys(this.venuesAvailables).forEach(key => {
      this.venuesAvailables[key].active = false;
    });
    if (all) {
      this.venuesAvailables['ALL'].active = true;
    }
  }

  filterVenue(venue: string, all: boolean): void {
    if (venue != 'ALL' && !all) {
      this.filteredEventsList = this.eventsList.filter(event => {
        return event.venue === venue;
      });
    } else {
      this.filteredEventsList = this.eventsList;
    }
  }
}
