import { InjectionToken } from '@angular/core';
import { AppConfiguration } from '../models/appconfiguration.model';

export const APP_CONFIG = new InjectionToken<AppConfiguration>('App Configuration');

export const AppConfig: AppConfiguration = {
  clientName: '',
  applicationName: '',
  accentColor: '',
  hoverColor: '',
  menuColor: '',
  darkTheme: false,
  backgroundImageUrl: '',
  logoUrl: '',
  favIco: '',
  venue: {
    male: {
      venueId: '',
      mapId: '',
      name: '',
    },
    female: {
      venueId: '',
      mapId: '',
      name: '',
    },
  },
  idleService: true,
};
