import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../models';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ConnectionService } from '../../services/connection.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-select-a-friend',
  templateUrl: './select-a-friend.component.html',
  styleUrls: ['./select-a-friend.component.scss'],
})
export class SelectAFriendComponent implements OnInit, OnDestroy {
  fromTransaction: number;
  eventId;
  friendsList: Array<any> = [];
  userData: User;
  venue: string;

  selectedFriends = {};
  customerAssociationsArray = [];

  get friendsSelectedCount() {
    return Object.keys(this.selectedFriends).length;
  }

  getIsUserDisabled(association) {
    return !!(
      this.dataService.associationsNotAllowed &&
      this.dataService.associationsNotAllowed[this.eventId] &&
      this.dataService.associationsNotAllowed[this.eventId][association.id]
    );
  }

  constructor(
    public dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private connection: ConnectionService
  ) {}

  ngOnInit(): void {
    this.fromTransaction = this.activatedRoute.snapshot.params.transaction || null;
    // Check event ID
    if (this.activatedRoute.snapshot.queryParamMap.has('event')) {
      this.eventId = this.activatedRoute.snapshot.queryParams.event;
      this.venue = this.activatedRoute.snapshot.queryParams.venue;
      this.dataService.lastEvent = this.eventId;
    } else {
      this.router.navigate(['exchange']);
    }

    // Get user data
    this.auth.getUserLogged$().subscribe(
      response => {
        if (typeof response === 'boolean') return;
        this.userData = response;
      },
      error => {
        console.error(error);
      }
    );

    // Get event information
    if (!Object.values(this.dataService.exchangeEventsList).length) {
      this.connection
        .getEventsListExchange()
        .pipe(first())
        .subscribe(response => {
          response.forEach(event => {
            this.dataService.exchangeEventsList[event.id] = event;
          });
        });
    }

    // Get customer associations / friends list
    if (!Object.keys(this.dataService.customerAssociationsFull).length) {
      this.connection.getCustomerAssociations(true).subscribe(
        response => {
          console.log(response);
          if (response && response.length) {
            response.forEach(association => {
              this.dataService.customerAssociationsFull[association.associate_id] = association;
              this.dataService.customerAssociations[association.associate_id] = association;
            });
          }
          this.customerAssociationsArray = Object.values(this.dataService.customerAssociationsFull);
          console.log(this.customerAssociationsArray);
        },
        error => {
          console.error(error);
        }
      );
    } else {
      this.customerAssociationsArray = Object.values(this.dataService.customerAssociationsFull);
    }
  }

  selectFriend(associateId) {
    if (this.selectedFriends[associateId]) {
      delete this.selectedFriends[associateId];
    } else {
      this.selectedFriends[associateId] = true;
    }
  }

  continue() {
    if (this.eventId) {
      this.router.navigate(['/exchange/select-a-seat'], {
        queryParams: {
          event: this.eventId,
          people: Object.keys(this.selectedFriends).join(';'),
          venue: this.venue,
          transaction: this.fromTransaction,
        },
      });
    }
  }

  activeContinue(): boolean {
    let disable = true;
    // si no es un evento non inventoried, puedes selecionar tantos como quieras, pero minimo 1. obvio.
    if (Object.keys(this.selectedFriends).length > 0) {
      disable = false;
    }
    return disable;
  }

  ngOnDestroy(): void {
    if (this.selectedFriends[this.userData.tdc_info.id]) {
      this.dataService.customerAssociations[this.userData.tdc_info.id] = this.userData;
      this.dataService.customerAssociations[this.userData.tdc_info.id].associate_id = this.userData.tdc_info.id;
      this.dataService.customerAssociations[this.userData.tdc_info.id].name = this.userData.tdc_info.account_name;
    }
  }
}
