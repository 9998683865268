<div class="full-container-wbar">
  <a
    [routerLink]="[
      '/buy-tickets/select-friends',
      { event: eventId, venue: this.dataService?.lastVenue, transaction: fromTransaction }
    ]"
    style="margin-left: 16px"
    >Go back</a
  >
  <div class="flex wrap-mobile" style="min-height: 70vh">
    <div class="rounded-block map-block">
      <div
        *ngIf="eventId && dataService.eventsList && dataService.eventsList[eventId]"
        class="rounded-block-header-container">
        <div class="content-title">
          <span>{{ dataService.eventsList[eventId].venue }}</span>
        </div>
        <div class="content-subtitle"><span>Select your price scale:</span></div>
      </div>
      <div class="flex wrap-mobile pricescales-container">
        <div
          *ngIf="priceScalesArray.length"
          class="pricescales-bar"
          [class.full-w]="isAwayEvent"
          [class.full-h]="isAwayEvent">
          <div
            (click)="selectPriceScale(priceScale.id)"
            (mouseenter)="priceScaleEnter(priceScale.id)"
            (mouseleave)="priceScaleLeave()"
            [ngClass]="{ selected: selectedPriceScale.getValue() === priceScale.id }"
            class="pricescale-element rounded-block mb16"
            [id]="priceScale.id"
            *ngFor="let priceScale of priceScalesArray">
            <div>
              <div class="bold">
                <span>{{ priceScale.name }}</span>
              </div>
            </div>
            <div class="content-subinfo font-size-14">
              from {{ getMinPrice(priceScale.prices) | currency : dataService.currencyCode }}
            </div>
          </div>
        </div>
        <ng-container *ngIf="!isAwayEvent">
          <!-- IF event is in other venue, dont show venue -->
          <div id="viewer-container"></div>
          <div class="map-interface-buttons-container">
            <button id="map-interface-button-plus" class="rounded-block map-interface-button">+</button>
            <button id="map-interface-button-minus" class="rounded-block map-interface-button">-</button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="rounded-block sub-interface-block desktop">
      <div class="sub-interface-container">
        <div>
          <!-- EVENT -->
          <div
            *ngIf="eventId && dataService.eventsList && dataService.eventsList[eventId]"
            class="rounded-block-header-container">
            <div style="display: flex; justify-content: start">
              <div
                *ngIf="dataService.eventsList[eventId].description"
                class="popover-placeholder"
                [popper]="tooltipcontent"
                [popperTrigger]="'hover'"
                [popperPlacement]="'bottom'"
                [popperApplyClass]="'popperStyle'">
                <button class="icon-container">
                  <div class="icon plus">i</div>
                </button>
              </div>
              <span class="content-title">{{ dataService.eventsList[eventId].name }}</span>
            </div>
            <popper-content #tooltipcontent>
              <p>
                {{ dataService.eventsList[eventId].description }}
              </p>
            </popper-content>
            <div>
              <div class="content-subtitle">
                <span>{{ dataService.eventsList[eventId].date | date : 'dd/MM/yyyy hh:mm a' }}</span>
              </div>
              <div class="content-subtitle">
                <span>{{ dataService.eventsList[eventId].venue }}</span>
              </div>
            </div>
          </div>
          <!-- tickets -->
          <div *ngIf="dataService.customerAssociations" style="margin-top: 16px; max-height: 550px; overflow: auto">
            <div *ngFor="let associationId of associations" class="rounded-block list-element-container">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  width: 100%;
                  align-items: baseline;
                "
                class="suppoter-information">
                {{ dataService.customerAssociations[associationId].tdc_info.first_name }}
                {{ dataService.customerAssociations[associationId].tdc_info.last_name }}
                <span *ngIf="selectedPriceScaleName">{{ this.selectedPriceScaleName | titlecase }}</span>
              </div>
              <!-- selector de buyer types -->
              <div class="flex flex-column w-100" *ngIf="selectedPriceScale.getValue()">
                <ng-container *ngFor="let byt of virtualCart.basket[associationId].seats | keyvalue">
                  <div
                    class="flex flex-wrap flex-row justify-content-between alignItemBase buyer-type-container"
                    style="margin-top: 5px">
                    <span
                      >{{ byt.value.name }} -
                      {{
                        dataService.priceScalesAvailability[selectedPriceScale.getValue()].prices[byt.value.code].price
                          | currency : dataService.currencyCode
                      }}</span
                    >
                    <div class="flex flex-row tickets">
                      <button
                        class="extras-modal-selection-button icon-container"
                        (click)="
                          subtractTicket(
                            associationId,
                            byt.key,
                            dataService.priceScalesAvailability[selectedPriceScale.getValue()].prices[byt.value.code]
                              .price
                          )
                        ">
                        <div class="icon minus">-</div>
                      </button>
                      <span class="ticket-selection-number">{{ byt.value.num_tickets }}</span>
                      <button
                        class="extras-modal-selection-button icon-container"
                        (click)="
                          addTicket(
                            associationId,
                            byt.key,
                            dataService.priceScalesAvailability[selectedPriceScale.getValue()].prices[byt.value.code]
                              .price
                          )
                        ">
                        <div class="icon plus">+</div>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="continue-button-container">
          <div class="rounded-block total-price-container">
            <span>Subtotal: </span>
            <span class="bold">{{ this.virtualCart.total_price | currency : dataService.currencyCode }}</span>
          </div>
          <button (click)="createTransaction()" [disabled]="!validateCart()">Continue</button>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="bottom-interface">
        <div class="continue-button-container">
          <div *ngIf="displayMobileBasket" class="display-mobile-basket">
            <div
              *ngIf="eventId && dataService.eventsList && dataService.eventsList[eventId]"
              class="rounded-block-header-container flex-column">
              <div class="content-title flex alignItemCenter w-100">
                <span>{{ dataService.eventsList[eventId].name }}</span>
                <button
                  *ngIf="dataService.eventsList[eventId].description"
                  class="extras-modal-selection-button icon-container icon-i-mobile selected"
                  style="margin-left: 15px"
                  tooltip="{{ dataService.eventsList[eventId].description }}">
                  <div class="icon plus">i</div>
                </button>
              </div>
              <div class="flex w-100 justify-content-start">
                <div class="content-subtitle">
                  <span>{{ dataService.eventsList[eventId].date | date : 'dd/MM/yyyy hh:mm a' }}</span>
                </div>
                <div class="content-subtitle" style="margin: 0 15px"><span>-</span></div>
                <div class="content-subtitle">
                  <span>{{ dataService.eventsList[eventId].venue }}</span>
                </div>
              </div>
            </div>
            <div *ngIf="dataService.customerAssociations" style="margin-top: 16px">
              <!-- customer and associations -->
              <div *ngFor="let associationId of associations" class="rounded-block list-element-container">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    align-items: baseline;
                  ">
                  {{ dataService.customerAssociations[associationId].tdc_info.first_name }}
                  {{ dataService.customerAssociations[associationId].tdc_info.last_name }}
                  <span *ngIf="selectedPriceScaleName">{{ this.selectedPriceScaleName | titlecase }}</span>
                </div>
                <!-- selector de buyer types -->
                <div class="flex flex-column w-100" *ngIf="selectedPriceScale.getValue()">
                  <ng-container *ngFor="let byt of virtualCart.basket[associationId].seats | keyvalue">
                    <div class="flex flex-wrap flex-row justify-content-between alignItemBase" style="margin-top: 5px">
                      <span
                        >{{ byt.value.name }} -
                        {{
                          dataService.priceScalesAvailability[selectedPriceScale.getValue()].prices[byt.value.code]
                            .price | currency : dataService.currencyCode
                        }}</span
                      >
                      <div class="flex flex-row tickets">
                        <button
                          class="extras-modal-selection-button icon-container"
                          (click)="
                            subtractTicket(
                              associationId,
                              byt.key,
                              dataService.priceScalesAvailability[selectedPriceScale.getValue()].prices[byt.value.code]
                                .price
                            )
                          ">
                          <div class="icon minus">-</div>
                        </button>
                        <span class="ticket-selection-number">{{ byt.value.num_tickets }}</span>
                        <button
                          class="extras-modal-selection-button icon-container"
                          (click)="
                            addTicket(
                              associationId,
                              byt.key,
                              dataService.priceScalesAvailability[selectedPriceScale.getValue()].prices[byt.value.code]
                                .price
                            )
                          ">
                          <div class="icon plus">+</div>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="virtualCart.total_price > 0" class="rounded-block total-price-container">
            <span>Subtotal: </span>
            <span class="bold">{{ virtualCart.total_price | currency : dataService.currencyCode }}</span>
          </div>
          <div class="flex">
            <button
              [ngClass]="{ selected: displayMobileBasket }"
              (click)="toggleMobileBasket()"
              style="margin-right: 8px">
              <span *ngIf="!displayMobileBasket; else mobileBasketHide">Tickets</span>
              <ng-template #mobileBasketHide><span>Hide</span></ng-template>
            </button>
            <button (click)="createTransaction()" [disabled]="!validateCart()">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END BUY TICKETS -->
</div>

<!-- tootl tip test -->
<ng-template #tooltiptest>
  <button
    class="extras-modal-selection-button icon-container icon-i-mobile selected"
    style="margin-left: 15px"
    tooltip="Tooltip for test">
    <div class="icon plus">i</div>
  </button>
</ng-template>
