import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ModalsService } from 'src/app/modals/modals.service';
import { DeliveryMethod } from 'src/app/models/delivery-method.model';
import { FiservFormModel } from 'src/app/models/fiserv-form.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';
import { FiservService } from 'src/app/services/fiserv.service';
import { User } from '../../models';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutRenewComponent implements OnInit, OnDestroy {
  transactionId;
  eventId;
  userData: User;
  transactionData;
  subscriptionList: Array<Subscription> = [];
  expirationTime;

  serviceCharges = 0;

  deliveryMethodsArray = [];
  deliveryMethod = 0;
  deliveryOptionals = {
    email: '',
    address1: '',
    address2: '',
    city: '',
    countryCode: '',
    postalCode: '',
    sub_country_code: '',
    sub_country_name: '',
  };

  fiservFormData: FiservFormModel;
  ready2Pay: boolean;

  timerObservable = new Subject<string>();
  timer;
  interval;

  get getTimer() {
    return this.timer;
  }

  constructor(
    public configurationService: ConfigurationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private connection: ConnectionService,
    private fiservService: FiservService,
    private modalService: ModalsService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    // Check event ID
    if (this.activatedRoute.snapshot.params.transaction) {
      this.transactionId = this.activatedRoute.snapshot.params.transaction;
    } else {
      this.router.navigate(['renew-tickets']);
    }
    // Get user data
    const sub = this.auth.getUserLogged$().subscribe(
      response => {
        this.userData = response as User;
        if (response['reservation_transactions'] && response['reservation_transactions'].length) {
          response['reservation_transactions'].forEach(transaction => {
            if (transaction.id == this.transactionId) {
              this.deliveryOptionals.email = this.userData.email;
              this.deliveryOptionals.address1 = this.userData.tdc_info.address.address1
                ? this.userData.tdc_info.address.address1
                : '';
              this.deliveryOptionals.address2 = this.userData.tdc_info.address.address2
                ? this.userData.tdc_info.address.address2
                : '';
              this.deliveryOptionals.postalCode = this.userData.tdc_info.address.postal_code;
              this.deliveryOptionals.countryCode = this.userData.tdc_info.address.address_country_code;
              this.deliveryOptionals.sub_country_code = this.userData.tdc_info.address.address_sub_country_code
                ? this.userData.tdc_info.address.address_sub_country_code
                : '';
              this.deliveryOptionals.sub_country_name = this.userData.tdc_info.address.address_sub_country_name
                ? this.userData.tdc_info.address.address_sub_country_name
                : '';
              this.deliveryOptionals.city = this.userData.tdc_info.address.city;
              this.eventId = transaction['tdc_event_id'];
              this.transactionData = transaction;
              this.deliveryMethodsArray = transaction.delivery_methods_available;
              this.expirationTime = transaction['tdc_expiration_time'];
              this.timerObservable.subscribe(timer => {
                this.timer = timer;
              });
              this.setTimer();
              this.setTimerInterval();
            }
          });
          this.onChangeDeliveryMethod(this.deliveryMethod);
          if (this.deliveryMethodsArray.length === 0) {
            this.modalService.info(
              'Error',
              'This orders cannot be checked out together, please select others',
              null,
              null,
              'Accept',
              () => {
                this.cancelTransactionAction();
              },
              null,
              true
            );
          }
          if (!this.transactionData) {
            this.router.navigate(['renew-tickets']);
          } else {
            this.transactionData.reservation_transaction_customers.forEach(customer => {
              if (customer.ticket_service_charges) {
                this.serviceCharges = customer.ticket_service_charges;
              }
            });
          }
        } else {
          this.router.navigate(['renew-tickets']);
        }

        setInterval(() => {
          this.dataService.isLoaderActive = false;
        }, 500);
      },
      error => {
        console.error(error);
      }
    );

    this.subscriptionList.push(sub);
  }

  ngOnDestroy() {
    this.timerObservable.unsubscribe();
    clearInterval(this.interval);
    this.subscriptionList.forEach(s => {
      s.unsubscribe();
    }); // unsuscribe for avoid memory leaks
  }

  cancelTransaction() {
    if (this.transactionId) {
      const title = 'Continue?';
      const message = 'Your transaction will be cancelled and your seats will be released. Are you sure?';
      const accept = 'Cancel transaction';
      this.modalService.info(title, message, accept, () => {
        this.connection.deleteReservationTransaction(this.transactionId).subscribe(
          response => {
            console.log(response);
            this.auth.getUserLogged(true).then(response => {
              console.log(response);
              this.router.navigate(['renew-tickets']);
            });
          },
          error => {
            console.error(error);
            this.auth.getUserLogged(true).then(response => {
              console.log(response);
              this.router.navigate(['renew-tickets']);
            });
          }
        );
      });
    }
  }

  cancelTransactionAction(): void {
    this.connection.deleteReservationTransaction(this.transactionId).subscribe(
      response => {
        console.log(response);
        this.auth.getUserLogged(true).then(response => {
          console.log(response);
          this.router.navigate(['renew-tickets']);
        });
      },
      error => {
        console.error(error);
        this.auth.getUserLogged(true).then(response => {
          console.log(response);
          this.router.navigate(['renew-tickets']);
        });
      }
    );
  }

  checkout() {
    const title = 'Continue?';
    let message =
      'I hereby confirm that I have read the Chelsea Football Club’s Conditions Of Issue which ' +
      // tslint:disable-next-line: max-line-length
      'are available for inspection at <a href="https://www.chelseafc.com/en/Conditions-of-issue" target="_blank">Conditions of Issue</a>' +
      ' and posted at the club’s offices and agree to abide by them.' +
      '<br><br> ' +
      'In addition, if I am purchasing Club Chelsea tickets, I hereby confirm that I have also read the Club Chelsea Ticket Terms ' +
      // tslint:disable-next-line: max-line-length
      'and Conditions which are available for inspection at <a href="https://www.chelseafc.com/en/club-chelsea-ticket-terms-and-conditions" target="_blank">www.chelseafc.com/en/club-chelsea-ticket-terms-and-conditions</a>' +
      ' and posted at the club’s offices and agree to abide by them.' +
      '<br><br> ' +
      'I understand that my season ticket/membership/Club Chelsea ticket/match ticket may be withdrawn, revoked or terminated as ' +
      'provided therein. Chelsea FC will use your personal data in accordance with its privacy policy,' +
      // tslint:disable-next-line: max-line-length
      ' which you can read at <a href="https://www.chelseafc.com/en/footer/privacy-policy" target="_blank">www.chelseafc.com/en/footer/privacy-policy</a>.' +
      `<br>
      <div class="container d-table">
      <span class="fw-bold">NOTE</span>
        <span class="text-danger fw-bold font-size-14">
          JUVENILES UNDER THE AGE OF 16 WILL NOT BE PERMITTED ENTRY TO THE GROUND UNLESS ACCOMPANIED BY A PERSON OVER 18 YEARS<br>
          </span>
        <span class="fw-bold font-size-14 opacity-75 ">AT STAMFORD BRIDGE, THE ENTIRE SHED END AND THE MATTHEW HARDING LOWER TIER ARE SAFE STANDING AREAS.</span>
      </div>`;
      
    const accept = 'Continue';
    this.modalService.info(
      title,
      message,
      accept,
      () => {
        console.log(this.deliveryMethod, this.deliveryOptionals);
        const deliveryMethod: DeliveryMethod = {
          delivery_method_id: this.deliveryMethodsArray[this.deliveryMethod].id,
          delivery_method_type: this.deliveryMethodsArray[this.deliveryMethod].type,
          delivery_first_name: this.userData.tdc_info.first_name,
          delivery_last_name: this.userData.tdc_info.last_name,
          delivery_email: this.deliveryOptionals.email,
          delivery_address1: this.deliveryOptionals.address1,
          delivery_city: this.deliveryOptionals.city,
          delivery_country_code: this.deliveryOptionals.countryCode,
          delivery_postal_code: this.deliveryOptionals.postalCode,
        };
        if (this.deliveryOptionals.sub_country_code)
          deliveryMethod.delivery_sub_country_code = this.deliveryOptionals.sub_country_code;
        if (this.deliveryOptionals.sub_country_name)
          deliveryMethod.delivery_sub_country_name = this.deliveryOptionals.sub_country_name;
        if (this.deliveryOptionals.address2) deliveryMethod.delivery_address2 = this.deliveryOptionals.address2;
        this.connection.putReservationTransaction(this.transactionId, deliveryMethod).subscribe(
          response => {
            console.log(response);
            // if we receive checkout_success means that payment is 0, then no need to enter card details
            // we redirect directly to return_url is true or to error url if false
            if (response.hasOwnProperty('checkout_success')) {
              const successUrl = response.return_url.split(location.hostname)[1];
              const failUrl = successUrl.replace('success', 'error');
              const urlToRedirect = response.checkout_success ? successUrl : failUrl;
              this.router.navigateByUrl(urlToRedirect);
              // normal case
            } else {
              this.modalService.adyenPaymentModal(response.session_id, response.session_data, response.return_url);
            }
          },
          error => {
            console.error(error);
          }
        );
      },
      null,
      null,
      'modal-lg'
    );
  }

  isCheckoutDisabled() {
    if (
      this.deliveryMethodsArray.length !== 0 &&
      this.deliveryMethodsArray[this.deliveryMethod].type === 'EXTERNAL_TICKETS_AT_HOME'
    ) {
      if (this.deliveryOptionals.email) {
        return false;
      }
      return true;
    } else if (
      this.deliveryMethodsArray.length !== 0 &&
      (this.deliveryMethodsArray[this.deliveryMethod].type === 'POSTAL' ||
        this.deliveryMethodsArray[this.deliveryMethod].type === 'POSTAL_TRACKABLE')
    ) {
      if (
        this.deliveryOptionals.address1 &&
        this.deliveryOptionals.city &&
        this.deliveryOptionals.countryCode &&
        this.deliveryOptionals.postalCode
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  // TIMER
  time2string(delta) {
    // Days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // Hours
    const hoursNum = Math.floor(delta / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    delta -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(delta / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    delta -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(delta % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    // return days + 'd ' + hours + ':' + minutes + ':' + seconds;
    return hours + ':' + minutes + ':' + seconds;
  }

  setTimer() {
    const appointmentTimestamp = new Date(this.expirationTime).getTime();
    const now = new Date().getTime();
    // let diff = Math.abs(appointmentTimestamp - now) / 1000;
    let diff = (appointmentTimestamp - now) / 1000;
    if (diff <= 0) {
      diff = 0;
      clearInterval(this.interval);
      this.modalService.info(
        'Transaction expired',
        'Your transaction has expired. Please, click the button below to return to the home page.',
        'Return',
        () => {
          this.auth.getUserLogged(true).then(() => {
            this.router.navigate(['renew-tickets']);
          });
        },
        null,
        null,
        null,
        true
      );
    }
    const timeString = this.time2string(diff);
    this.timerObservable.next(timeString);
  }

  setTimerInterval() {
    this.interval = setInterval(() => {
      this.setTimer();
    }, 1000);
  }

  onChangeDeliveryMethod(deliveryMethod: any): void {
    if (this.deliveryMethodsArray.length === 0) {
      return;
    }
    const isPostal =
      this.deliveryMethodsArray[deliveryMethod].type === 'POSTAL' ||
      this.deliveryMethodsArray[deliveryMethod].type === 'POSTAL_TRACKABLE';
    if (!this.deliveryOptionals.address1 && isPostal) {
      this.modalService.info(
        'Error',
        'You cannot select this delivery method because you don’t have delivery address',
        null,
        null,
        'Accept',
        null,
        null,
        true
      );
    }
  }
}
