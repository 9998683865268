import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG } from '../configuration/app.configuration';
import { AppConfiguration } from '../models/appconfiguration.model';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationComponent implements OnInit {
  constructor(@Inject(APP_CONFIG) private appConfig: AppConfiguration) {}

  get team(): string {
    return this.appConfig.clientName;
  }

  get isChelseaFC(): boolean {
    return this.appConfig.clientName === 'Chelsea FC';
  }

  ngOnInit(): void {}
}
