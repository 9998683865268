import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';
import { EventModel } from '../../models';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  fromTransaction: number;
  eventsList: Array<EventModel> = [];
  venuesAvailables: { [key: string]: { name: string; active: boolean } } = {};
  filteredEventsList: Array<EventModel> = [];

  constructor(
    public dataService: DataService,
    private router: Router,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private connection: ConnectionService
  ) {}

  ngOnInit(): void {
    this.fromTransaction = this.activatedRoute.snapshot.params.transaction || null;
    // Get user data
    this.auth
      .getUserLogged$()
      .pipe(take(1))
      .subscribe(
        response => {
          if (response['sale_transactions'] && response['sale_transactions'].length && !this.fromTransaction) {
            this.router.navigate([
              '/buy-tickets/checkout',
              { transaction: response['sale_transactions'][0].id, flow: 'tickets' },
            ]);
          } else if (
            response['membership_transactions'] &&
            response['membership_transactions'].length &&
            !this.fromTransaction
          ) {
            this.router.navigate([
              '/buy-tickets/checkout',
              { transaction: response['membership_transactions'][0].id, flow: 'memberships' },
            ]);
          }
        },
        error => {
          console.error(error);
        }
      );
    // this.eventsList = Object.values(this.dataService.eventsList);
    this.eventsList = this.dataService.sortedEvents;
    this.venuesAvailables = this.dataService.venuesAvailables;
    this.filteredEventsList = this.eventsList;
    if (!this.eventsList.length) {
      this.connection.getEventsList().subscribe(response => {
        this.dataService.sortedEvents = response;
        // this.dataService.sortedEvents.push({"id":9999,"name":"Kingsmeadow TEST","description":null,"date":"2022-05-31T15:00:00.000+01:00","venue":"Kingsmeadow"})
        this.dataService.sortedEvents.forEach(event => {
          this.dataService.eventsList[event.id] = event;
          // Seleccionamos los venues disponibles en base a los eventos.
          this.dataService.venuesAvailables[event.venue] = { name: event.venue, active: false };
        });
        this.eventsList = this.dataService.sortedEvents;
        this.filteredEventsList = this.eventsList;
      });
    }
  }

  onSelectVenue(venue: string): void {
    const status = this.venuesAvailables[venue].active ? !this.venuesAvailables[venue].active : true; // mejorable seguramente
    this.selectVenue(venue, status);
  }

  selectVenue(venue: string, status: boolean): void {
    // Si es false, estamos deseleccionamos, por lo que debemos auto seleccionar la opcion 'All' y hacer reset de la lista de eventos
    const all = !status;
    this.unSelectVenue(all);
    this.venuesAvailables[venue].active = status;
    this.filterVenue(venue, all); // Apply filter
  }

  unSelectVenue(all: boolean = false): void {
    Object.keys(this.venuesAvailables).forEach(key => {
      this.venuesAvailables[key].active = false;
    });
    if (all) {
      this.venuesAvailables['ALL'].active = true;
    }
  }

  filterVenue(venue: string, all: boolean): void {
    if (venue != 'ALL' && !all) {
      this.filteredEventsList = this.eventsList.filter(event => event.venue === venue);
    } else {
      this.filteredEventsList = this.eventsList;
    }
  }

  eventIsAlreadyPick(eventId: number): boolean {
    if (this.fromTransaction) {
      return !this.dataService.eventsAlreadyPickList.includes(eventId);
    }
    return true;
  }
}
