import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG } from '../../configuration/app.configuration';
import { AppConfiguration } from '../../models/appconfiguration.model';
import { ConnectionService } from '../../services/connection.service';
import { DataService } from '../../services/data.service';
import { NewAccountService } from '../new-account.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  username;
  password;
  password2;
  backgroundUrl;

  token;

  requestSent = false;
  isCAEDBcode: boolean = false;
  isCAETDCcode: boolean = false;
  isEmailIncorrect: boolean = false;

  constructor(
    @Inject(APP_CONFIG) public appConfig: AppConfiguration,
    private connection: ConnectionService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private newAccountService: NewAccountService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.backgroundUrl = `url(${this.appConfig.backgroundImageUrl})`;
    if (this.activatedRoute.snapshot.params) {
      if (this.activatedRoute.snapshot.params.t) {
        this.token = this.activatedRoute.snapshot.params.t;
      }
    }
  }

  sendEmail() {
    if (!this.validateEmail(this.username)) {
      this.isEmailIncorrect = true;
      return;
    }

    if (this.username) {
      this.connection.postCustomerRegister(this.username).subscribe(
        response => {
          console.log(response);
          this.requestSent = true;
        },
        error => {
          this.redirect(error);
        }
      );
    }
  }

  redirect(e: any) {
    console.log(e);
    if (e?.error?.code) {
      switch (e.error.code) {
        // The user don't exist in MMC PORTAL AND PV
        case 'CNETDC':
          this.newAccountService.addEmail(this.username);
          this.router.navigate(['new-account']);
          break;
        // The user exist in MMC PORTAL AND PV
        case 'CAEDB':
          this.isCAEDBcode = true;
          break;
        // The user only exist in PV
        case 'CAETDC':
          this.isCAETDCcode = true;
          break;
        default:
          alert('Code not with any mach');
          break;
      }
    } else {
      alert('Upss, an error ocurred');
    }
  }

  register() {
    if (this.token && this.password && this.password2 && this.password === this.password2) {
      this.connection.postCustomerRegisterFinalize(this.token, this.password).subscribe(
        response => {
          this.requestSent = true;
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  resetPassword() {
    if (this.username) {
      this.auth.postResetPassword(this.username).subscribe(
        response => {
          this.requestSent = true;
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  validateEmail(email: string) {
    if (/^\S+@\S+\.\S+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  }
}
