<div class="pb-container container">
  <a [routerLink]="['../my-events']">Go back</a>
  <div class="content-title" style="margin-bottom: 24px;" *ngIf="eventAndTicket"><span>{{eventAndTicket.event.name}}</span></div>
  <div class="content-title" style="margin-bottom: 24px; margin-top: 24px"><span>My Tickets</span></div>
  <div class="list-header-container desktop">
    <div class="list-element bold"><span>Section</span></div>
    <div class="list-element bold"><span>Row</span></div>
    <div class="list-element bold"><span>Seat</span></div>
    <div class="list-element bold"><span>Price Scale</span></div>
    <div class="list-element-button"><span></span></div>
  </div>
  <div>
    <ng-container *ngFor="let ticket of ticketList">
      <div class="list-element-container-wrap rounded-block"  [ngClass]="{'disabled': ticket.currentlyOnMarketOffer}">
        <div class="list-element-container">
          <div class="list-element bold desktop"><span>{{ ticket.seat.section }}</span></div>
          <div class="list-element bold desktop"><span>{{ ticket.seat.row }}</span></div>
          <div class="list-element bold desktop"><span>{{ ticket.seat.seat_number }}</span></div>
          <div class="list-element"><span>{{ ticket.price_scale.publicDescription }}</span></div>
          <div class="list-element-button" *ngIf="!ticket.currentlyOnMarketOffer else ReleaseButton" (click)="releaseTicket(ticket)"><button>List</button></div>
          <ng-template #ReleaseButton>
            <div class="list-element-button" (click)="recoverTicket(ticket.market_offer, ticket)"><button class="white-button">Delist</button></div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</div>
