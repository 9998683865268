import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AvailabilitySeatMapPricesModel } from '../../exchange-tickets/models';

@Component({
  selector: 'app-select-a-price-modal',
  templateUrl: './select-a-price-modal.component.html',
  styleUrls: ['./select-a-price-modal.component.scss'],
})
export class SelectAPriceModalComponent implements OnInit {
  title = 'Select a Price';
  acceptBtnName = null;

  priceList: Array<AvailabilitySeatMapPricesModel>;

  selectedPrice: AvailabilitySeatMapPricesModel = { id: 0, name: 'placeholder', price: 0, code: 'plh' };

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}

  select(price: AvailabilitySeatMapPricesModel): void {
    if (price.id === this.selectedPrice.id) {
      this.selectedPrice = { id: 0, name: 'placeholder', price: 0, code: 'plh' };
    } else {
      this.selectedPrice = price;
      this.bsModalRef.hide();
    }
  }
}
