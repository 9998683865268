import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-admin-login-banner',
  templateUrl: './admin-login-banner.component.html',
  styleUrls: ['./admin-login-banner.component.scss'],
})
export class AdminLoginBannerComponent implements OnInit {
  @Input() adminUsername: string;

  constructor(public configurationService: ConfigurationService) {}

  ngOnInit(): void {}

  returno2Backoffice(): void {
    const url = this.getBoEnvironment(this.configurationService.client);
    window.open(`${url}`, '_self');
  }

  // Devuelve la url del portal correcto. Si es para dev, local o pro
  getBoEnvironment(team: string): any {
    const actualDomain = window.location.href;
    if (actualDomain.includes('dev-') || actualDomain.includes('local.')) {
      // check if user is working on dev env
      return `https://private.dev-${team}.3ddigitalvenue.com`;
    } else if (actualDomain.includes('pre-')) {
      // pro env
      return `https://private.pre-${team}.3ddigitalvenue.com`;
    } else {
      return `https://private.${team}.3ddigitalvenue.com`;
    }
  }
}
