<div>
  <div class="super-container">
    <div class="modal-header bold">
      <h4 class="pull-left">{{ title }}</h4>
    </div>
    <div class="modal-body wrapper">
      <p style="white-space: pre-line" [innerHTML]="content"></p>
    </div>
    <div class="modal-footer">
      <button *ngIf="closeBtnName" type="button" class="white-button" (click)="close()">{{ closeBtnName }}</button>
      <button *ngIf="acceptBtnName" type="button" (click)="accept()">{{ acceptBtnName }}</button>
    </div>
  </div>
</div>
