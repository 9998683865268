import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalsService } from 'src/app/modals/modals.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';
import { DVMService } from 'src/app/services/dvm.service';
import { environment } from '../../../environments/environment';
import { ConfigurationService } from '../../services/configuration.service';
import { User } from '../../models';
import { take } from 'rxjs/operators';
import { GtmService } from 'src/app/services/gtm.service';

interface PriceScalePrices {
  [key: string]: { name: string; code: string; id: number; price: number };
}

interface CustomerBasket {
  seats: { [key: number]: { num_tickets: number; code: string; name: string } };
  customer?: number;
}

@Component({
  selector: 'app-select-seats',
  templateUrl: './select-seats.component.html',
  styleUrls: ['./select-seats.component.scss'],
})
export class SelectSeatsComponent implements OnInit {
  @Input() fromTransaction: number;
  @Input() eventId: number;
  associations = [];
  @Input() isAwayEvent: boolean; // Cuando un evento es en un away venue, no mostramos el mapa.
  familyPriceScale = ['1001', '1002']; // las zonas familiares tienen ciertas logicas especiales a la hora de seleccionar buyer types
  priceScalesArray = [];
  selectedPriceScale = new BehaviorSubject(null);
  copyPricesScaleArray = [];
  totalPrice;
  selectedSection: string;
  selectedPriceScaleName: string;
  userData: User;
  selectedPriceScalePrices = {};
  virtualCart: { basket: { [key: string]: CustomerBasket }; total_price: number } = {
    basket: {},
    total_price: 0,
  };
  isMobile = false;

  displayMobileBasket = false;

  get customerAssociationIds() {
    const ids = [];
    if (this.associations) {
      this.associations.forEach(association => {
        ids.push(this.dataService.customerAssociations[association].associate_id);
      });
    }
    return ids;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private el: ElementRef,
    private configurationService: ConfigurationService,
    private router: Router,
    public dataService: DataService,
    public auth: AuthService,
    private dvmService: DVMService,
    private modalService: ModalsService,
    private connection: ConnectionService,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    // Check selected associations
    if (this.activatedRoute.snapshot.params.associations) {
      this.associations = this.activatedRoute.snapshot.params.associations.split(',');
    }
    // guardamos la transaccion padre para poder utilizarla mas adelante.
    this.dataService.fromTransaction = this.fromTransaction;

    // Check events list
    if (!this.dataService.eventsList[this.eventId] || !this.dataService.eventsList[this.eventId]) {
      this.router.navigate(['buy-tickets']);
    } else if (this.isAwayEvent) {
      // Is Away event, no map, cargamos los price scale y la pesca, sin dvm.
      this.getEventAvailability();
    } else {
      this.initializeMap();
    }

    // check mobile mode ???
    const bodyElement = document.getElementsByTagName('body');
    if (bodyElement[0].clientWidth <= 576) {
      this.isMobile = true;
    }

    // Get user data
    this.auth
      .getUserLogged$()
      .pipe(take(1))
      .subscribe(
        response => {
          if (typeof response === 'boolean') {
            return;
          }
          this.userData = response;
          // START BASKET
          // this.virtualCart.basket[this.userData.tdc_info.id] = { customer:this.userData.tdc_info.id, seats: {}};
          for (const ass of this.customerAssociationIds) {
            this.virtualCart.basket[ass] = { customer: ass, seats: {} };
          }
        },
        error => {
          console.error(error);
        }
      );
  }

  initializeMap() {
    if (this.dataService.lastVenue === 'kingsmeadow') {
      let venueFemale = this.configurationService.getFemaleVenue();
      this.dvmService.restartDVM(venueFemale.venueId, false, venueFemale.mapId);
    } else {
      this.dvmService.restartDVM();
    }
    this.dvmService.subscribeHandler('end_load', () => {
      this.dvmService.viewer.setAvailable('section', this.dvmService.viewer.getNodesByType('section'));
      this.dvmService.viewer.layers.flags.automatic_control_layers = false;
      this.dvmService.viewer.layers.flags.automatic_control_level = false;
      // this.dvmService.viewer.scaling_factor = 1.3;
      this.dvmService.viewer.scaling_factor = 1;
      this.dvmService.viewer.bindInterfaceAction(document.getElementById('map-interface-button-plus'), 'zoom-in');
      this.dvmService.viewer.bindInterfaceAction(document.getElementById('map-interface-button-minus'), 'zoom-out');
      this.getEventAvailability();
    });
    this.dvmService.subscribeHandler('click', action => {
      const node: { id: string; type: string; state: 'available' | 'unavailable' } = action.nodes[0];
      if (!node || node.state === 'unavailable' || node.id === this.selectedSection) {
        this.resetPriceScaleSelectionSelected();
        return;
      } else {
        this.unSelectNode(this.selectedSection);
        this.selectNode(node.id);
      }
      const priceScaleList: Array<string> = this.dataService.reverseEventMorfology[node.id];
      this.priceScalesArray = this.copyPricesScaleArray.filter(item => {
        return priceScaleList.includes(item.id);
      });
    });
    this.dvmService.subscribeHandler('enter', action => {
      const node: { id: string; type: string; state: 'available' | 'unavailable' } = action.nodes[0];
      if (!node && node.state === 'unavailable') return;
      this.dvmService.viewer.hover(node.id);
      this.enterSection(node.id);
    });
    this.dvmService.subscribeHandler('leave', action => {
      const node: { id: string; type: string; state: 'available' | 'unavailable' } = action.nodes[0];
      if (!node || node.state === 'unavailable') return;
      this.dvmService.viewer.hover([]);
      this.leaveSection(node.id);
    });
  }

  createTransaction() {
    let valid = true;
    const list = Object.keys(this.virtualCart.basket).map(index => this.virtualCart.basket[index]);
    // Validamos que tenga ticket Junior para introducir mensaje dinamico [IT-4550]
    // check enclosure family 2 https://mmcbcn.atlassian.net/browse/IT-2225
    if (this.selectedPriceScale.getValue() && this.familyPriceScale.indexOf(this.selectedPriceScale.getValue()) >= 0) {
      valid = this.validateFamilyEnclosureOnCheckout(list);
    }
    if (valid) {
      const title = 'Continue?';
      const message =
        `By clicking continue, your selection will be place on hold and you will be redirected to the checkout page.

          <span class="fw-bold">NOTE</span>
          <span class="d-inline-block text-danger fw-bold font-size-14">
          JUVENILES UNDER THE AGE OF 16 WILL NOT BE PERMITTED ENTRY TO THE GROUND UNLESS ACCOMPANIED BY A PERSON OVER 18 YEARS<br>
          </span>
          <span class="fw-bold font-size-14 opacity-75 ">AT STAMFORD BRIDGE, THE ENTIRE SHED END AND THE MATTHEW HARDING LOWER TIER ARE SAFE STANDING AREAS.</span>
        `;

      const accept = 'Continue';
      this.modalService.info(title, message, accept, () => {
        this.connection
          .postSaleTransaction(this.eventId, this.selectedPriceScale.getValue(), list, this.fromTransaction)
          .subscribe(
            () => {
              this.auth.getUserLogged(true).then(response => {
                if (response['sale_transactions'] && response['sale_transactions'].length) {
                  response ? this.gtmService.addToCartBuyTicket(response.sale_transactions[0]) : '';
                  this.router.navigate([
                    '/buy-tickets/checkout',
                    { transaction: response['sale_transactions'][0].id, flow: 'tickets' },
                  ]);
                }
              });
            },
            error => {
              console.error(error);
            }
          );
      });
    } else {
      // Si las normas de enclosure family no se cumplen, mostramos el modal.
      const title = 'System Message';
      const message = `The rules of ${this.selectedPriceScaleName} are not met.`;
      this.modalService.info(title, message, null);
    }
  }

  selectPriceScale(id) {
    if (!this.isAwayEvent) {
      this.dvmService.viewer.unselectAll();
    }
    this.emptyCart();
    if (this.selectedPriceScale.getValue() === id) {
      // es la price sscale seleccioanda ?
      this.selectedPriceScale.next(null);
      this.selectedPriceScaleName = null;
      this.dvmService.viewer.goTo([0, 0], this.dvmService.viewer.min_scaling_factor); // reiniciamos el focus de dvm
      // si estamos en movil cerramos la ventana del basket
      if (this.isMobile) {
        this.displayMobileBasket = false;
      }
    } else {
      // nueva price scale seleccionada
      this.selectedPriceScale.next(id);
      // start basket for this prices scale
      this.startBasket(this.dataService.priceScalesAvailability[id].prices);
      // CONTINUE
      this.selectedPriceScaleName = this.dataService.priceScalesAvailability[id].name;
      // select price scales prices
      this.selectedPriceScalePrices = this.dataService.priceScalesAvailability[id].prices;
      if (!this.isAwayEvent) {
        // Si no es un evento Away(fuera del estadio del equipo) hacemos zoom a la secciones que pertenecen al price scale
        this.dvmService.viewer.select(this.dataService.eventMorfology[id]);
        const nodes = this.dataService.eventMorfology[id].map(id => this.dvmService.viewer.getNodeById(id));
        this.dvmService.viewer.goTo(this.getNodesBoundingBox(nodes));
      }
      // si estamos en movil abrimos la ventana del basket
      if (this.isMobile) {
        this.displayMobileBasket = true;
      }
    }
  }

  priceScaleEnter(id) {
    if (this.isAwayEvent) {
      return;
    }
    this.dvmService.viewer.hover(this.dataService.eventMorfology[id]);
  }

  priceScaleLeave() {
    if (this.isAwayEvent) {
      return;
    }
    this.dvmService.viewer.hover([]);
  }

  toggleMobileBasket() {
    this.displayMobileBasket = !this.displayMobileBasket;
  }

  enterSection(section: string) {
    const priceScaleList: Array<number> = this.dataService.reverseEventMorfology[section];
    if (priceScaleList == undefined) return;
    priceScaleList.forEach(value => {
      const tag = this.el.nativeElement.querySelector(`[id="${value}"]`);
      if (tag) tag.classList.add('selected');
    });
  }

  leaveSection(section: string) {
    const priceScaleList: Array<number> = this.dataService.reverseEventMorfology[section];
    priceScaleList.forEach(value => {
      const tag = this.el.nativeElement.querySelector(`[id="${value}"]`);
      if (tag) tag.classList.remove('selected');
    });
  }

  getBuyerTypes(date: string): Array<any> {
    return this.getbuyerTypes(date);
  }

  getEventAvailability() {
    this.connection.getEventAvailability(this.eventId).subscribe(availability => {
      this.dataService.priceScalesAvailability = availability;
      this.getMorfology(availability);
      Object.entries(availability).forEach(priceScale => {
        const priceScaleObject = priceScale[1];
        priceScaleObject['id'] = priceScale[0];
        if (priceScaleObject['prices']) {
          this.priceScalesArray.push(priceScaleObject);
        }
        this.copyPricesScaleArray = this.priceScalesArray;

        this.havePriceScale();
      });
    });
  }

  getMorfology(availability: { [key: number]: Array<string> }) {
    // Get Morfology
    const morfology: { [key: number]: Array<string> } = {};
    Object.entries(availability).forEach(([key, values]) => {
      if (!morfology.hasOwnProperty(key)) {
        morfology[key] = values['sections'];
      }
    });
    this.dataService.eventMorfology = morfology;

    // Get Reverse Morfology
    const reverseMorfology: { [key: number]: Array<string> } = {};
    Object.entries(morfology).forEach(([key, values]) => {
      values.forEach(value => {
        if (!reverseMorfology.hasOwnProperty(value)) {
          reverseMorfology[value] = [];
        }
        reverseMorfology[value].push(key);
      });
    });

    this.dataService.reverseEventMorfology = reverseMorfology;
    console.log(morfology);
  }

  havePriceScale() {
    if (this.priceScalesArray.length === 0) {
      this.modalService.info(
        'No inventory available',
        'There are no seats available for this event. Please, click the button below to select a new event.',
        'Browse more events',
        () => {
          this.router.navigate(['buy-tickets']);
        },
        null,
        null,
        null,
        true
      );
      if (!this.isAwayEvent) {
        this.dvmService.viewer.setAvailability('section', []);
      }
    } else if ((this.dvmService.viewer || this.dvmService.isViewerSubjectInitialized) && !this.isAwayEvent) {
      let availableSectionsArray = [];
      this.priceScalesArray.forEach(priceScale => {
        if (this.dataService.eventMorfology[priceScale.id]) {
          availableSectionsArray = availableSectionsArray.concat(this.dataService.eventMorfology[priceScale.id]);
        }
      });
      this.dvmService.viewer.setAvailability('section', availableSectionsArray);
    } else {
      this.dvmService.viewerSubject.subscribe(() => {});
    }
  }

  private selectNode(id: string): void {
    this.dvmService.viewer.select(id);
    this.selectedSection = id;
  }

  private unSelectNode(id: string): void {
    this.dvmService.viewer.unselect(id);
    this.selectedSection = null;
  }

  // Unselect seccion and reset pricescale side list
  private resetPriceScaleSelectionSelected() {
    this.priceScalesArray = this.copyPricesScaleArray;
    this.unSelectNode(this.selectedSection);
  }

  getbuyerTypes(date: string): Array<string> {
    const byt = this.dataService.getCustomerBuyerType(date, this.configurationService.client);
    if (byt === 'SENIOR') {
      return this.buyerTypesRules(byt);
    } else if (byt === 'ADULT') {
      return this.buyerTypesRules(byt);
    } else if (byt === 'JUNIOR' || byt === 'CHILD') {
      return this.buyerTypesRules(byt);
    }
  }

  private buyerTypesRules(buyerTypeName: string): Array<string> {
    const rules = environment.buyer_types[this.configurationService.client].buyer_types_rules;
    const list = [];
    Object.keys(rules[buyerTypeName]).forEach(item => {
      if (rules[buyerTypeName][item]) {
        if (this.selectedPriceScalePrices.hasOwnProperty(item) && this.selectedPriceScalePrices[item]) {
          list.push(this.selectedPriceScalePrices[item]);
        }
      }
    });
    return list;
  }

  subtractTicket(patronId: number, buyer_type, price: number): void {
    if (this.virtualCart.basket[patronId].seats[buyer_type].num_tickets > 0) {
      this.virtualCart.basket[patronId].seats[buyer_type].num_tickets -= 1;
      this.virtualCart.total_price -= price;
    }
  }

  addTicket(patronId: number, buyer_type: number, price: number): void {
    this.virtualCart.basket[patronId].seats[buyer_type].num_tickets += 1;
    this.virtualCart.total_price += price;
  }

  // iniciamos para cada customer un carro con datos place holder para poder seleccionarlos en el html template
  private startBasket(prices: PriceScalePrices): void {
    let listPrices = Object.values(prices);
    listPrices.forEach(buyerType => {
      Object.keys(this.virtualCart.basket).forEach(cart => {
        this.virtualCart.basket[cart].seats[buyerType.id] = {
          num_tickets: 0,
          code: buyerType.code,
          name: buyerType.name,
        };
      });
    });
  }

  // buscar el minimo precio
  getMinPrice(prices: PriceScalePrices) {
    let list = Object.values(prices);
    return Math.min(...list.map(item => item.price));
  }

  validateCart(): boolean {
    let valid = this.cartHasSeats();
    if (this.selectedPriceScale.getValue() && this.familyPriceScale.indexOf(this.selectedPriceScale.getValue()) >= 0) {
      valid = this.validateFamilyEnclosure();
    }
    return valid;
  }

  // esta funcion esta comprobando si los basket cumple las normas "basicas" del enclosure https://mmcbcn.atlassian.net/browse/IT-2225
  // el carro debe como MINIMO tener un 1 senior o 1 junior, solo adults no permitido.
  private validateFamilyEnclosure(): any {
    const enclosureFamilyAdultBuyerTypeId = this.dataService.getFamilyAreaAdultBuyerTypeId(
      this.configurationService.client
    );
    let valid = false;
    Object.keys(this.virtualCart.basket).forEach(basket => {
      const basketPersonal = this.virtualCart.basket[basket];
      Object.keys(basketPersonal.seats).forEach(buyerTypes => {
        if (buyerTypes !== `${enclosureFamilyAdultBuyerTypeId}` && basketPersonal.seats[buyerTypes].num_tickets > 0) {
          valid = true;
        }
      });
    });
    return valid;
  }

  // Validamos que entre todos los usuarios cumplan las reglas de enclosure family https://mmcbcn.atlassian.net/browse/IT-2225
  // por cada junior pueden añadir 2 adults.
  // por cada senior pueden añadir 1 adult.
  // se puede combinar entre si: 2 junior + 2 senior => 6 adults allowed.
  // Esta funcion se ejecuta cuando el usuario pulsa el 'continue'
  private validateFamilyEnclosureOnCheckout(list: Array<CustomerBasket>): boolean {
    const enclosureFamilyAdultBuyerTypeId = this.dataService.getFamilyAreaAdultBuyerTypeId(
      this.configurationService.client
    );
    const buyerTypes = {
      1012: 0, // junior
      1013: 0, // senior
    };
    buyerTypes[enclosureFamilyAdultBuyerTypeId] = 0;
    let valid = false;
    for (const basket of list) {
      Object.entries(basket.seats).forEach(([key, value]) => (buyerTypes[key] += value.num_tickets));
    }
    let allowedAdults = buyerTypes[1012] * 2;
    allowedAdults += buyerTypes[1013];
    if (allowedAdults >= buyerTypes[enclosureFamilyAdultBuyerTypeId]) {
      valid = true;
    }

    return valid;
  }

  private cartHasSeats(): boolean {
    let has = false;
    for (let basketC of Object.values(this.virtualCart.basket)) {
      Object.values(basketC.seats).some(item => {
        if (item.num_tickets > 0) {
          has = true;
        }
      });
    }

    return has;
  }

  // vaciamos los buyer type seleccionados anteriormente
  private emptyCart(): void {
    this.virtualCart.total_price = 0;
    Object.keys(this.virtualCart.basket).forEach(cart => {
      this.virtualCart.basket[cart].seats = {};
    });
  }

  // Made in Ricardo Navarro
  // Para centrar sin tapar ningun nodo.
  private getNodesBoundingBox(nodes: D2M.IPublicNode[]): [number, number, number, number] {
    if (!nodes.length) return [0, 0, 0, 0];

    let min_x = Infinity;
    let min_y = Infinity;
    let max_x = -Infinity;
    let max_y = -Infinity;

    for (let i = 0; i < nodes.length; ++i) {
      const node = nodes[i];
      const [x1, y1, w, h] = node.aabb!;
      const x2 = x1 + w;
      const y2 = y1 + h;
      if (x1 < min_x) min_x = x1;
      if (y1 < min_y) min_y = y1;
      if (x2 > max_x) max_x = x2;
      if (y2 > max_y) max_y = y2;
    }

    return [min_x, min_y, max_x - min_x, max_y - min_y];
  }
}
