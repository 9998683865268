import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-map-legend',
  templateUrl: './map-legend.component.html',
  styleUrls: ['./map-legend.component.scss'],
})
export class MapLegendComponent implements OnInit {
  @Input() client: string;
  holdCodes: { [key: string]: { code: string; name: string; color: string; buyer_type: string } } = {};
  listOfHolCodes: Array<{ code: string; name: string; color: string; buyer_type: string }> = [];

  constructor(private data: DataService) {}

  ngOnInit(): void {
    this.holdCodes = this.data.getExchangeHoldCodes(this.client);
    const fnSort = (a, b) => {
      let nameA = a.name.toLowerCase();
      let nameB = b.name.toLowerCase();
      if (nameA === nameB) return 0;
      return nameA > nameB ? 1 : -1;
    };
    this.listOfHolCodes = Object.values(this.holdCodes).sort(fnSort);
  }

  toggleLegend(): void {
    const elDiv = document.getElementById('map-legend');
    const elDivArrow = document.getElementById('arrow');
    if (elDiv.classList.contains('visible')) {
      elDiv.classList.remove('visible');
      elDivArrow.classList.remove('arrow-close');
    } else {
      elDiv.classList.add('visible');
      elDivArrow.classList.add('arrow-close');
    }
  }
}
