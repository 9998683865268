<div class="full-container-wbar">
  <div class="flex wrap-mobile" style="height: 100%">
    <div class="rounded-block tickets-block" style="overflow-y: auto">
      <div class="bold" style="margin-bottom: 16px">
        <span>My tickets:</span>
      </div>
      <div *ngIf="transactionData">
        <div
          *ngFor="let customer of transactionData.reservation_transaction_customers"
          class="rounded-block list-element-container">
          <div>
            {{ customer.name }}
            <div>
              <div
                *ngFor="let order of customer.orders_claimed"
                class="rounded-block"
                style="margin: 16px 0; padding: 16px">
                <div>
                  <span>Order ID: </span><span class="bold">{{ order.id }}</span>
                </div>
                <div>
                  <div *ngFor="let ticket of order.pending_items">
                    <div>
                      <span class="bold">{{ ticket.name }}</span>
                    </div>
                    <div>
                      <div *ngFor="let seat of ticket.seats">
                        <div *ngIf="!ticket.venue || ticket.venue; else showSeatInfo">
                          <span>Section: {{ seat.section | titlecase }} Row: {{ seat.row }} Seat: {{ seat.seat }}</span>
                        </div>
                        <ng-template #showSeatInfo
                          ><span>Section: {{ seat.section | titlecase }}</span></ng-template
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>{{ customer.buyer_type }}</div>
            <div class="bold" style="text-align: right">
              {{ customer.ticket_price | currency : dataService.currencyCode }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rounded-block payment-block">
      <div>
        <div class="payment-block-header">
          <div></div>
          <div class="timer">
            <div class="content-subtitle">
              <span>Your transaction will expire in</span>
            </div>
            <span class="bold">{{ getTimer }}</span>
          </div>
        </div>
        <div>
          <p>
            Your seats are now reserved, please complete your transaction before the timer runs out. Choose your
            delivery method and click 'Checkout'. After you confirm, your seats will remain reserved and you will be
            redirected to an external website to process your payment.
          </p>
        </div>

        <div class="flex wrap-mobile" *ngIf="deliveryMethodsArray.length > 0">
          <div class="delivery-methods-container">
            <div class="bold"><span>Choose your delivery method:</span></div>
            <select
              name="miselect"
              [(ngModel)]="deliveryMethod"
              (ngModelChange)="onChangeDeliveryMethod(deliveryMethod)">
              <option [value]="i" *ngFor="let deliveryMethod of deliveryMethodsArray; let i = index">
                {{ deliveryMethod.description }}
              </option>
            </select>
            <div *ngIf="deliveryMethodsArray[deliveryMethod].type === 'EXTERNAL_TICKETS_AT_HOME'">
              <div class="bold"><span>Email:</span></div>
              <input [(ngModel)]="deliveryOptionals.email" type="text" disabled />
            </div>
            <div
              *ngIf="
                deliveryMethodsArray[deliveryMethod].type === 'POSTAL' ||
                deliveryMethodsArray[deliveryMethod].type === 'POSTAL_TRACKABLE'
              ">
              <div class="bold"><span>Address:</span></div>
              <input [(ngModel)]="deliveryOptionals.address1" type="text" disabled />
              <div *ngIf="deliveryOptionals.address2" class="bold">
                <span>Address line 2:</span>
              </div>
              <input *ngIf="deliveryOptionals.address2" [(ngModel)]="deliveryOptionals.address2" type="text" disabled />
              <div class="bold"><span>City:</span></div>
              <input [(ngModel)]="deliveryOptionals.city" type="text" disabled />
              <div class="bold"><span>Country Code:</span></div>
              <input [(ngModel)]="deliveryOptionals.countryCode" type="text" disabled />
              <input
                *ngIf="deliveryOptionals.sub_country_code"
                [(ngModel)]="deliveryOptionals.sub_country_code"
                type="text"
                disabled />
              <!--                            <select name="country-select" [(ngModel)]="deliveryOptionals.countryCode">-->
              <!--                              <option [value]="'GBR'">Great Britain</option>-->
              <!--                            </select>-->
              <div class="bold"><span>Postal Code:</span></div>
              <input [(ngModel)]="deliveryOptionals.postalCode" type="text" disabled />
            </div>
          </div>

          <div class="summary-container">
            <div class="bold"><span>Summary:</span></div>
            <div class="list-element-container rounded-block" style="margin-top: 8px; flex-direction: row">
              <div>
                <div>Subtotal:</div>
                <div>Delivery:</div>
                <div *ngIf="serviceCharges">Service charges:</div>
                <div class="bold" style="margin-top: 8px">Total:</div>
              </div>
              <div>
                <div>
                  <span class="bold">{{
                    transactionData.total_price - serviceCharges | currency : dataService.currencyCode
                  }}</span>
                </div>
                <div>
                  <span class="bold">{{
                    deliveryMethodsArray[deliveryMethod].price | currency : dataService.currencyCode
                  }}</span>
                </div>
                <div *ngIf="serviceCharges">
                  <span class="bold">{{ serviceCharges | currency : dataService.currencyCode }}</span>
                </div>
                <div style="margin-top: 8px">
                  <span class="bold">{{
                    transactionData.total_price + deliveryMethodsArray[deliveryMethod].price
                      | currency : dataService.currencyCode
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="continue-button-container">
          <button (click)="cancelTransaction()" class="white-button">Cancel transaction</button>
          <div *ngIf="transactionData" class="total-price-container">
            <button *ngIf="!this.fiservFormData" (click)="checkout()" [disabled]="isCheckoutDisabled()">
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
