import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG } from './configuration/app.configuration';
import { AppConfiguration } from './models/appconfiguration.model';
import { DataService } from './services/data.service';
import { environment } from '../environments/environment';
import { ConfigurationService } from './services/configuration.service';
import { IdleService } from './services/idle.service';
import { AuthService } from './services/auth.service';
import { KickService } from './services/kick.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(APP_CONFIG) public appConfig: AppConfiguration,
    public dataService: DataService,
    private idleService: IdleService,
    private auth: AuthService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private titleService: Title,
    private kick: KickService,
    private activatedRoute: ActivatedRoute,
    private cookieBotService: NgxCookiebotService,
    private configurationService: ConfigurationService,
    private gtmService: GoogleTagManagerService
  ) {}

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.gtmService.addGtmToDom();
    this.setPageTitle();
    if (!this.idleService.getIsRunning()) {
      if (this.appConfig.idleService) {
        this.idleService.start();
      }
      const subEvict = this.kick.getEvictStatus().subscribe(respone => {
        if (respone.evict) {
          this.toLogout(subEvict);
        }
        this.kick.startCounter();
      });
      const subs = this.idleService.onTimeout().subscribe(() => {
        this.toLogout(subs);
      });
    }
    document.getElementById('favicon16').setAttribute('href', this.appConfig.favIco);
    document.getElementById('favicon32').setAttribute('href', this.appConfig.favIco);
    document.getElementById('faviconshort').setAttribute('href', this.appConfig.favIco);
    document.documentElement.style.setProperty('--accent-color', this.appConfig.accentColor);
    document.documentElement.style.setProperty('--hover-color', this.appConfig.hoverColor);
    document.documentElement.style.setProperty('--menu-color', this.appConfig.menuColor);
    this.cookieBotService.onServiceReady$
      .pipe(
        filter((ready: boolean) => {
          return ready;
        })
      )
      .subscribe(() => {
        this.cookieBotService.onLoad$.subscribe(() => {
          // wait for statistics consent to load analytics
          if (
            environment.analytics[this.configurationService.client] &&
            this.cookieBotService.cookiebot.consent.statistics
          ) {
            this.loadAnayliticsScripts(environment.analytics[this.configurationService.client]);
          }
          console.log(this.cookieBotService.cookiebot.consent);
        });
      });
  }

  private toLogout(subs: Subscription = null): void {
    this.auth
      .logout()
      .toPromise()
      .then(() => {
        if (subs) subs.unsubscribe();
        const url = location.href.match('^(?:https?:\\/\\/)?(?:[^@\\/\\n]+@)?(?:www\\.)?([^:\\/?\\n]+)');
        location.replace('https://www.chelseafc.com/en/tickets/mens-tickets');
      });
  }

  loadAnayliticsScripts(analyticsId) {
    // first part analytics node
    const node = document.createElement('script'); // creates the script tag
    node.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`; // sets the source (insert url in between quotes)
    node.async = true; // makes script run asynchronously
    node.type = 'text/javascript'; // set the script type
    document.getElementsByTagName('head')[0].appendChild(node);
    // second part analytics node
    const node2 = document.createElement('script'); // creates the script tag
    node2.innerHTML = `window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${analyticsId}');`;
    node2.type = 'text/javascript'; // set the script type
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node2);
  }

  // Cambia el titulo de la pestaña del navegador utilizando el texto que se haya escrito en el fichero de rutas
  // Si no tiene, pone un placeholder (line 103).
  private setPageTitle(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return 'Protected Sales Portal';
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.titleService.setTitle(data + ' | 3D Digital Venue');
        }
      });
  }
}
