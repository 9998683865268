import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventModel, MembershipTransactionCustomerModel, SaleTransactionCustomerModel, User } from '../../models';
import { FiservFormModel } from '../../models/fiserv-form.model';
import { Subject } from 'rxjs';
import { ConfigurationService } from '../../services/configuration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ConnectionService } from '../../services/connection.service';
import { FiservService } from '../../services/fiserv.service';
import { ModalsService } from '../../modals/modals.service';
import { DataService } from '../../services/data.service';
import { first } from 'rxjs/operators';
import { ExchangeTransactionModel } from '../models';
import { DeliveryMethod } from '../../models/delivery-method.model';

@Component({
  selector: 'app-checkout-exchange',
  templateUrl: './checkout-exchange.component.html',
  styleUrls: ['./checkout-exchange.component.scss'],
})
export class CheckoutExchangeComponent implements OnInit, OnDestroy {
  transactionId: number;
  eventId;
  userData: User;
  transactionData;
  transactionsListByEvent: {
    [key: string]: {
      event: EventModel;
      transactions: Array<SaleTransactionCustomerModel | MembershipTransactionCustomerModel>;
    };
  };
  expirationTime;
  accordionCustomer = {};
  interfaceBuyerTypeByCustomer = {};
  serviceCharges = 0;
  deliveryMethodsArray = [];
  deliveryMethod = 0;
  deliveryOptionals = {
    email: '',
    address1: '',
    address2: '',
    city: '',
    countryCode: '',
    postalCode: '',
    sub_country_code: '',
    sub_country_name: '',
  };
  isMobile = false;

  fiservFormData: FiservFormModel;
  ready2Pay: boolean;

  timerObservable = new Subject<string>();
  timer;
  interval;

  get getTimer() {
    return this.timer;
  }

  constructor(
    public configurationService: ConfigurationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private connection: ConnectionService,
    private fiservService: FiservService,
    private modalService: ModalsService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    // Check transaction ID
    if (this.activatedRoute.snapshot.queryParamMap.has('transaction')) {
      this.transactionId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('transaction'));
    } else {
      this.router.navigate(['exchange']);
    }

    // check mobile mode ???
    const bodyElement = document.getElementsByTagName('body');
    if (bodyElement[0].clientWidth <= 576) {
      this.isMobile = true;
    }

    this.auth
      .getUserLogged$()
      .pipe(first())
      .subscribe(response => {
        this.userData = response as User;
        if (response['exchange_transactions'] && response['exchange_transactions'].length) {
          response['exchange_transactions'].forEach((transaction: ExchangeTransactionModel) => {
            if (transaction.id == this.transactionId) {
              this.deliveryOptionals.email = this.userData.email;
              this.deliveryOptionals.address1 = this.userData.tdc_info.address.address1
                ? this.userData.tdc_info.address.address1
                : '';
              this.deliveryOptionals.address2 = this.userData.tdc_info.address.address2
                ? this.userData.tdc_info.address.address2
                : '';
              this.deliveryOptionals.city = this.userData.tdc_info.address.city;
              this.deliveryOptionals.postalCode = this.userData.tdc_info.address.postal_code;
              this.deliveryOptionals.countryCode = this.userData.tdc_info.address.address_country_code;
              this.deliveryOptionals.sub_country_code = this.userData.tdc_info.address.address_sub_country_code
                ? this.userData.tdc_info.address.address_sub_country_code
                : '';
              this.deliveryOptionals.sub_country_name = this.userData.tdc_info.address.address_sub_country_name
                ? this.userData.tdc_info.address.address_sub_country_name
                : '';
              this.eventId = transaction['tdc_event_id'];
              this.transactionData = transaction;
              this.deliveryMethodsArray = transaction.delivery_methods_available;
              this.expirationTime = transaction['tdc_expiration_time'];
              this.timerObservable.subscribe(timer => {
                this.timer = timer;
              });
              this.setTimer();
              this.setTimerInterval();
              this.prepareAccordeonObject(transaction.sale_transaction_customers);
            }
          });
        }
      });

    // Get event information
    if (!Object.values(this.dataService.exchangeEventsList).length) {
      this.connection.getEventsList().subscribe(response => {
        response.forEach(event => {
          this.dataService.exchangeEventsList[event.id] = event;
        });
      });
    }
  }

  ngOnDestroy() {
    this.timerObservable.unsubscribe();
    clearInterval(this.interval);
  }

  checkout(): void {
    const title = 'Continue?';
    const message =
      'I hereby confirm that I have read the Chelsea Football Club\'s Conditions Of Issue which are available for inspection at <a href="https://www.chelseafc.com/en/tickets-guides-policies-and-forms" target="_blank">www.chelseafc.com/en/tickets-guides-policies-and-forms</a> and posted at the club\'s offices and agree to abide by them.<br><br> I understand that my season ticket/membership/match ticket may be withdrawn, revoked or terminated as provided therein. Chelsea FC will use your personal data in accordance with its privacy policy, which you can read at <a href="https://www.chelseafc.com/en/footer/privacy-policy" target="_blank">www.chelseafc.com/en/footer/privacy-policy</a>.';
    const accept = 'Continue';
    this.modalService.info(
      title,
      message,
      accept,
      () => {
        const deliveryMethod: DeliveryMethod = {
          delivery_method_id: this.deliveryMethodsArray[this.deliveryMethod].id,
          delivery_method_type: this.deliveryMethodsArray[this.deliveryMethod].type,
          delivery_first_name: this.userData.tdc_info.first_name,
          delivery_last_name: this.userData.tdc_info.last_name,
          delivery_email: this.deliveryOptionals.email,
          delivery_address1: this.deliveryOptionals.address1,
          // delivery_address2: this.deliveryOptionals.address2,
          delivery_city: this.deliveryOptionals.city,
          delivery_country_code: this.deliveryOptionals.countryCode,
          delivery_postal_code: this.deliveryOptionals.postalCode,
        };
        if (this.deliveryOptionals.sub_country_code)
          deliveryMethod.delivery_sub_country_code = this.deliveryOptionals.sub_country_code;
        if (this.deliveryOptionals.sub_country_name)
          deliveryMethod.delivery_sub_country_name = this.deliveryOptionals.sub_country_name;
        if (this.deliveryOptionals.address2) deliveryMethod.delivery_address2 = this.deliveryOptionals.address2;

        this.connection.putExchangeTransaction(this.transactionId, deliveryMethod).subscribe(
          response => {
            this.modalService.adyenPaymentModal(response.session_id, response.session_data, response.return_url);
          },
          error => {
            console.error(error);
          }
        );
      },
      null,
      null,
      'modal-lg'
    );
  }

  cancelTransaction(): void {
    if (this.transactionId) {
      const title = 'Continue?';
      const message = 'Your transaction will be cancelled and your seats will be released. Are you sure?';
      const accept = 'Cancel transaction';
      this.modalService.info(title, message, accept, () => {
        let deleteFunc = this.connection.deleteExchangeTransaction(this.transactionId);
        deleteFunc.subscribe(
          response => {
            console.log(response);
            this.auth.getUserLogged(true).then(() => {
              this.dataService.eventsAlreadyPickList = [];
              this.router.navigate(['exchange']);
            });
          },
          error => {
            console.error(error);
            this.auth.getUserLogged(true).then(response => {
              console.log(response);
              this.router.navigate(['buy-tickets']);
            });
          }
        );
      });
    }
  }

  isCheckoutDisabled() {
    if (this.deliveryMethodsArray[this.deliveryMethod].type === 'EXTERNAL_TICKETS_AT_HOME') {
      return !this.deliveryOptionals.email;
    } else if (
      this.deliveryMethodsArray[this.deliveryMethod].type === 'POSTAL' ||
      this.deliveryMethodsArray[this.deliveryMethod].type === 'POSTAL_TRACKABLE'
    ) {
      return !(
        this.deliveryOptionals.address1 &&
        this.deliveryOptionals.city &&
        this.deliveryOptionals.countryCode &&
        this.deliveryOptionals.postalCode
      );
    }
    return false;
  }

  private prepareAccordeonObject(saleTransactionCustomer: Array<SaleTransactionCustomerModel>): void {
    saleTransactionCustomer.forEach(trans => {
      this.accordionCustomer[trans.name] = false;
      let customerName = trans.name;
      trans.buyer_type_info.forEach(info => {
        let buyerTypeName = info.buyer_type_name;
        if (!this.interfaceBuyerTypeByCustomer.hasOwnProperty(customerName))
          this.interfaceBuyerTypeByCustomer[trans.name] = {};
        if (!this.interfaceBuyerTypeByCustomer[customerName].hasOwnProperty(buyerTypeName))
          this.interfaceBuyerTypeByCustomer[customerName][buyerTypeName] = false;
      });
    });
  }

  private setTimerInterval() {
    this.interval = setInterval(() => {
      this.setTimer();
    }, 1000);
  }

  private setTimer() {
    const appointmentTimestamp = new Date(this.expirationTime).getTime();
    const now = new Date().getTime();
    // let diff = Math.abs(appointmentTimestamp - now) / 1000;
    let diff = (appointmentTimestamp - now) / 1000;
    if (diff <= 0) {
      diff = 0;
      clearInterval(this.interval);
      this.modalService.info(
        'Transaction expired',
        'Your transaction has expired. Please, click the button below to return to the home page.',
        'Return',
        () => {
          this.auth.getUserLogged(true).then(() => {
            this.router.navigate(['exchange']);
          });
        },
        null,
        null,
        null,
        true
      );
    }
    const timeString = this.dataService.time2string(diff);
    this.timerObservable.next(timeString);
  }
}
