import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../configuration/app.configuration';
import { DVM_CONFIG } from '../configuration/dvm.configuration';
import { AppConfiguration } from '../models/appconfiguration.model';
import { DVMConfiguration } from '../models/dvmconfiguration.model';
import * as configurationJson from './../../app/configuration/configuration.json';
import * as countryJson from '../../assets/data/countries-code.json';
import * as subCountryJson from '../../assets/data/sub-country-dict.json';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  _client;
  public get client() {
    return this._client;
  }

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    private dataService: DataService,
    @Inject(DVM_CONFIG) private DVMConfig: DVMConfiguration
  ) {}

  public init() {
    // retrieve json from somewhere or have the json compiled and choose one based on url or any other logic
    // https://regex101.com/
    const regex =
      /^(?:http|https):\/\/(?:local\.)?(?:private\.)?(?:demo-)?(?:dev-)?(?:pre-)?(?:uat-)?(.*)\.3ddigitalvenue\.com.*/gm;
    const client = regex.exec(location.href)[1];
    this._client = client;
    if (client && typeof client === 'string') {
      const configuration = configurationJson['default'][client];
      // const text = textJson['default'][client];
      return new Promise<any>((success, reject) => {
        if (configuration) {
          for (const [key, value] of Object.entries(configuration)) {
            this.appConfig[key] = value;
          }
        }
        if (configuration.venue.male.venueId && configuration.venue.male.venueId) {
          this.DVMConfig.venue_id = configuration.venue.male.venueId;
          this.DVMConfig.map_id = configuration.venue.male.mapId;
        }
        // if (text) {
        //     for (const [key, value] of Object.entries(text)) {
        //         this.appConfig[key] = value;
        //     }
        // }
        this.dataService.countryList = countryJson['default'];
        this.dataService.subCountryDictionary = subCountryJson['default'];
        success(null);
      });
    }
  }

  public getFemaleVenue(): any {
    return this.appConfig.venue.female;
  }
}
