import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { ConnectionService } from '../../services/connection.service';
import { User } from '../../models';
import { ExchangeEventModel } from '../models';

@Component({
  selector: 'app-my-events',
  templateUrl: './my-events.component.html',
  styleUrls: ['./my-events.component.scss'],
})
export class MyEventsComponent implements OnInit {
  user: User;
  isAccountVerifided: boolean = false;
  myEventsList: Array<ExchangeEventModel>;

  constructor(public dataService: DataService, private auth: AuthService, public connection: ConnectionService) {}

  ngOnInit(): void {
    this.auth.getUserLogged$().subscribe(response => {
      if (typeof response === 'boolean') {
        return;
      }
      this.user = response;
      this.myEventsList = this.dataService.customerEventsList[this.user.tdc_info.id] || [];
      if (!this.myEventsList.length) {
        // tslint:disable-next-line: variable-name
        this.connection.getCustomerInventory().subscribe(_response => {
          console.log('Exchange events -> ', _response);
          this.myEventsList = _response;
          this.dataService.customerEventsList[this.user.tdc_info.id] = _response;
        });
      }
    });
  }

  isVerifidedAccount(isVerifided: boolean) {
    this.isAccountVerifided = isVerifided;
  }
}
