import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from 'src/app/configuration/app.configuration';
import { AppConfiguration } from 'src/app/models/appconfiguration.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  username;
  password;
  password2;
  backgroundUrl;

  token;

  requestSent = false;

  constructor(
    @Inject(APP_CONFIG) public appConfig: AppConfiguration,
    private connection: ConnectionService,
    private auth: AuthService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.backgroundUrl = `url(${this.appConfig.backgroundImageUrl})`;
    if (this.activatedRoute.snapshot.params) {
      if (this.activatedRoute.snapshot.params.t) {
        this.token = this.activatedRoute.snapshot.params.t;
      }
    }
  }

  sendEmail() {
    if (this.username) {
      this.auth.postResetPassword(this.username).subscribe(
        response => {
          console.log(response);
          this.requestSent = true;
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  register() {
    if (this.token && this.password && this.password2 && this.password === this.password2) {
      this.auth.postResetPasswordFinalize(this.token, this.password).subscribe(
        response => {
          console.log(response);
          this.requestSent = true;
        },
        error => {
          console.error(error);
          this.dataService.isLoaderActive = false;
        }
      );
    }
  }
}
