<div>
  <div class="super-container">
    <div class="modal-header bold">
      <h4 class="pull-left">{{ title }}</h4>
    </div>
    <div class="modal-body" *ngIf="priceList">
      <div class="container flex f-column">
        <ng-container *ngFor="let price of priceList" >
          <div class="rounded-block flex f-column list-element-container" [ngClass]="{ 'selected': selectedPrice.id === price.id }" (click)="select(price)">
            <span>{{ price.name }}</span>
            <span>{{ price.price | currency:'GBP' }}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="modal-footer"></div>
  </div>
</div>
