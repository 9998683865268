import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ModalsService } from '../modals/modals.service';
import { AuthorizationData, User } from '../models';
import { DataService } from './data.service';
import { IdleService } from './idle.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // apiUrl = 'https://smp.eu-west-1.service.3ddvfactory.com/friends-family';
  apiUrl = environment.apiUrl;
  // apiUrl = 'http://127.0.0.1:8000/friends-family';

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private modalService: ModalsService,
    private idleService: IdleService,
    private router: Router
  ) {
    this.userLogged$ = new BehaviorSubject<User | undefined>(undefined);
  }

  // General - Send Request
  private sendRequest(endpoint, body = null, responsetype = null) {
    const finalUrl = `${endpoint}`;
    console.log(finalUrl, body);
    if (!responsetype) {
      responsetype = 'json';
    }
    const observable = body ? this.http.post(finalUrl, body, { responseType: responsetype }) : this.http.get(finalUrl);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(response => {
        this.dataService.isLoaderActive = false;
      }, this.requestErrorFunction)
    );
  }

  private requestErrorFunction = error => {
    let errorMessage = '';
    try {
      const mmcError: { code: string; message: string } = JSON.parse(error.error);
      errorMessage = mmcError.message;
    } catch (e) {
      if (error.error.message) {
        errorMessage = error.error.message;
      }
    }

    if (error.status === 500) {
      errorMessage = 'We were unable to process your request please try again';
    }
    this.modalService.info('Error', errorMessage);
    this.dataService.isLoaderActive = false;
  };

  private currentUser: any;
  private authorizationUrl = '/auth';
  private csrftoken = null;
  private userLogged$: BehaviorSubject<User | false>;

  private tapSetUser = tap((result: User) => {
    this.dataService.isLoaderActive = false;
    const retValue = this.setUser(result);
    this.currentUser = retValue;
    this.userLogged$.next(retValue);
  });

  private setUser(result: User) {
    let retValue: User | false = result;
    if (result.csrf) {
      this.csrftoken = result.csrf;
    }
    // think return in backend id = false and do !user.id comparisons in components instead of having to type DjangoBaseUser | false in all
    if (result.id === -1) {
      retValue = false;
    }
    return retValue;
  }

  private _getUserLogged(): Observable<User> {
    return this.http
      .get<User>(`${this.apiUrl}${this.authorizationUrl}/logged/`)
      .pipe(this.tapSetUser) as Observable<User>;
  }

  // Reset Password
  private _postResetPassword(email: string) {
    const body = {
      email,
    };
    let endpoint = `${this.apiUrl}${this.authorizationUrl}/password-reset/`;
    return this.sendRequest(endpoint, body, 'text');
  }

  // Register Customer Finalize
  private _postResetPasswordFinalize(token: string, password: string) {
    const body = {
      token,
      password,
    };
    let endpoint = `${this.apiUrl}${this.authorizationUrl}/password-reset/finalize/`;
    return this.sendRequest(endpoint, body, 'text');
  }

  login(credentials: AuthorizationData): Observable<User> {
    const data = {
      username: credentials.username,
      password: credentials.password,
    };
    // this.dataService.isLoaderActive = true;
    return this.http
      .post(`${this.apiUrl}${this.authorizationUrl}/login/`, data)
      .pipe(this.tapSetUser) as Observable<User>;
  }

  logout() {
    this.userLogged$.next(undefined);
    this.currentUser = undefined;
    return this.http.get(`${this.apiUrl}${this.authorizationUrl}/logout/`);
  }

  getUserLogged(force = false): Promise<User | false> {
    const promise = new Promise<User | false>((resolve, reject) => {
      if (this.currentUser === undefined || force) {
        this._getUserLogged()
          .toPromise()
          .then(data => {
            resolve(this.setUser(data));
          });
      } else {
        resolve(this.currentUser);
      }
    });
    return promise;
  }

  getUserLogged$(): Observable<User | false> {
    return this.userLogged$.asObservable();
  }

  canActivate(): boolean {
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }

  getCsrfToken() {
    return this.csrftoken;
  }

  // Post Customer Register
  public postResetPassword(email: string): Observable<any> {
    return this._postResetPassword(email).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Post Customer Register Finalize
  public postResetPasswordFinalize(token: string, password: string): Observable<any> {
    return this._postResetPasswordFinalize(token, password).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
