import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ModalsService } from '../modals/modals.service';
import { DeliveryMethod } from '../models/delivery-method.model';
import { DataService } from './data.service';
import { environment } from '../../environments/environment';
import { CustomerAssociationInverseModel, EventModel, TraitDataModel } from '../models';
import { TDCInfoModel } from '../models/user.model';
import { AccountManagementDataType } from '../account-management/account-management-data.type';
import {
  AvailabilitySeatmapModel,
  CreateExchangeTransactionResponseModel,
  EventTicketModel,
  ExchangeEventModel,
  TicketModel,
} from '../exchange-tickets/models';
import { TraitModel } from '../models/trait.model';
import { NewAccount } from '../login/new-account/new-account';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  // apiUrl = 'https://smp.eu-west-1.service.3ddvfactory.com/friends-family';
  apiUrl = environment.apiUrl;

  // apiUrl = 'http://127.0.0.1:8000/friends-family';

  constructor(
    private http: HttpClient,
    private router: Router,
    private modalService: ModalsService,
    private dataService: DataService
  ) {}

  // General - Send Request
  public sendRequest(endpoint, body = null, responsetype = null) {
    const finalUrl = `${endpoint}`;
    if (!responsetype) {
      responsetype = 'json';
    }
    const observable = body ? this.http.post(finalUrl, body, { responseType: responsetype }) : this.http.get(finalUrl);
    // if evict call don't show the loader
    if (!endpoint.includes('evict')) {
      this.dataService.isLoaderActive = true;
    }
    return observable.pipe(
      tap(response => {
        this.dataService.isLoaderActive = false;
      }, this.requestErrorFunction)
    );
  }

  public requestErrorFunction = error => {
    console.log(error);
    // if evict don't show error modal
    if (error.url.includes('evict')) {
      return;
    }
    let mmcError: { code: string; message: string } = error.error;
    const isExchange = error.url.includes('exchange_transaction');
    let title = 'System Message';
    if (typeof error.error === 'string') {
      try {
        mmcError = JSON.parse(error.error);
      } catch (e) {
        console.log('error parse');
      }
    }
    let errorMessage = error.message;
    if (error.error.message) {
      errorMessage = error.error.message;
    }
    // generic messaging if unknown error
    if (error.status === 500 || errorMessage.includes('Unknown Error')) {
      errorMessage = 'We were unable to process your request please try again';
    }
    if (mmcError && mmcError.code && mmcError.code === 'TI') {
      errorMessage = 'The link you followed has expired or is invalid.';
    }
    if (mmcError && mmcError.code && mmcError.code !== 'TI') {
      // fix for putting returned api message in modal
      errorMessage = mmcError.message;
    }

    // Error si no se encuentran eventos
    if (mmcError && mmcError.code && mmcError.code === 'BFE') {
      title = 'Event Schedule';
      errorMessage = 'There are no events are available at this time';
    }

    // Error si no se encuentran paquetes
    if (mmcError && mmcError.code && mmcError.code === 'NPA') {
      title = 'Package Schedule';
      errorMessage = 'There are no packages available at this time';
    }

    //IT-4619 - UAT Fixes system message- Frontend
    if(mmcError && mmcError.code && mmcError.code === 'MMCFA'){
      title = 'System Message';
    }

    if (error.status === 403) {
      title = 'Please enable third party cookies';
      errorMessage =
        'If you are receiving this message please enable third party cookies and cross site tracking to be able to enter' +
        ' the site - <a href="/assets/cookies.pdf" target="_blank">click here</a> for more information';
    }
    if (error.error.associations && this.dataService.lastEvent) {
      errorMessage = errorMessage + ':';
      error.error.associations.forEach(association => {
        errorMessage += `\n${association.name}:\n${association.reason}\n`;
        if (!this.dataService.associationsNotAllowed[this.dataService.lastEvent]) {
          this.dataService.associationsNotAllowed[this.dataService.lastEvent] = {};
        }
        this.dataService.associationsNotAllowed[this.dataService.lastEvent][association.id] = false;
      });
      this.modalService.info(
        'Requirements not met',
        errorMessage,
        'Return',
        () => {
          let trans = this.dataService.fromTransaction;
          this.dataService.fromTransaction = null;
          if (isExchange) {
            // Si es un error de una transaccion exchange, devolvemos al usuario al lugar adecuado
            this.router.navigate(['/exchange/select-a-friends'], {
              queryParams: { event: this.dataService.lastEvent, venue: this.dataService.lastVenue },
            });
          } else {
            this.router.navigate([
              '/buy-tickets/select-friends',
              { event: this.dataService.lastEvent, venue: this.dataService.lastVenue },
            ]);
          }
        },
        null,
        null,
        'modal-lg',
        true
      );
    } else if (error.url.includes('friends-family/customer/associations/inverse/') && error.status === 404) {
      // En sandbox esta peticion siempre peta ya que no existe vpn/replication en este entorno, esto es un forma chusca de que no salte el modal de error
      return;
    } else {
      error.error.code === "TCR01" ? title = 'System Message' : false;
      this.modalService.info(title, errorMessage);
    }
    this.dataService.isLoaderActive = false;
  };

  /*
   *   PRIVATE METHODS
   */

  // GET

  private _getEvictStatus(): Observable<any> {
    return this.sendRequest(`${this.apiUrl}/customer/evict/`);
  }

  // Events List
  private _getEventsList() {
    let endpoint;
    endpoint = `${this.apiUrl}/events/`;
    return this.sendRequest(endpoint);
  }

  // Event Availability
  private _getEventAvailability(eventId) {
    let endpoint;
    endpoint = `${this.apiUrl}/events/${eventId}/availability/`;
    return this.sendRequest(endpoint);
  }

  // Customer Associations
  private _getCustomerAssociations(complete = false) {
    let endpoint;
    if (!complete) {
      endpoint = `${this.apiUrl}/customer/associations/`;
    } else {
      endpoint = `${this.apiUrl}/customer/associations/complete/`;
    }
    return this.sendRequest(endpoint);
  }

  private _getCustomerInverseAssociations(): Observable<any> {
    return this.sendRequest(`${this.apiUrl}/customer/associations/inverse/`);
  }

  // Transaction
  private _getSaleTransaction(transactionId) {
    let endpoint;
    endpoint = `${this.apiUrl}/sale_transaction/${transactionId}/`;
    return this.sendRequest(endpoint);
  }

  private _getReservationTransaction(transactionId) {
    let endpoint;
    endpoint = `${this.apiUrl}/reservation_transaction/${transactionId}/`;
    return this.sendRequest(endpoint);
  }

  // Transaction
  private _getSaleTransactionPaymentData(transactionId) {
    let endpoint;
    endpoint = `${this.apiUrl}/sale_transaction/${transactionId}/`;
    const observable = this.http.patch(endpoint, {});
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  // Reservation Transaction
  private _getReservationTransactionPaymentData(transactionId) {
    let endpoint;
    endpoint = `${this.apiUrl}/reservation_transaction/${transactionId}/`;
    const observable = this.http.patch(endpoint, {});
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  //
  private _getMembershipTransactionPaymentData(transactionId) {
    const observable = this.http.patch(`${this.apiUrl}/membership_transaction/${transactionId}/`, {});
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  private _getExchangeTransactionPaymentData(transactionId): Observable<any> {
    const obs = this.http.patch(`${this.apiUrl}/exchange_transaction/${transactionId}/`, {});
    return obs.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  // Event Morfology
  private _getEventMorfology(eventId) {
    let endpoint;
    endpoint = `${this.apiUrl}/events/${eventId}/morfology/`;
    return this.sendRequest(endpoint);
  }

  // Reservations
  private _getReservations(associations: number[]) {
    let endpoint = `${this.apiUrl}/customer/reservations/`;

    if (associations && associations.length) {
      associations.forEach((associationId, index) => {
        if (index === 0) {
          endpoint += `?`;
        } else {
          endpoint += `&`;
        }
        endpoint += `friend_family_account=${associationId}`;
      });
    }
    return this.sendRequest(endpoint);
  }

  // get user inventory
  private _getUserInventory(): Observable<any> {
    let endpoint = `${this.apiUrl}/ticket_exchange/customer/inventory/`;
    return this.sendRequest(endpoint);
  }

  // get tickets from event
  private _getTicketsFromEvent(eventId: number): Observable<any> {
    let endpoint = `${this.apiUrl}/ticket_exchange/customer/event/${eventId}/`;
    return this.sendRequest(endpoint);
  }

  private _getMembershipTransaction(transactionId: number): Observable<any> {
    let endpoint;
    endpoint = `${this.apiUrl}/membership_transaction/${transactionId}/`;
    return this.sendRequest(endpoint);
  }

  private _getPatronTrait(): Observable<any> {
    return this.sendRequest(`${this.apiUrl}/customer/trait/`);
  }

  //=== EXCHANGE
  // availability topview
  private _getEventAvailabilityExchange(eventId: number, buyerTypeName: string): Observable<any> {
    return this.sendRequest(
      `${this.apiUrl}/ticket_exchange/events/availability/${eventId}/?buyer_type_name=${buyerTypeName}`
    );
  }

  // Availability seatmap
  private _getEventSectionAvailabilityExchange(eventId: number, section: string): Observable<any> {
    return this.sendRequest(`${this.apiUrl}/ticket_exchange/events/availability/${eventId}/${section}`);
  }

  private _getEventsExchange(): Observable<any> {
    return this.sendRequest(`${this.apiUrl}/ticket_exchange/events/`);
  }

  // POST

  // Sale Transaction
  private _postSaleTransaction(
    eventId: number,
    priceScale: string,
    customerAssociations: Array<any>,
    masterTransactionId: number = null
  ) {
    const body = {
      event: eventId, // el id que te devuelvo en events
      price_scale: priceScale, //la key de los diccionarios de la request de availability
      friends_family_accounts: customerAssociations, // listado de objetos
    };
    if (masterTransactionId) {
      body['from_transaction'] = masterTransactionId;
    }
    let endpoint = `${this.apiUrl}/sale_transaction/`;
    return this.sendRequest(endpoint, body);
  }

  // Customer Association
  private _postCustomerAssociation(email: string) {
    const body = {
      email,
    };
    let endpoint = `${this.apiUrl}/customer/associations/add/`;
    return this.sendRequest(endpoint, body);
  }

  // Register Customer
  private _postCustomerRegister(email: string) {
    const body = {
      email,
    };
    let endpoint = `${this.apiUrl}/customer/register/`;

    return this.http.post(endpoint, body, { responseType: 'json' }).pipe(
      tap(
        data => {
          this.dataService.isLoaderActive = false;
        },
        error => (this.dataService.isLoaderActive = false)
      )
    );
  }

  // Register Customer Finalize
  private _postCustomerRegisterFinalize(token: string, password: string) {
    const body = {
      token,
      password,
    };
    let endpoint = `${this.apiUrl}/customer/register/finalize/`;
    return this.sendRequest(endpoint, body, 'text');
  }

  // Register Customer Finalize
  private _postAddAssociationFinalize(token: string) {
    const body = {
      token,
    };
    let endpoint = `${this.apiUrl}/customer/associations/add/finalize/`;
    return this.sendRequest(endpoint, body, 'text');
  }

  // Create reservation
  private _postReservationTransaction(orders) {
    const body = {
      friends_family_orders: orders,
    };
    let endpoint = `${this.apiUrl}/reservation_transaction/`;
    return this.sendRequest(endpoint, body, 'text');
  }

  // Create Membership transaction
  private _postMembershipTransaction(body): Observable<any> {
    let endpoint = `${this.apiUrl}/membership_transaction/`;
    return this.sendRequest(endpoint, body, 'text');
  }

  private _postConsignBackTicket(body: any): Observable<any> {
    let endpoint = `${this.apiUrl}/customer/market_offer/`;
    return this.sendRequest(endpoint, body);
  }

  private _postExchangeTransaction(body): Observable<any> {
    return this.sendRequest(`${this.apiUrl}/exchange_transaction/`, body);
  }

  // Create trait for customer
  private _postPatronTrait(body: TraitDataModel): Observable<any> {
    return this.sendRequest(`${this.apiUrl}/customer/trait/`, body);
  }

  // PUT

  // Sale Transaction
  private _putSaleTransaction(
    transactionId: number,
    delivery_method_id: number,
    delivery_method_type: string,
    delivery_first_name: string,
    delivery_last_name: string,
    delivery_email?: string,
    delivery_address1?: string,
    delivery_address2?: string,
    delivery_city?: string,
    delivery_country_code?: string,
    delivery_postal_code?: string,
    delivery_sub_country_code?: string,
    delivery_sub_country_name?: string
  ) {
    const body = {
      delivery_method_id, // info de transaction en el logged
      delivery_method_type, // info de transaction en el logged
      delivery_first_name, // Input
      delivery_last_name, // Input
    };

    if (delivery_method_type === 'EXTERNAL_TICKETS_AT_HOME') {
      body['delivery_email'] = delivery_email;
    } else if (delivery_method_type === 'POSTAL' || delivery_method_type === 'POSTAL_TRACKABLE') {
      body['delivery_address1'] = delivery_address1;
      body['delivery_address2'] = delivery_address2;
      body['delivery_city'] = delivery_city;
      body['delivery_country_code'] = delivery_country_code;
      body['delivery_postal_code'] = delivery_postal_code;
      if (delivery_sub_country_code) body['delivery_sub_country_code'] = delivery_sub_country_code;
      if (delivery_sub_country_name) body['delivery_sub_country_name'] = delivery_sub_country_name;
    }

    let endpoint = `${this.apiUrl}/sale_transaction/${transactionId}/`;
    const finalUrl = `${endpoint}`;
    const observable = this.http.put(finalUrl, body);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  // Reservation Transaction
  private _putReservationTransaction(
    transactionId: number,
    delivery_method_id: number,
    delivery_method_type: string,
    delivery_first_name: string,
    delivery_last_name: string,
    delivery_email?: string,
    delivery_address1?: string,
    delivery_address2?: string,
    delivery_city?: string,
    delivery_country_code?: string,
    delivery_postal_code?: string,
    delivery_sub_country_code?: string,
    delivery_sub_country_name?: string
  ) {
    const body = {
      delivery_method_id, // info de transaction en el logged
      delivery_method_type, // info de transaction en el logged
      delivery_first_name, // Input
      delivery_last_name, // Input
    };

    if (delivery_method_type === 'EXTERNAL_TICKETS_AT_HOME') {
      body['delivery_email'] = delivery_email;
    } else if (delivery_method_type === 'POSTAL' || delivery_method_type === 'POSTAL_TRACKABLE') {
      body['delivery_address1'] = delivery_address1;
      body['delivery_address2'] = delivery_address2;
      body['delivery_city'] = delivery_city;
      body['delivery_country_code'] = delivery_country_code;
      body['delivery_postal_code'] = delivery_postal_code;
      body['delivery_sub_country_code'] = delivery_sub_country_code;
      if (delivery_sub_country_name) body['delivery_sub_country_name'] = delivery_sub_country_name;
    }

    let endpoint = `${this.apiUrl}/reservation_transaction/${transactionId}/`;
    const finalUrl = `${endpoint}`;
    const observable = this.http.put(finalUrl, body);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  // Update user data (name, email, etc...)
  private _putCustomerData(body: any, endpoint: string): Observable<any> {
    const observable = this.http.put(endpoint, body);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        () => (this.dataService.isLoaderActive = false),
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  private _putMembershipTransaction(
    transactionId: number,
    delivery_method_id: number,
    delivery_method_type: string,
    delivery_first_name: string,
    delivery_last_name: string,
    delivery_email?: string,
    delivery_address1?: string,
    delivery_address2?: string,
    delivery_city?: string,
    delivery_country_code?: string,
    delivery_postal_code?: string,
    delivery_sub_country_code?: string,
    delivery_sub_country_name?: string
  ): Observable<any> {
    const body = {
      delivery_method_id, // info de transaction en el logged
      delivery_method_type, // info de transaction en el logged
      delivery_first_name, // Input
      delivery_last_name, // Input
    };

    if (delivery_method_type === 'EXTERNAL_TICKETS_AT_HOME') {
      body['delivery_email'] = delivery_email;
    } else if (delivery_method_type === 'POSTAL' || delivery_method_type === 'POSTAL_TRACKABLE') {
      body['delivery_address1'] = delivery_address1;
      body['delivery_address2'] = delivery_address2;
      body['delivery_city'] = delivery_city;
      body['delivery_country_code'] = delivery_country_code;
      body['delivery_postal_code'] = delivery_postal_code;
      body['delivery_sub_country_code'] = delivery_sub_country_code;
      if (delivery_sub_country_name) body['delivery_sub_country_name'] = delivery_sub_country_name;
    }

    let endpoint = `${this.apiUrl}/membership_transaction/${transactionId}/`;
    const finalUrl = `${endpoint}`;
    const observable = this.http.put(finalUrl, body);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  // Sale Transaction
  private _putExchangeTransaction(
    transactionId: number,
    delivery_method_id: number,
    delivery_method_type: string,
    delivery_first_name: string,
    delivery_last_name: string,
    delivery_email?: string,
    delivery_address1?: string,
    delivery_address2?: string,
    delivery_city?: string,
    delivery_country_code?: string,
    delivery_postal_code?: string,
    delivery_sub_country_code?: string,
    delivery_sub_country_name?: string
  ) {
    const body = {
      delivery_method_id, // info de transaction en el logged
      delivery_method_type, // info de transaction en el logged
      delivery_first_name, // Input
      delivery_last_name, // Input
    };

    if (delivery_method_type === 'EXTERNAL_TICKETS_AT_HOME') {
      body['delivery_email'] = delivery_email;
    } else if (delivery_method_type === 'POSTAL' || delivery_method_type === 'POSTAL_TRACKABLE') {
      body['delivery_address1'] = delivery_address1;
      body['delivery_address2'] = delivery_address2;
      body['delivery_city'] = delivery_city;
      body['delivery_country_code'] = delivery_country_code;
      body['delivery_postal_code'] = delivery_postal_code;
      if (delivery_sub_country_code) body['delivery_sub_country_code'] = delivery_sub_country_code;
      if (delivery_sub_country_name) body['delivery_sub_country_name'] = delivery_sub_country_name;
    }

    let endpoint = `${this.apiUrl}/exchange_transaction/${transactionId}/`;
    const finalUrl = `${endpoint}`;
    const observable = this.http.put(finalUrl, body);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  private _putPatronTrait(body: TraitDataModel, id: number): Observable<any> {
    const observable = this.http.put(`${this.apiUrl}/customer/trait/${id}/`, body);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  // DELETE

  // Sale Transaction
  private _deleteSaleTransaction(transactionId: number) {
    let endpoint = `${this.apiUrl}/sale_transaction/${transactionId}/`;
    const finalUrl = `${endpoint}`;
    const observable = this.http.delete(finalUrl);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          // if (error.error.code !== 'TCAU01') {
          //   let errorMessage = error.message;
          //   if (error.error.message) {
          //     errorMessage = error.error.message;
          //   }
          //   if (error.status === 500) {
          //     errorMessage = 'We were unable to process your request please try again';
          //   }
          //   this.modalService.info('Error', errorMessage);
          // }
          if (error.status === 500) {
            let errorMessage =
              'We were unable to process your request please try again';
            this.modalService.info('Error', errorMessage);
          }
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  // Customer Association you can purchase on behalf of your friends
  private _deleteCustomerAssociation(associateId: number) {
    let endpoint = `${this.apiUrl}/customer/associations/${associateId}/`;
    const finalUrl = `${endpoint}`;
    const observable = this.http.delete(finalUrl);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  // Reservation Transaction
  private _deleteReservationTransaction(transactionId: number) {
    let endpoint = `${this.apiUrl}/reservation_transaction/${transactionId}/`;
    const finalUrl = `${endpoint}`;
    const observable = this.http.delete(finalUrl);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  // Remove who can buy on you behalf
  private _deleteCustomerAssocationInverse(patronId: number, patronAssId: number): Observable<any> {
    let endpoint = `${this.apiUrl}/customer/associations/inverse/${patronId}/${patronAssId}/`;
    const observable = this.http.delete(endpoint);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        () => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          if (error.status === 500) {
            errorMessage =
              'We were unable to process your request please try again';
          }
          this.modalService.info('Error', errorMessage);
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  // Cancel membership transacion
  private _deleteMembershipTransaction(transactionId: number): Observable<any> {
    let endpoint = `${this.apiUrl}/membership_transaction/${transactionId}/`;
    const observable = this.http.delete(endpoint);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          // if (error.error.code !== 'TCAU01') {
          //   let errorMessage = error.message;
          //   if (error.error.message) {
          //     errorMessage = error.error.message;
          //   }
          //   if (error.status === 500) {
          //     errorMessage = 'We were unable to process your request please try again';
          //   }
          //   this.modalService.info('Error', errorMessage);
          // }
          if (error.status === 500) {
            let errorMessage =
              'We were unable to process your request please try again';
            this.modalService.info('Error', errorMessage);
          }
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  private _deleteRecoverTicketFromConsignBack(marketOfferId: number, buyerTypeId: number): Observable<any> {
    let endpoint = `${this.apiUrl}/customer/market_offer/${marketOfferId}/cancel/?buyer_type_id=${buyerTypeId}`;
    const observable = this.http.delete(endpoint);
    return observable.pipe(
      tap(
        () => {},
        error => {
          let errorMessage = error.message;
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          this.modalService.info('Error', errorMessage);
        }
      )
    );
  }

  // Cancel exchange transaction
  private _deleteExchangeTransaction(transactionId: number): Observable<any> {
    let endpoint = `${this.apiUrl}/exchange_transaction/${transactionId}/`;
    const observable = this.http.delete(endpoint);
    this.dataService.isLoaderActive = true;
    return observable.pipe(
      tap(
        response => {
          this.dataService.isLoaderActive = false;
        },
        error => {
          // if (error.error.code !== 'TCAU01') {
          //   let errorMessage = error.message;
          //   if (error.error.message) {
          //     errorMessage = error.error.message;
          //   }
          //   if (error.status === 500) {
          //     errorMessage = 'We were unable to process your request please try again';
          //   }
          //   this.modalService.info('Error', errorMessage);
          // }
          if (error.status === 500) {
            let errorMessage =
              'We were unable to process your request please try again';
            this.modalService.info('Error', errorMessage);
          }
          this.dataService.isLoaderActive = false;
        }
      )
    );
  }

  /*
   *   PUBLIC METHODS
   */

  // Events List
  public getEventsList(): Observable<Array<EventModel>> {
    return this._getEventsList().pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Event Morfology
  public getEventMorfology(eventId): Observable<any> {
    return this._getEventMorfology(eventId).pipe(
      map((morfology: { [key: number]: Array<string> }) => {
        const reverseMorfology = {};
        Object.entries(morfology).forEach(([key, values]) => {
          values.forEach(value => {
            if (!reverseMorfology.hasOwnProperty(value)) {
              reverseMorfology[value] = [];
            }
            reverseMorfology[value].push(key);
          });
        });
        return [morfology, reverseMorfology];
      })
    );
  }

  // Event Availability
  public getEventAvailability(eventId): Observable<any> {
    return this._getEventAvailability(eventId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public getMembershipEventAvailability(eventId) {
    const endpoint = `${this.apiUrl}/events/${eventId}/availability/`;
    const params = {
      force_scale_summary: true
    };
    return this.http.get(endpoint, {params});
  }

  // Get Customer Associations
  public getCustomerAssociations(complete = false): Observable<any> {
    return this._getCustomerAssociations(complete).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Post Customer Association
  public postCustomerAssociation(email: string): Observable<any> {
    return this._postCustomerAssociation(email).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Post Customer Association
  public deleteCustomerAssociation(associateId: number): Observable<any> {
    return this._deleteCustomerAssociation(associateId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public deleteCustomerAssociationInverse(patronId: number, patronAssId: number): Observable<any> {
    return this._deleteCustomerAssocationInverse(patronId, patronAssId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Post Customer Register
  public postCustomerRegister(email: string): Observable<any> {
    this.dataService.isLoaderActive = true;
    return this._postCustomerRegister(email).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Post Customer Register Finalize
  public postCustomerRegisterFinalize(token: string, password: string): Observable<any> {
    return this._postCustomerRegisterFinalize(token, password).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Post Customer Register Finalize
  public postAddAssociationFinalize(token: string): Observable<any> {
    return this._postAddAssociationFinalize(token).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Post Sale Transaction
  public postSaleTransaction(
    eventId: number,
    priceScale: string,
    customerAssociations: Array<any>,
    fromTransactionId: number = null
  ): Observable<any> {
    return this._postSaleTransaction(eventId, priceScale, customerAssociations, fromTransactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get Sale Transaction
  public getSaleTransaction(transactionId: number): Observable<any> {
    return this._getSaleTransaction(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get Reservation Transaction
  public getReservationTransaction(transactionId: number): Observable<any> {
    return this._getReservationTransaction(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get Sale Transaction Payment Data
  public getSaleTransactionPaymentData(transactionId: number): Observable<any> {
    return this._getSaleTransactionPaymentData(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get Reservation Transaction Payment Data
  public getReservationTransactionPaymentData(transactionId: number): Observable<any> {
    return this._getReservationTransactionPaymentData(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Delete Sale Transaction
  public deleteSaleTransaction(transactionId) {
    return this._deleteSaleTransaction(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Sale Transaction
  public putSaleTransaction(transactionId: number, deliveryMethod: DeliveryMethod) {
    return this._putSaleTransaction(
      transactionId,
      deliveryMethod.delivery_method_id,
      deliveryMethod.delivery_method_type,
      deliveryMethod.delivery_first_name,
      deliveryMethod.delivery_last_name,
      deliveryMethod.delivery_email,
      deliveryMethod.delivery_address1,
      deliveryMethod.delivery_address2,
      deliveryMethod.delivery_city,
      deliveryMethod.delivery_country_code,
      deliveryMethod.delivery_postal_code,
      deliveryMethod.delivery_sub_country_code,
      deliveryMethod.delivery_sub_country_name
    ).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Reservation Transaction
  public putReservationTransaction(transactionId: number, deliveryMethod: DeliveryMethod) {
    return this._putReservationTransaction(
      transactionId,
      deliveryMethod.delivery_method_id,
      deliveryMethod.delivery_method_type,
      deliveryMethod.delivery_first_name,
      deliveryMethod.delivery_last_name,
      deliveryMethod.delivery_email,
      deliveryMethod.delivery_address1,
      deliveryMethod.delivery_address2,
      deliveryMethod.delivery_city,
      deliveryMethod.delivery_country_code,
      deliveryMethod.delivery_postal_code,
      deliveryMethod.delivery_sub_country_code,
      deliveryMethod.delivery_sub_country_name
    ).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get Reservations
  public getReservations(associations: number[]) {
    return this._getReservations(associations).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Create reservation
  public postReservationTransaction(orders) {
    return this._postReservationTransaction(orders).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Delete reservation
  public deleteReservationTransaction(transactionId) {
    return this._deleteReservationTransaction(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public getCustomerInverseAssociations(): Observable<Array<CustomerAssociationInverseModel>> {
    return this._getCustomerInverseAssociations().pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public putCustomerData(data: TDCInfoModel, dataType: AccountManagementDataType): Observable<any> {
    // Change function return
    // Hay que preparar el body de la peticion, la api no necesita toda la informacion.
    let body = {
      birthday: data.birthday,
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      phone: {
        phone_country_code: data.phone.phone_country_code,
        number: data.phone.number,
      },
      email: data.email,
      address: data.address,
    };
    body.address['sub_country_name'] = body.address.address_sub_country_name;
    let endpoint = `${this.apiUrl}/customer/update/`;
    // if we are updating onlye protection is another body and another endpoint
    if (dataType === 'PROTECTION') {
      body = {} as any;
      for (const dpu of data.data_protection) {
        body[dpu.data_protection_unit.code] = {
          email_channel: dpu.email_channel,
          mail_channel: dpu.mail_channel,
          phone_channel: dpu.phone_channel,
          sms_text_channel: dpu.sms_text_channel,
        };
      }
      endpoint = endpoint + 'dpu/';
    }
    return this._putCustomerData(body, endpoint).pipe(map((data: any) => data));
  }

  public getCustomerInventory(): Observable<Array<ExchangeEventModel>> {
    return this._getUserInventory().pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public getCustomerTicketsFromEvent(eventId: number): Observable<EventTicketModel> {
    return this._getTicketsFromEvent(eventId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public postCreateConsignBack(eventId: number, ticket: TicketModel): Observable<any> {
    const body = {
      event: eventId,
      tickets: [ticket.id],
      buyer_type_id: `${ticket.buyer_type.id}`,
      patron: ticket.attending_patron,
    };
    return this._postConsignBackTicket(body).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // TODO type function return
  public deleteConsignBack(marketOfferId: number, buyerTypeId: number): Observable<any> {
    return this._deleteRecoverTicketFromConsignBack(marketOfferId, buyerTypeId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Create membership transaction
  public postMembershipTransaction(
    eventId: number,
    price_scale: string,
    buyer_type: number,
    patronId: number
  ): Observable<any> {
    let body = {
      event: eventId,
      price_scale: parseInt(price_scale, 10),
      buyer_type,
      patron_to_purchase: patronId,
    };
    return this._postMembershipTransaction(body).pipe(map((data: any) => data));
  }

  // Confirm membership transaction
  public putMembershipTransaction(transactionId: number, deliveryMethod: DeliveryMethod): Observable<any> {
    return this._putMembershipTransaction(
      transactionId,
      deliveryMethod.delivery_method_id,
      deliveryMethod.delivery_method_type,
      deliveryMethod.delivery_first_name,
      deliveryMethod.delivery_last_name,
      deliveryMethod.delivery_email,
      deliveryMethod.delivery_address1,
      deliveryMethod.delivery_address2,
      deliveryMethod.delivery_city,
      deliveryMethod.delivery_country_code,
      deliveryMethod.delivery_postal_code,
      deliveryMethod.delivery_sub_country_code,
      deliveryMethod.delivery_sub_country_name
    ).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // retrieve membership transaction
  public getMembershipTransaction(transactionId: number): Observable<any> {
    return this._getMembershipTransaction(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public deleteMembershipTransaction(transactionId: number): Observable<any> {
    return this._deleteMembershipTransaction(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public getMembershipTransactionPaymentData(transactionId: number): Observable<any> {
    return this._getMembershipTransactionPaymentData(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public getEvictStatus(): Observable<{ evict: boolean }> {
    return this._getEvictStatus().pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  //== EXCHANGE

  public getEventsListExchange(): Observable<any> {
    return this._getEventsExchange().pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public getEventAvailabilityExchange(eventId: number, buyerTypeName: string): Observable<any> {
    return this._getEventAvailabilityExchange(eventId, buyerTypeName).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public getEventSectionAvailabilityExchange(
    eventId: number,
    section: string
  ): Observable<{ [key: string]: { [key: string]: AvailabilitySeatmapModel } }> {
    return this._getEventSectionAvailabilityExchange(eventId, section).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public postExchangeTransaction(
    eventId: number,
    friends_family_accounts: Array<any>,
    currentTransaction: number = null
  ): Observable<CreateExchangeTransactionResponseModel> {
    let body = {
      event: eventId,
      friends_family_accounts,
    };
    if (currentTransaction) body['add_to_transaction'] = currentTransaction;
    return this._postExchangeTransaction(body).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public deleteExchangeTransaction(transactionId: number): Observable<any> {
    return this._deleteExchangeTransaction(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public putExchangeTransaction(transactionId: number, deliveryMethod: DeliveryMethod): Observable<any> {
    return this._putExchangeTransaction(
      transactionId,
      deliveryMethod.delivery_method_id,
      deliveryMethod.delivery_method_type,
      deliveryMethod.delivery_first_name,
      deliveryMethod.delivery_last_name,
      deliveryMethod.delivery_email,
      deliveryMethod.delivery_address1,
      deliveryMethod.delivery_address2,
      deliveryMethod.delivery_city,
      deliveryMethod.delivery_country_code,
      deliveryMethod.delivery_postal_code,
      deliveryMethod.delivery_sub_country_code,
      deliveryMethod.delivery_sub_country_name
    ).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public getExchangeTransactionPaymentData(transactionId: number): Observable<any> {
    return this._getExchangeTransactionPaymentData(transactionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  //== TRAIT

  public getPatronTrait(patronId: number): Observable<TraitModel> {
    return this._getPatronTrait().pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public postPatronTrait(data: TraitDataModel): Observable<TraitModel> {
    return this._postPatronTrait(data).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public putPatronTrait(data: TraitDataModel, id: number): Observable<TraitModel> {
    return this._putPatronTrait(data, id).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  newAccount(newAccount: NewAccount): Observable<any> {
    let endpoint = `${this.apiUrl}/customer/register/new-account/`;
    return this.sendRequest(endpoint, newAccount);
  }
}
