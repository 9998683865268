export const environment = {
  apiUrl: 'https://uat-chelseafc.3ddigitalvenue.com/friends-family',
  production: true,
  replication_db: false,
  sandbox: false,
  buyer_types: {
    'chelseafc': {
      family_area_adult_id: 1741,
      buyer_types_list: [
        { age: 65, buyer_type_name: 'SENIOR' },
        { age: 18, buyer_type_name: 'ADULT' },
        { age: 0, buyer_type_name: 'JUNIOR' },
      ],
      buyer_types_rules: {
        JUNIOR: {
          JUNIOR: true,
          ADULT: true,
          SENIOR: false,
        },
        ADULT: {
          JUNIOR: false,
          ADULT: true,
          SENIOR: false,
        },
        SENIOR: {
          JUNIOR: false,
          ADULT: true,
          SENIOR: true,
        },
      },
    },
    'mkdons': [
      { age: 65, buyer_type_name: 'SENIOR' },
      { age: 18, buyer_type_name: 'ADULT' },
      { age: 0, buyer_type_name: 'JUNIOR' },
    ],
    'demo.psp': {
      family_area_adult_id: 1741,
      buyer_types_list: [
        { age: 65, buyer_type_name: 'SENIOR' },
        { age: 18, buyer_type_name: 'ADULT' },
        { age: 0, buyer_type_name: 'JUNIOR' },
      ],
      buyer_types_rules: {
        JUNIOR: {
          JUNIOR: true,
          ADULT: true,
          SENIOR: false,
        },
        ADULT: {
          JUNIOR: false,
          ADULT: true,
          SENIOR: false,
        },
        SENIOR: {
          JUNIOR: false,
          ADULT: true,
          SENIOR: true,
        },
      },
    },
  },
  analytics: {
    chelseafc: 'G-RG5ZP12TPZ',
  },
  exchange: {
    'chelseafc': {
      family_enclosure_buyer_type_id: [1510, 1511, 1512],
      family_enclosure_buyer_type_id_junior: [1502, 1505, 1508],
      family_enclosure_buyer_type_id_senior: [1503, 1506, 1509],
      buyer_types: {
        ADULT: { from: 'all', to: 'all' }, // Everyone can buy ADULT
        JUNIOR: { from: '2003-08-01', to: 'today' }, // Supporters born on or after 1st Aug 2002 (01/08/2003 - today)
        SENIOR: { from: '1900-01-01', to: '1958-07-31' }, // Supporters born on or before 31st July 1957 (31/07/1958)
      },
      hold_codes: {
        '1030': {
          code: 'TEADUL',
          name: 'adult',
          label: 'A',
          color: '#001489',
          buyer_type: 'ADULT', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
        },
        '1033': {
          code: 'TEADUN',
          name: 'adult non-ambulant',
          color: '#00B1FF',
          buyer_type: 'ADULT', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
          label: 'ANA',
        },
        '1036': {
          code: 'TEADUA',
          name: 'adult ambulant',
          color: '#FB5AAA',
          buyer_type: 'ADULT', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
          label: 'AAM',
        },
        '1031': {
          code: 'TEJNR',
          name: 'junior',
          color: '#C13030',
          buyer_type: 'JUNIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
          label: 'J',
        },
        '1034': {
          code: 'TEJNRN',
          name: 'junior non-ambulant',
          color: '#FFA700',
          buyer_type: 'JUNIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
          label: 'JNA',
        },
        '1037': {
          code: 'TEJNRA',
          name: 'junior ambulant',
          color: '#FCCA24',
          buyer_type: 'JUNIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
          label: 'JAM',
        },
        '1032': {
          code: 'TESEN',
          name: 'senior',
          color: '#59A83C',
          buyer_type: 'SENIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
          label: 'S',
        },
        '1035': {
          code: 'TESENN',
          name: 'senior non-ambulant',
          color: '#D5C68A',
          buyer_type: 'SENIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
          label: 'SNA',
        },
        '1038': {
          code: 'TESENA',
          name: 'senior ambulant',
          color: '#8B4EB1',
          buyer_type: 'SENIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
          label: 'SAM',
        },
      },
    },
    'demo.psp': {
      family_enclosure_buyer_type_id: [],
      family_enclosure_buyer_type_id_junior: [],
      family_enclosure_buyer_type_id_senior: [],
      buyer_types: {
        ADULT: { from: 'all', to: 'all' }, // Everyone can buy ADULT
        JUNIOR: { from: '2002-08-01', to: 'today' }, // Supporters born on or after 1st Aug 2002 (01/08/2002 - today)
        SENIOR: { from: '1900-01-01', to: '1957-07-31' }, // Supporters born on or before 31st July 1957 (31/07/1957)
      },
      hold_codes: {
        1108: {
          code: 'MMCAD',
          name: 'adult',
          label: 'A',
          color: '#001489',
          buyer_type: 'ADULT', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
        },
        1109: {
          code: 'MMCJR',
          name: 'junior',
          label: 'J',
          color: '#C13030',
          buyer_type: 'junior', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
        },
        1110: {
          code: 'MMCSR',
          name: 'senior',
          color: '#59A83C',
          buyer_type: 'SENIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
          label: 'S',
        },
      },
    },
  },
  adyen: {
    environment: 'test', // Change to 'live' for the live environment.
    clientKey: 'test_P6NWOWMQZFAWBHPHX3JWUUOWBQCBI3GB',
  },
  gtm: {
    id: 'GTM-NTJ9QP62', // --> GTM for UAT
  },
};
