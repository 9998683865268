import { NgxCookiebotConfig } from '@halloverden/ngx-cookiebot';
import { Injectable } from '@angular/core';

// todo use config and environments for this
@Injectable()
export class CookiebotConfig extends NgxCookiebotConfig {
  blockingMode: 'auto' | 'manual' | string;
  cbId: string;
  cdn: 'com' | 'eu' | string;
  culture?: string;
  framework?: string;
  level?: string;
  loadScript: boolean;
  type?: string;
}
CookiebotConfig.prototype.loadScript = false;
