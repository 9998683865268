<div class="pb-container container">
    <a [routerLink]="['/exchange/select-a-event']" [queryParams]="{ transaction: fromTransaction }">Go back</a>
    <div *ngIf="eventId && dataService.exchangeEventsList && dataService.exchangeEventsList[eventId]" style="margin-bottom: 24px;">
        <div class="content-title"><span>{{dataService.exchangeEventsList[eventId].name}}</span></div>
        <div class="content-subtitle"><span>{{dataService.exchangeEventsList[eventId].venue}}, {{dataService.exchangeEventsList[eventId].date | date: 'dd/MM/yyyy hh:mm a'}}</span></div>
    </div>
    <div>
        <div class="list-element-container rounded-block" (click)="selectFriend(userData.tdc_info.id)" [ngClass]="{'selected': selectedFriends[userData.tdc_info.id]}">
            <div class="list-element bold"><span>{{userData.tdc_info.first_name}} {{ userData.tdc_info.last_name }}</span></div>
            <div class="list-element-button accent-color" *ngIf="selectedFriends[userData.tdc_info.id]">Selected</div>
        </div>
    </div>
    <div *ngIf="customerAssociationsArray.length" class="bold" style="padding: 16px;">
        <span>Purchase tickets on behalf of:</span>
    </div>
    <div *ngIf="customerAssociationsArray.length">
        <div *ngFor="let association of customerAssociationsArray" class="list-element-container rounded-block" [ngClass]="{'selected': selectedFriends[association.associate_id], 'disabled': getIsUserDisabled(association)} " (click)="selectFriend(association.associate_id)">
            <div>
                <div class="list-element bold"><span>{{ association.tdc_info.first_name }} {{ association.tdc_info.last_name }}</span></div>
                <div><span class="font-size-14">Supporter Number: {{ association.associate_id }}</span></div>
                <div *ngIf="association.tdc_info.memberships && association.tdc_info.memberships.length" class="list-element italic membership-level">
                    <span>{{association.tdc_info.memberships[0].membership_level.description}}</span><br>
                    <span>Point balance {{association.tdc_info.memberships[0].loyalty_program?.current_point_balance}}</span>
                </div>
            </div>
            <div *ngIf="selectedFriends[association.associate_id]" class="list-element-button accent-color">Selected</div>
        </div>
    </div>
</div>
<div class="bottom-interface">
    <div style="margin-right: 16px;"><span>{{friendsSelectedCount}} selected</span></div>
    <button [disabled]="activeContinue()" (click)="continue()">Continue</button>
</div>