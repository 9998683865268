import { Component, Input, OnInit } from '@angular/core';
import { EventModel, MembershipsAvailabilityModel, MembershipsAvailabilityPriceModel, User } from '../../models';
import { ConnectionService } from '../../services/connection.service';
import { DataService } from '../../services/data.service';
import { ModalsService } from '../../modals/modals.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { GtmService } from 'src/app/services/gtm.service';

@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss'],
})
export class MembershipsComponent implements OnInit {
  @Input() event: EventModel;
  @Input() fromTransaction: number;
  @Input() listOfCustomers: Array<number>;
  user: User;
  availabilityMemberships: { [key: string]: MembershipsAvailabilityModel };
  selectedMembership: string;
  selectedMembershipBuyerType: MembershipsAvailabilityPriceModel;

  // Lista de buyer types que no deben aparecer si tu cumpleaños no esta en el rango de fechas.
  restrictedPriceScale: { [key: string]: { from: string; until: string } } = {
    TEEN: { from: '2003-08-01', until: '2010-07-31' },
    JUNIOR: { from: '2010-08-01', until: 'today' },
    AB_T: { from: '2003-08-01', until: '2010-07-31' },
    AB_JR: { from: '2010-08-01', until: 'today' },
    NAB_T: { from: '2003-08-01', until: '2010-07-31' },
    NAB_JR: { from: '2010-08-01', until: 'today' },
  };

  restrictedBuyerType: { [key: string]: { from: string; until: string } } = {
    TEENUK: { from: '2003-08-01', until: '2010-07-31' },
    TEEEUR: { from: '2003-08-01', until: '2010-07-31' },
    TEEROW: { from: '2003-08-01', until: '2010-07-31' },
    JUNIUK: { from: '2010-08-01', until: 'today' },
    JUNEUR: { from: '2010-08-01', until: 'today' },
    JUNROW: { from: '2010-08-01', until: 'today' },
    JUNROWI: { from: '2010-08-01', until: 'today' }, // FAKE
    AMTEUK: { from: '2003-08-01', until: '2010-07-31' },
    AMTEEU: { from: '2003-08-01', until: '2010-07-31' },
    AMTERW: { from: '2003-08-01', until: '2010-07-31' },
    AMJUUK: { from: '2010-08-01', until: 'today' },
    AMJUEU: { from: '2010-08-01', until: 'today' },
    AMJURW: { from: '2010-08-01', until: 'today' },
    NATEUK: { from: '2003-08-01', until: '2010-07-31' },
    NATEEU: { from: '2003-08-01', until: '2010-07-31' },
    NATERW: { from: '2003-08-01', until: '2010-07-31' },
    NAJUUK: { from: '2010-08-01', until: 'today' },
    NAJUEU: { from: '2010-08-01', until: 'today' },
    NAJURW: { from: '2010-08-01', until: 'today' },
  };

  constructor(
    private connection: ConnectionService,
    public dataService: DataService,
    private modalService: ModalsService,
    private auth: AuthService,
    private router: Router,
    private gtmService: GtmService
  ) {
    this.selectedMembership = null;
    this.selectedMembershipBuyerType = { name: '', price: 0, code: '', id: 0 };
  }

  ngOnInit(): void {
    this.auth
      .getUserLogged$()
      .pipe(take(1))
      .subscribe(response => {
        if (typeof response === 'boolean') return;
        this.user = response;
      });
    // Get customer associations / friends list
    if (!Object.keys(this.dataService.customerAssociationsFull).length) {
      this.connection.getCustomerAssociations(true).subscribe(
        response => {
          if (response && response.length) {
            response.forEach(association => {
              this.dataService.customerAssociationsFull[association.associate_id] = association;
              this.dataService.customerAssociations[association.associate_id] = association;
            });
          }
        },
        error => {
          console.error(error);
        }
      );
    }
    this.getAvailability();
  }

  selectMembership(membership: string): void {
    this.selectedMembership = membership;
    this.unselectBuyerType();
  }

  selectBuyerType(price: MembershipsAvailabilityPriceModel): void {
    this.selectedMembershipBuyerType = price;
  }

  unselectMembership(): void {
    this.selectedMembership = null;
    this.unselectBuyerType();
  }

  unselectBuyerType(): void {
    this.selectedMembershipBuyerType = { name: '', price: 0, code: '', id: 0 };
  }

  checkout(): void {
    const title = 'Continue?';
    const message =
      'By clicking continue, your selection will be place on hold and you will be redirected to the checkout page.';
    const accept = 'Continue';
    const createMembership = () => {
      this.connection
        .postMembershipTransaction(
          this.event.id,
          this.selectedMembership,
          this.selectedMembershipBuyerType.id,
          this.listOfCustomers[0]
        )
        .subscribe(() => {
          this.auth.getUserLogged(true).then(response => {
            if (response['membership_transactions'] && response['membership_transactions'].length) {
              response ? this.gtmService.addToCartMembership(response.membership_transactions[0]) : '';
              this.router.navigate([
                '/buy-tickets/checkout',
                { transaction: response['membership_transactions'][0].id, flow: 'memberships' },
              ]);
            }
          });
        });
    };
    this.modalService.info(title, message, accept, createMembership);
  }

  validateBuyerType(buyerTypeCode: string): boolean {
    if (this.restrictedBuyerType[buyerTypeCode]) {
      const dateFromTimestamp = new Date(this.restrictedBuyerType[buyerTypeCode].from).getTime();
      const dateUntilTimestamp =
        this.restrictedBuyerType[buyerTypeCode].until === 'today'
          ? new Date().getTime()
          : new Date(this.restrictedBuyerType[buyerTypeCode].until).getTime();
      const birthdayTimestamp = new Date(
        this.dataService.customerAssociations[this.listOfCustomers[0]].tdc_info.birthday
      ).getTime();
      return birthdayTimestamp >= dateFromTimestamp && birthdayTimestamp <= dateUntilTimestamp;
    } else {
      return true;
    }
  }

  validatePriceScale(priceScale: string): boolean {
    if (this.restrictedPriceScale[priceScale]) {
      const dateFromTimestamp = new Date(this.restrictedPriceScale[priceScale].from).getTime();
      const dateUntilTimestamp =
        this.restrictedPriceScale[priceScale].until === 'today'
          ? new Date().getTime()
          : new Date(this.restrictedPriceScale[priceScale].until).getTime();
      const birthdayTimestamp = new Date(
        this.dataService.customerAssociations[this.listOfCustomers[0]].tdc_info.birthday
      ).getTime();
      return birthdayTimestamp >= dateFromTimestamp && birthdayTimestamp <= dateUntilTimestamp;
    } else {
      return true;
    }
  }

  private getAvailability(): any {
    const subs = this.connection
      .getMembershipEventAvailability(this.event.id)
      .subscribe((response: { [key: string]: MembershipsAvailabilityModel }) => {
        subs.unsubscribe();
        this.availabilityMemberships = response;
      });
  }
}
