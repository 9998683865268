<div class="pb-container container">
    <a [routerLink]="['/renew-tickets']">Go back</a>
    <div style="margin-bottom: 24px;">
        <div class="content-title"><span>Renew Tickets</span></div>
        <div class="content-subtitle"><span>Select the tickets you would like to renew:</span></div>
    </div>
    <div>
        <div class="list-element-container rounded-block f-column">
            <div class="flex spb">
                <div class="bold alignItemCenter"><span>{{userData.tdc_info.first_name}} {{ userData.tdc_info.last_name }}<br>{{userData.tdc_info.email.email_address}}</span></div>
                <div *ngIf="customerTickets[userData.tdc_info.id]" class="flex alignItemCenter">
                    <span>{{customerTickets[userData.tdc_info.id].length}} reservations available</span>
                    <button *ngIf="customerTickets[userData.tdc_info.id].length" (click)="customerAssociationsInterfaceStatus[userData.tdc_info.id] = !customerAssociationsInterfaceStatus[userData.tdc_info.id]" class="transparent-button" style="margin-left: 24px;">
                        <div [ngClass]="{'closed': !customerAssociationsInterfaceStatus[userData.tdc_info.id]}" class="flex j-center icon-container">
                            <div class="icon arrow"></div>
                        </div>
                    </button>
                </div>
            </div>
            <div *ngIf="customerAssociationsInterfaceStatus[userData.tdc_info.id]">
                <div *ngFor="let ticket of customerTickets[userData.tdc_info.id]" style="width: 100%;">
                    <div [ngClass]="{'selected': selectedTickets[userData.tdc_info.id][ticket.id]}" class="list-element-container rounded-block f-column">
                        <button *ngIf="!selectedTickets[userData.tdc_info.id][ticket.id]" (click)="selectTicket(userData.tdc_info.id, ticket.id)" class="select-button">Select</button>
                        <button *ngIf="selectedTickets[userData.tdc_info.id][ticket.id]" (click)="selectTicket(userData.tdc_info.id, ticket.id)" class="select-button selected">Unselect</button>

                        <div><span>Order ID: </span><span class="bold">{{ticket.id}}</span></div>
                        <!-- <div><span>Payment Due: </span><span class="bold">{{ticket.payment_due | date: 'dd/MM/yyyy hh:mm'}}</span></div> -->
                        <div style="margin-top: 24px">
                            <div *ngFor="let pendingItem of ticket.pending_items">
                                <div class="list-element-container rounded-block f-column">
                                    <div class="flex spb">
                                        <div><span class="bold">{{pendingItem.name}}</span></div>
                                        <div><span>{{pendingItem.quantity}}</span></div>
                                    </div>
                                    <div *ngIf="pendingItem.seats && pendingItem.seats.length">
                                        <div *ngFor="let seat of pendingItem.seats" class="flex" style="justify-content: space-between;">
                                            <div *ngIf="!pendingItem.venue || ( pendingItem.venue && pendingItem.venue === 'STAMFORD BRIDGE'); else showSeatInfo"><span>Section: {{ seat.section | titlecase }} Row: {{seat.row}}  Seat: {{seat.seat}}</span></div>
                                            <ng-template #showSeatInfo><span>Section: {{ seat.section | titlecase }} </span></ng-template>
                                            <div *ngIf="seat.buyer_type && seat.price"><span>{{seat.buyer_type}}: {{seat.price | currency: dataService.currencyCode }}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="customerTicketsArray.length">
        <div *ngFor="let customer of customerTicketsArray">
            <div *ngIf="customer[0] != userData.tdc_info.id" class="list-element-container rounded-block f-column">
                <div class="flex spb">
                    <div class="bold alignItemCenter">
                        <span>{{customerAssociations[parseInt(customer[0])].tdc_info.first_name}} {{customerAssociations[parseInt(customer[0])].tdc_info.last_name}}
                          <br>{{customerAssociations[parseInt(customer[0])].tdc_info.email.email_address}}</span></div>
                    <div *ngIf="customer[1]" class="flex alignItemCenter">
                        <span>{{customer[1].length}} reservations available</span>
                        <button *ngIf="customer[1].length" (click)="customerAssociationsInterfaceStatus[customer[0]] = !customerAssociationsInterfaceStatus[customer[0]]" class="transparent-button" style="margin-left: 24px;">
                            <div [ngClass]="{'closed': !customerAssociationsInterfaceStatus[customer[0]]}" class="flex j-center icon-container">
                                <div class="icon arrow"></div>
                            </div>
                        </button>
                    </div>
                </div>
                <div *ngIf="customerAssociationsInterfaceStatus[customer[0]]">
                    <div *ngFor="let ticket of customer[1]" style="width: 100%;">
                        <div [ngClass]="{'selected': selectedTickets[customer[0]][ticket.id]}" class="list-element-container rounded-block f-column">
                            <button *ngIf="!selectedTickets[customer[0]][ticket.id]" (click)="selectTicket(customer[0], ticket.id)" class="select-button">Select</button>
                            <button *ngIf="selectedTickets[customer[0]][ticket.id]" (click)="selectTicket(customer[0], ticket.id)" class="select-button selected">Unselect</button>

                            <div><span>Order ID: </span><span class="bold">{{ticket.id}}</span></div>
                            <!-- <div><span>Payment Due: </span><span class="bold">{{ticket.payment_due | date: 'dd/MM/yyyy hh:mm'}}</span></div> -->
                            <div><span>Packages:</span></div>
                            <div>
                                <div *ngFor="let pendingItem of ticket.pending_items">
                                    <div class="list-element-container rounded-block f-column">
                                        <div class="flex spb">
                                            <div><span class="bold">{{pendingItem.name}}</span></div>
                                            <div><span>{{pendingItem.quantity}}</span></div>
                                        </div>
                                        <div *ngIf="pendingItem.seats && pendingItem.seats.length">
                                            <div *ngFor="let seat of pendingItem.seats" class="flex" style="justify-content: space-between;">
                                                <div *ngIf="!pendingItem.venue || ( pendingItem.venue && pendingItem.venue === 'STAMFORD BRIDGE'); else showSeatInfo"><span>Section: {{ seat.section | titlecase }}  Row: {{seat.row}}  Seat: {{seat.seat}}</span></div>
                                                <ng-template #showSeatInfo><span>Section: {{ seat.section | titlecase }}</span></ng-template>
                                                <div *ngIf="seat.buyer_type && seat.price"><span>{{seat.buyer_type}}: {{seat.price | currency: dataService.currencyCode }}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bottom-interface">
    <div style="margin-right: 16px;"><span>{{ticketsCount}}/10 tickets selected</span></div>
    <button (click)="continue()" [disabled]="ticketsCount === 0">Continue</button>
</div>