import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FiservRequestPayload } from './../models/fiserv-request-payload';
import { FiservFormModel } from './../models/fiserv-form.model';

@Injectable({
  providedIn: 'root',
})
export class FiservService {
  constructor(private http: HttpClient) {}

  getFormData(payload: FiservRequestPayload): Observable<FiservFormModel> {
    return this.http.post<FiservFormModel>('api-payment/prepare_payment_form/', payload);
  }
}
