import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { ConnectionService } from "src/app/services/connection.service";
import { DataService } from "src/app/services/data.service";
import { Subscription } from "rxjs";
import { User } from "../../models";

@Component({
  selector: 'app-select-friends',
  templateUrl: './select-friends.component.html',
  styleUrls: ['./select-friends.component.scss'],
})
export class SelectFriendsRenewComponent implements OnInit, OnDestroy {
  friendsList = [];
  subscriptionList: Array<Subscription> = [];
  userData: User;

  selectedFriends = {};
  customerAssociationsArray = [];

  get friendsSelectedCount() {
    return Object.keys(this.selectedFriends).length;
  }

  constructor(
    public dataService: DataService,
    private router: Router,
    private auth: AuthService,
    private connection: ConnectionService
  ) {}

  ngOnInit(): void {
    // Get user data
    const getUserLoggedSubscription = this.auth.getUserLogged$().subscribe(
      (response) => {
        console.log(response);
        this.userData = response as User;
        if (response['reservation_transactions'] && response['reservation_transactions'].length) {
          this.router.navigate([
            '/renew-tickets/checkout',
            { transaction: response['reservation_transactions'][0].id },
          ]);
        }
      },
      (error) => {
        console.error(error);
      }
    );
    this.subscriptionList.push(getUserLoggedSubscription);
    // Get customer associations / friends list
    if (!Object.keys(this.dataService.customerAssociationsFull).length) {
      const getCustomerAssociationsSubscription = this.connection
        .getCustomerAssociations(true)
        .subscribe(
          (response) => {
            console.log(response);
            if (response && response.length) {
              response.forEach((association) => {
                this.dataService.customerAssociationsFull[
                  association.associate_id
                ] = association;
                this.dataService.customerAssociations[
                  association.associate_id
                ] = association;
              });
            }
            this.customerAssociationsArray = Object.values(
              this.dataService.customerAssociationsFull
            );
          },
          (error) => {
            console.error(error);
          }
        );
      this.subscriptionList.push(getCustomerAssociationsSubscription);
    } else {
      this.customerAssociationsArray = Object.values(
        this.dataService.customerAssociationsFull
      );
    }
  }

  selectFriend(associateId) {
    if (this.selectedFriends[associateId]) {
      delete this.selectedFriends[associateId];
    } else {
      this.selectedFriends[associateId] = true;
    }
  }

  continue() {
    let associations = Object.keys(this.selectedFriends);
    associations.push(`${this.userData.tdc_info.id}`);
    this.router.navigate(['/renew-tickets/select-tickets', { associations }]);
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
