import { Component, Input, OnInit } from '@angular/core';
import { EventModel } from '../../../models';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  @Input() event: EventModel;
  @Input() fromTransaction: number;

  constructor(private dataService: DataService) {}

  ngOnInit(): void {}

  eventIsAlreadyPick(eventId: number): boolean {
    if (this.fromTransaction) {
      return !this.dataService.eventsAlreadyPickList.includes(eventId);
    }
    return true;
  }

  shouldNotShowEvent(eventId: number, venue: string): boolean {
    return this.dataService.getVenues().includes(venue.toLowerCase());
  }
}
