<div class="information-container">
  <div class="information-box">
    <h4>Ticket Information</h4>
    <span>To see all our up-to-date Ticket sales information please click the link below</span><br>
    <div class="btn-margin-top"><a *ngIf="isChelseaFC;else demoLink" class="link-info" target="_blank" href="https://www.chelseafc.com/en/tickets---membership/ticket-information">Click here</a></div>
  </div>
  <div class="information-box">
    <h4>Matchday Information</h4>
    <span>To see all our up to date Matchday information and guides please click the link below</span><br>
    <div class="btn-margin-top"><a *ngIf="isChelseaFC;else demoLink" class="link-info" target="_blank" href="https://www.chelseafc.com/en/matchday-information-guide">Click here</a></div>
  </div>
  <div class="information-box">
    <h4>Contact Us</h4>
    <span *ngIf="isChelseaFC;else demoContactUsText">To contact Chelsea please click the link below and to see all our details or to complete a Contact Us form.</span><br>
    <div class="btn-margin-top"><a *ngIf="isChelseaFC;else demoLink" class="link-info" target="_blank" href="https://www.chelseafc.com/en/about-chelsea/contact-us?pageTab=%2520Contact%2520Us:%2520By%2520Email">Click here</a></div>
  </div>
  <div class="information-box">
    <h4>FAQ’s</h4>
    <span>For more information, please click below and to see all of our FAQs.</span><br>
    <div class="btn-margin-top"><a *ngIf="team === 'Chelsea FC';else demoLink" class="link-info" target="_blank" href="https://www.chelseafc.com/en/about-chelsea/faqs/faqs-boxoffice?pageTab=Tickets">Click here</a></div>
  </div>
</div>

<ng-template #demoLink>
  <a class="link-info" target="_blank" href="https://www.tickets.com/tos/">Click here</a>
</ng-template>

<ng-template #demoContactUsText>
  <span>To contact River City FC please email <a href="mailto:rivercityfc@tickets.com">rivercityfc@tickets.com</a> or click the link below and to see all our details.</span>
</ng-template>
