<div class="full-container-wbar">
  <div class="flex wrap-mobile" style="height: 100%">
    <div class="rounded-block tickets-block flex flex-column justify-content-between" style="overflow-y: auto">
      <div>
        <div class="bold"><span>My tickets:</span></div>
        <div
          *ngIf="transactionData && transactionsListByEvent"
          id="transaction-container"
          class="flex flex-column alignItemCenter">
          <div
            id="transaction"
            *ngFor="let item of transactionsListByEvent | keyvalue"
            class="w-95 rounded-block list-element-container flex-column alignItemStart">
            <div class="flex spb w-100">
              <div class="bold alignItemCenter">
                <div class="content-title">
                  <span>{{ item.value.event.name }}</span>
                </div>
                <div class="content-subtitle">
                  <span>{{ item.value.event.venue }}, {{ item.value.event.date | date : 'dd/MM/yyyy hh:mm a' }}</span>
                </div>
              </div>
              <div class="flex alignItemCenter">
                <button
                  class="transparent-button"
                  (click)="interfaceEventStatus[item.value.event.id] = !interfaceEventStatus[item.value.event.id]">
                  <div
                    [ngClass]="{ closed: !interfaceEventStatus[item.value.event.id] }"
                    class="flex j-center icon-container">
                    <div class="icon arrow"></div>
                  </div>
                </button>
              </div>
            </div>
            <ng-container *ngIf="interfaceEventStatus[item.value.event.id]">
              <!-- Collapse by event-->
              <div class="w-100" *ngFor="let customer of item.value.transactions">
                <div
                  class="list-element-container rounded-block f-column"
                  *ngIf="typeOfSale === 'TICKET'; else memberships">
                  <div class="flex flex-row justify-content-between w-100">
                    <div>{{ customer.name }}</div>
                  </div>
                  <div
                    class="w-95 rounded-block list-element-container flex-column alignItemStart"
                    style="margin-top: 16px"
                    *ngFor="let info of customer.buyer_type_info">
                    <div class="flex spb w-100">
                      <div class="bold alignItemCenter">
                        <div class="">
                          <span>{{ info.buyer_type_name }}</span>
                          <span style="margin-left: 16px">Seats: {{ info.seats.length }}</span>
                        </div>
                      </div>
                      <div class="flex alignItemCenter">
                        <button
                          class="transparent-button"
                          (click)="
                            interfaceBuyerTypeByCustomer[customer.name][info.buyer_type_name] =
                              !interfaceBuyerTypeByCustomer[customer.name][info.buyer_type_name]
                          ">
                          <div
                            [ngClass]="{ closed: !interfaceBuyerTypeByCustomer[info.buyer_type_name] }"
                            class="flex j-center icon-container">
                            <div class="icon arrow"></div>
                          </div>
                        </button>
                      </div>
                    </div>
                    <!-- Collapse div buyer types-->
                    <ng-container *ngIf="interfaceBuyerTypeByCustomer[customer.name][info.buyer_type_name]">
                      <div class="w-100" style="margin-top: 16px" *ngFor="let seat of info.seats">
                        <div class="list-element-container rounded-block f-column">
                          <div class="w-100 flex f-row flex-wrap justify-content-between">
                            <div>Section: {{ seat.tdc_section }}</div>
                            <div *ngIf="item.value.event.venue != 'AWAY MATCHES'">Row: {{ seat.tdc_seat_row }}</div>
                            <div *ngIf="item.value.event.venue != 'AWAY MATCHES'">Seat: {{ seat.tdc_seat_number }}</div>
                          </div>
                          <div class="w-100 flex f-row justify-content-start">
                            <!-- DIVIDIR PRECIO TOTAL ENTRE EL SILLAS -->
                            Price: {{ info.total_price / info.seats.length | currency : dataService.currencyCode }}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- NG TEMPLATE MEMEBERSHIP CASE -->
                <ng-template #memberships>
                  <div class="list-element-container rounded-block f-column">
                    <div class="flex flex-row justify-content-between w-100">
                      <div>{{ customer.name }}</div>
                      <div>{{ customer.buyer_type }}</div>
                    </div>
                    <div class="flex flex-row justify-content-between w-100">
                      <div>{{ customer.price_scale }}</div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div
        class="text-center"
        *ngIf="typeOfSale === 'TICKET'"
        [routerLink]="['/buy-tickets', { transaction: transactionId }]">
        <button>Select more events</button>
      </div>
    </div>
    <div class="rounded-block payment-block">
      <div>
        <div *ngIf="eventId && dataService.eventsList && dataService.eventsList[eventId]" class="payment-block-header">
          <div></div>
          <div class="timer">
            <div class="content-subtitle"><span>Your transaction will expire in</span></div>
            <span class="bold">{{ getTimer }}</span>
          </div>
        </div>
        <div>
          <p>
            Your selection is now held, please complete your transaction before the timer runs out.<br />
            Choose your delivery method and click ‘Checkout’. You will then be redirected to an external website to
            process your payment.
            <span class="bold"
              >If your transaction total is £0.00 please enter your card details, you will not be charged for this
              transaction</span
            >
          </p>
        </div>

        <div class="flex wrap-mobile">
          <div class="delivery-methods-container">
            <div class="bold"><span>Choose your delivery method:</span></div>
            <select name="miselect" [(ngModel)]="deliveryMethod">
              <option [value]="i" *ngFor="let deliveryMethod of deliveryMethodsArray; let i = index">
                {{ deliveryMethod.description }}
              </option>
            </select>
            <div *ngIf="deliveryMethodsArray[deliveryMethod].type === 'EXTERNAL_TICKETS_AT_HOME'">
              <div class="bold"><span>Email:</span></div>
              <input [(ngModel)]="deliveryOptionals.email" type="text" disabled />
            </div>
            <div
              *ngIf="
                deliveryMethodsArray[deliveryMethod].type === 'POSTAL' ||
                deliveryMethodsArray[deliveryMethod].type === 'POSTAL_TRACKABLE'
              ">
              <div class="bold"><span>Address:</span></div>
              <input [(ngModel)]="deliveryOptionals.address1" type="text" />
              <div *ngIf="deliveryOptionals.address2" class="bold"><span>Address line 2:</span></div>
              <input *ngIf="deliveryOptionals.address2" [(ngModel)]="deliveryOptionals.address2" type="text" />
              <!-- <input [(ngModel)]="deliveryOptionals.address2" type="text"> -->
              <div class="bold"><span>City:</span></div>
              <input [(ngModel)]="deliveryOptionals.city" type="text" />
              <div class="bold"><span>Country Code:</span></div>
              <input [(ngModel)]="deliveryOptionals.countryCode" type="text" disabled />
              <input
                *ngIf="deliveryOptionals.sub_country_code"
                [(ngModel)]="deliveryOptionals.sub_country_code"
                type="text"
                disabled />
              <input
                *ngIf="deliveryOptionals.sub_country_name"
                [(ngModel)]="deliveryOptionals.sub_country_name"
                type="text"
                disabled />
              <!--                            <select name="country-select" [(ngModel)]="deliveryOptionals.countryCode">-->
              <!--                              <option [value]="'GBR'">Great Britain</option>-->
              <!--                            </select>-->
              <div class="bold"><span>Postal Code:</span></div>
              <input [(ngModel)]="deliveryOptionals.postalCode" type="text" />
            </div>
          </div>

          <div class="summary-container">
            <div class="bold"><span>Summary:</span></div>
            <div class="list-element-container rounded-block" style="margin-top: 8px">
              <div>
                <div>Subtotal:</div>
                <div>Delivery:</div>
                <div *ngIf="serviceCharges">Service charges:</div>
                <div class="bold" style="margin-top: 8px">Total:</div>
              </div>
              <div>
                <div>
                  <span class="bold">{{
                    transactionData.total_price - serviceCharges | currency : dataService.currencyCode
                  }}</span>
                </div>
                <div>
                  <span class="bold">{{
                    deliveryMethodsArray[deliveryMethod].price | currency : dataService.currencyCode
                  }}</span>
                </div>
                <div *ngIf="serviceCharges">
                  <span class="bold">{{ serviceCharges | currency : dataService.currencyCode }}</span>
                </div>
                <div style="margin-top: 8px">
                  <span class="bold">{{
                    transactionData.total_price + deliveryMethodsArray[deliveryMethod].price
                      | currency : dataService.currencyCode
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="continue-button-container">
          <button (click)="cancelTransaction()" class="white-button">Cancel transaction</button>
          <div *ngIf="transactionData" class="total-price-container">
            <button *ngIf="!this.fiservFormData" (click)="checkout()" [disabled]="isCheckoutDisabled()">
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
