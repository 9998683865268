import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewAccountService {
  private email$ = new BehaviorSubject<string>('');

  constructor() {}

  addEmail(email: string) {
    this.email$.next(email);
  }

  getEmail(): Observable<string> {
    return this.email$.asObservable();
  }
}
