import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-renew-tickets',
  templateUrl: './renew-tickets.component.html',
  styleUrls: ['./renew-tickets.component.scss'],
})
export class RenewTicketsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
