<div class="summary-container container" style="margin-top: 5%">
  <div *ngIf="pending">
    <p>Please wait while we process your transaction...</p>
  </div>
  <!-- SUCCESS -->
  <div *ngIf="!pending && status === 'success'" style="width: 100%">
    <div class="bold summary-title"><span>Your transaction was completed successfully!</span></div>
    <div style="margin-bottom: 8px"><span>Here are the details of your transaction:</span></div>
    <!-- SALE -->
    <div
      *ngIf="saleTransaction && (transactionType === 'sale' || transactionType === 'package')"
      class="summary-container">
      <div class="flex j-center">
        <div class="rounded-block flex flex-row w-100 spb">
          <div>
            <span class="bold">Transaction ID: {{ saleTransaction.payment_identifier }}</span>
          </div>
          <div>
            <span class="bold">Delivery method: {{ saleTransaction.delivery_method_description }}</span>
          </div>
          <div>
            <span class="bold"
              >Total Price: {{ saleTransaction.total_price | currency : dataService.currencyCode }}</span
            >
          </div>
        </div>
      </div>
      <div class="rounded-block" style="max-width: 100%">
        <div><span class="bold">Your order contains the following items</span></div>
        <div class="flex flex-column alignItemCenter">
          <div
            id="transaction"
            *ngFor="let item of saleTransactionsListByEvent | keyvalue"
            class="w-95 rounded-block list-element-container flex-column alignItemStart">
            <!-- Event -->
            <div class="flex spb w-100">
              <div class="bold alignItemCenter text-start">
                <div class="content-title">
                  <span>{{ item.value.event.name }}</span>
                </div>
                <div class="content-subtitle">
                  <span>{{ item.value.event.venue }}, {{ item.value.event.date | date : 'dd/MM/yyyy hh:mm a' }}</span>
                </div>
              </div>
              <div class="flex alignItemCenter">
                <button
                  class="transparent-button"
                  (click)="interfaceEventStatus[item.value.event.id] = !interfaceEventStatus[item.value.event.id]">
                  <div
                    [ngClass]="{ closed: !interfaceEventStatus[item.value.event.id] }"
                    class="flex j-center icon-container">
                    <div class="icon arrow"></div>
                  </div>
                </button>
              </div>
            </div>
            <!-- Acordion -->
            <ng-container *ngIf="interfaceEventStatus[item.value.event.id]">
              <div class="w-100 flex" *ngFor="let customer of item.value.transactions">
                <div class="list-element-container rounded-block f-column">
                  <div class="flex flex-row justify-content-between w-100">
                    <div>{{ customer.name }}</div>
                  </div>
                  <div
                    class="w-95 rounded-block list-element-container flex-column text-start"
                    style="margin-top: 16px"
                    *ngFor="let info of customer.buyer_type_info">
                    <div class="flex spb w-100">
                      <!-- HEAD OF COLLAPSE-->
                      <div class="bold alignItemCenter">
                        <div>
                          <span>{{ info.buyer_type_name }}</span>
                        </div>
                        <div>
                          <span>Total Seats: {{ info.seats.length }}</span>
                        </div>
                        <div>Total Price: {{ info.total_price | currency : dataService.currencyCode }}</div>
                      </div>
                      <div class="flex alignItemCenter">
                        <button
                          class="transparent-button"
                          (click)="
                            interfaceBuyerTypeByCustomer[customer.name][info.buyer_type_name] =
                              !interfaceBuyerTypeByCustomer[customer.name][info.buyer_type_name]
                          ">
                          <div
                            [ngClass]="{ closed: !interfaceBuyerTypeByCustomer[info.buyer_type_name] }"
                            class="flex j-center icon-container">
                            <div class="icon arrow"></div>
                          </div>
                        </button>
                      </div>
                    </div>
                    <!-- Collapse div buyer types-->
                    <ng-container *ngIf="interfaceBuyerTypeByCustomer[customer.name][info.buyer_type_name]">
                      <div class="w-100" style="margin-top: 16px" *ngFor="let seat of info.seats">
                        <div class="list-element-container rounded-block f-column w-95 m-auto">
                          <div class="w-100 flex f-row flex-wrap justify-content-between">
                            <div>Section: {{ seat.tdc_section }}</div>
                            <div *ngIf="item.value.event.venue != 'AWAY MATCHES'">Row: {{ seat.tdc_seat_row }}</div>
                            <div *ngIf="item.value.event.venue != 'AWAY MATCHES'">Seat: {{ seat.tdc_seat_number }}</div>
                          </div>
                          <div class="w-100 flex f-row justify-content-start">
                            <!-- DIVIDIR PRECIO TOTAL ENTRE EL SILLAS -->
                            Price: {{ info.total_price / info.seats.length | currency : dataService.currencyCode }}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- RESERVATION -->
    <div *ngIf="reservationTransaction && transactionType === 'reservation'" class="summary-container">
      <div class="flex-row-3 j-center">
        <div class="rounded-block">
          <div>
            <span class="label bold">Transaction ID:</span><span>{{ reservationTransaction.payment_identifier }}</span>
          </div>
          <div>
            <span class="label bold">Total Price:</span
            ><span>{{ reservationTransaction.total_price | currency : dataService.currencyCode }}</span>
          </div>
          <div>
            <span>{{ reservationTransaction.delivery_method_description }}</span>
          </div>
        </div>
      </div>

      <div class="rounded-block" style="max-width: 100%">
        <div><span class="bold">Your order contains the following items</span></div>
        <div class="flex-row-3 j-center">
          <div *ngFor="let customer of reservationTransaction.reservation_transaction_customers" class="rounded-block">
            <div class="label">
              <span>{{ customer.name }}</span>
            </div>
            <div class="flex wrap j-center">
              <div *ngFor="let order of customer.orders_claimed" class="rounded-block">
                <div *ngIf="order.pending_items">
                  <div *ngFor="let item of order.pending_items">
                    <span class="bold">{{ item.name }}</span>
                    <div *ngFor="let seat of item.seats">
                      <span *ngIf="!order.pending_items.venue || order.pending_items.venue; else showSeatInfo"
                        >{{ seat.section }}-{{ seat.row }}-{{ seat.seat }}</span
                      >
                      <ng-template #showSeatInfo
                        ><span>{{ seat.section }}</span></ng-template
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <span class="label bold">Price:</span
              ><span>{{ customer.price | currency : dataService.currencyCode }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Membership -->
    <div *ngIf="membershipTransaction && transactionType === 'membership'" class="summary-container">
      <div class="flex j-center">
        <div class="rounded-block flex flex-row w-100 spb">
          <div>
            <span class="bold">Transaction ID: {{ membershipTransaction.payment_identifier }}</span>
          </div>
          <div>
            <span class="bold">Delivery method: {{ membershipTransaction.delivery_method_description }}</span>
          </div>
          <div>
            <span class="bold"
              >Total Price: {{ membershipTransaction.total_price | currency : dataService.currencyCode }}</span
            >
          </div>
        </div>
      </div>
      <div class="rounded-block" style="max-width: 100%">
        <div><span class="bold">Your order contains the following items</span></div>
        <div class="flex flex-column alignItemCenter">
          <div
            id="transactionM"
            *ngFor="let item of saleTransactionsListByEvent | keyvalue"
            class="w-95 rounded-block list-element-container flex-column alignItemStart">
            <!-- Event -->
            <div class="flex spb w-100">
              <div class="bold alignItemCenter text-start">
                <div class="content-title">
                  <span>{{ item.value.event.name }}</span>
                </div>
                <div class="content-subtitle">
                  <span>{{ item.value.event.venue }}, {{ item.value.event.date | date : 'dd/MM/yyyy hh:mm a' }}</span>
                </div>
              </div>
              <div class="flex alignItemCenter">
                <button
                  class="transparent-button"
                  (click)="interfaceEventStatus[item.value.event.id] = !interfaceEventStatus[item.value.event.id]">
                  <div
                    [ngClass]="{ closed: !interfaceEventStatus[item.value.event.id] }"
                    class="flex j-center icon-container">
                    <div class="icon arrow"></div>
                  </div>
                </button>
              </div>
            </div>
            <!-- Acordion -->
            <ng-container *ngIf="interfaceEventStatus[item.value.event.id]">
              <div class="w-100 flex" *ngFor="let customer of item.value.transactions">
                <div class="list-element-container rounded-block f-column w-100">
                  <div class="flex flex-row justify-content-between w-100">
                    <div>{{ customer.name }}</div>
                    <div>{{ customer.buyer_type }}</div>
                  </div>
                  <div class="flex flex-row justify-content-between w-100">
                    <div>Price Scale: {{ customer.price_scale }}</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- EXCHANGE -->
    <div *ngIf="exchangeTransaction && transactionType === 'exchange'" class="summary-container">
      <div class="flex j-center">
        <div class="rounded-block flex flex-row w-100 spb">
          <div>
            <span class="bold">Transaction ID: {{ exchangeTransaction.payment_identifier }}</span>
          </div>
          <div>
            <span class="bold">Delivery method: {{ exchangeTransaction.delivery_method_description }}</span>
          </div>
          <div>
            <span class="bold"
              >Total Price: {{ exchangeTransaction.total_price | currency : dataService.currencyCode }}</span
            >
          </div>
        </div>
      </div>
      <div class="rounded-block" style="max-width: 100%">
        <div><span class="bold">Your order contains the following items</span></div>
        <div class="flex flex-column alignItemCenter">
          <div
            id="transactionE"
            *ngFor="let item of saleTransactionsListByEvent | keyvalue"
            class="w-95 rounded-block list-element-container flex-column alignItemStart">
            <!-- Event -->
            <div class="flex spb w-100">
              <div class="bold alignItemCenter text-start">
                <div class="content-title">
                  <span>{{ item.value.event.name }}</span>
                </div>
                <div class="content-subtitle">
                  <span>{{ item.value.event.venue }}, {{ item.value.event.date | date : 'dd/MM/yyyy hh:mm a' }}</span>
                </div>
              </div>
              <div class="flex alignItemCenter">
                <button
                  class="transparent-button"
                  (click)="interfaceEventStatus[item.value.event.id] = !interfaceEventStatus[item.value.event.id]">
                  <div
                    [ngClass]="{ closed: !interfaceEventStatus[item.value.event.id] }"
                    class="flex j-center icon-container">
                    <div class="icon arrow"></div>
                  </div>
                </button>
              </div>
            </div>
            <!-- Acordion -->
            <ng-container *ngIf="interfaceEventStatus[item.value.event.id]">
              <div class="w-100 flex" *ngFor="let customer of item.value.transactions">
                <div class="list-element-container rounded-block f-column">
                  <div class="flex flex-row justify-content-between w-100">
                    <div>{{ customer.name }}</div>
                  </div>
                  <div
                    class="w-95 rounded-block list-element-container flex-column text-start"
                    style="margin-top: 16px"
                    *ngFor="let info of customer.buyer_type_info">
                    <div class="flex spb w-100">
                      <!-- HEAD OF COLLAPSE-->
                      <div class="bold alignItemCenter">
                        <div>
                          <span>{{ info.buyer_type_name }}</span>
                        </div>
                        <div>
                          <span>Total Seats: {{ info.seats.length }}</span>
                        </div>
                        <div>Total Price: {{ info.total_price | currency : dataService.currencyCode }}</div>
                      </div>
                      <div class="flex alignItemCenter">
                        <button
                          class="transparent-button"
                          (click)="
                            interfaceBuyerTypeByCustomer[customer.name][info.buyer_type_name] =
                              !interfaceBuyerTypeByCustomer[customer.name][info.buyer_type_name]
                          ">
                          <div
                            [ngClass]="{ closed: !interfaceBuyerTypeByCustomer[info.buyer_type_name] }"
                            class="flex j-center icon-container">
                            <div class="icon arrow"></div>
                          </div>
                        </button>
                      </div>
                    </div>
                    <!-- Collapse div buyer types-->
                    <ng-container *ngIf="interfaceBuyerTypeByCustomer[customer.name][info.buyer_type_name]">
                      <div class="w-100" style="margin-top: 16px" *ngFor="let seat of info.seats">
                        <div class="list-element-container rounded-block f-column w-95 m-auto">
                          <div class="w-100 flex f-row flex-wrap justify-content-between">
                            <div>Section: {{ seat.tdc_section }}</div>
                            <div>Row: {{ seat.tdc_seat_row }}</div>
                            <div>Seat: {{ seat.tdc_seat_number }}</div>
                          </div>
                          <div class="w-100 flex f-row justify-content-start">
                            <!-- DIVIDIR PRECIO TOTAL ENTRE EL SILLAS -->
                            Price: {{ info.total_price / info.seats.length | currency : dataService.currencyCode }}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 24px">
      <button (click)="backHome()">{{ btnBack }}</button>
    </div>
  </div>

  <!-- ERROR -->
  <div *ngIf="!pending && status === 'error'">
    <!-- ERROR SALE -->
    <div *ngIf="saleTransaction && saleTransaction.status === 'PH'">
      <div class="timer">
        <div class="content-subtitle"><span>Your transaction will expire in</span></div>
        <span class="bold" style="margin-left: 8px">{{ getTimer }}</span>
      </div>
      <div class="bold summary-title"><span>There was an error processing your payment.</span></div>
      <div style="margin-bottom: 8px"><span>Please follow the following instructions:</span></div>
      <div>
        <p>
          You can click 'Try again' to be redirected back to the payment platform. Your seats will remain in your basket
          until the timer runs out. Please ensure you enter all your details correctly to complete your transaction
        </p>
        <p>
          By clicking 'Cancel transaction', your order will be cancelled, the selected tickets will be released and you
          will be redirected to the home page.
        </p>
      </div>
      <div style="margin-top: 24px">
        <button (click)="continueToPayment()">Try again</button>
        <button (click)="cancelTransaction()" style="margin-left: 8px">Cancel transaction</button>
      </div>
    </div>
    <div *ngIf="saleTransaction && (saleTransaction.status === 'CA' || saleTransaction.status === 'FA')">
      <div class="bold summary-title"><span>There was an error processing your transction.</span></div>
      <div style="margin-bottom: 8px">
        <p>
          Please click the button below and try again later or contact the club or box office. No charges have been
          applied to your account.
        </p>
      </div>
      <div style="margin-top: 24px">
        <button (click)="backHome()">Go back</button>
      </div>
    </div>
    <!-- ERROR RESERVATION -->
    <div *ngIf="reservationTransaction && reservationTransaction.status === 'PH'">
      <div class="timer">
        <div class="content-subtitle"><span>Your transaction will expire in</span></div>
        <span class="bold" style="margin-left: 8px">{{ getTimer }}</span>
      </div>
      <div class="bold summary-title"><span>There was an error processing your payment.</span></div>
      <div style="margin-bottom: 8px"><span>Please follow the following instructions:</span></div>
      <div>
        <p>
          You can click 'Try again' to be redirected back to the payment platform. Your seats will remain in your basket
          until the timer runs out. Please ensure you enter all your details correctly to complete your transaction
        </p>
        <p>
          By clicking 'Cancel transaction', your order will be cancelled, the selected tickets will be released and you
          will be redirected to the home page.
        </p>
      </div>
      <div style="margin-top: 24px">
        <button (click)="continueToPayment()">Try again</button>
        <button (click)="cancelTransaction()" style="margin-left: 8px">Cancel transaction</button>
      </div>
    </div>
    <div
      *ngIf="
        reservationTransaction && (reservationTransaction.status === 'CA' || reservationTransaction.status === 'FA')
      ">
      <div class="bold summary-title"><span>There was an error processing your transction.</span></div>
      <div style="margin-bottom: 8px">
        <p>
          Please click the button below and try again later or contact the club or box office. No charges have been
          applied to your account.
        </p>
      </div>
      <div style="margin-top: 24px">
        <button (click)="backHome()">Go back</button>
      </div>
    </div>
    <!-- ERROR MEMBERSHIP -->
    <div *ngIf="membershipTransaction && membershipTransaction.status === 'PH'">
      <div class="timer">
        <div class="content-subtitle"><span>Your transaction will expire in</span></div>
        <span class="bold" style="margin-left: 8px">{{ getTimer }}</span>
      </div>
      <div class="bold summary-title"><span>There was an error processing your payment.</span></div>
      <div style="margin-bottom: 8px"><span>Please follow the following instructions:</span></div>
      <div>
        <p>
          You can click 'Try again' to be redirected back to the payment platform. Your seats will remain in your basket
          until the timer runs out. Please ensure you enter all your details correctly to complete your transaction
        </p>
        <p>
          By clicking 'Cancel transaction', your order will be cancelled, the selected tickets will be released and you
          will be redirected to the home page.
        </p>
      </div>
      <div style="margin-top: 24px">
        <button (click)="continueToPayment()">Try again</button>
        <button (click)="cancelTransaction()" style="margin-left: 8px">Cancel transaction</button>
      </div>
    </div>
    <div
      *ngIf="membershipTransaction && (membershipTransaction.status === 'CA' || membershipTransaction.status === 'FA')">
      <div class="bold summary-title"><span>There was an error processing your transction.</span></div>
      <div style="margin-bottom: 8px">
        <p>
          Please click the button below and try again later or contact the club or box office. No charges have been
          applied to your account.
        </p>
      </div>
      <div style="margin-top: 24px">
        <button (click)="backHome()">Go back</button>
      </div>
    </div>
    <!-- ERROR T EXCHANGE -->
    <div *ngIf="exchangeTransaction && exchangeTransaction.status === 'PH'">
      <div class="timer">
        <div class="content-subtitle"><span>Your transaction will expire in</span></div>
        <span class="bold" style="margin-left: 8px">{{ getTimer }}</span>
      </div>
      <div class="bold summary-title"><span>There was an error processing your payment.</span></div>
      <div style="margin-bottom: 8px"><span>Please follow the following instructions:</span></div>
      <div>
        <p>
          You can click 'Try again' to be redirected back to the payment platform. Your seats will remain in your basket
          until the timer runs out. Please ensure you enter all your details correctly to complete your transaction
        </p>
        <p>
          By clicking 'Cancel transaction', your order will be cancelled, the selected tickets will be released and you
          will be redirected to the home page.
        </p>
      </div>
      <div style="margin-top: 24px">
        <button (click)="continueToPayment()">Try again</button>
        <button (click)="cancelTransaction()" style="margin-left: 8px">Cancel transaction</button>
      </div>
    </div>
  </div>
</div>

<iframe
  *ngIf="transactionType == 'sale' || transactionType == 'membership'"
  id="”cfc_tracking”"
  src="https://theblues.chelseafc.com/iframes/tickets-tracking/"
  title="description"
  height="1"
  scrolling="no"
  hidden>
</iframe>
