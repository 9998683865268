<div class="pb-container container">
  <div class="content-title"><span>My Account</span></div>
  <div class="content-subtitle" style="margin-bottom: 24px;">
    <span>Please ensure your address details (including state & postal code) are correct before making your purchase.</span>
  </div>
  <!-- PERSONA DATA -->
  <div class="rounded-block box" *ngIf="tdcInfo">
    <div class="box-header">
      <div class="box-header-title"><span>Personal Data</span></div>
      <div *ngIf="!forms.PERSONAL" style="cursor: pointer;" (click)="editToggle('PERSONAL')"><button>Edit</button></div>
    </div>
    <div class="box-body">
      <form #personalDatForm='ngForm' (ngSubmit)="onSubmit(personalDatForm, 'PERSONAL')" class="">
        <div class="flex flex-row flex-wrap" *ngIf="tdcInfo">
          <!-- FIRST NAME -->
          <div class="flex flex-column input-container">
            <label>First Name</label>
            <input type="text" [(ngModel)]="tdcInfo.first_name" name="first_name" readonly disabled>
          </div>
          <!-- LAST NAME -->
          <div class="flex flex-column input-container">
            <label>Last Name</label>
            <input type="text" [(ngModel)]="tdcInfo.last_name" name="last_name" readonly disabled>
          </div>
          <!-- GENDER -->
          <div class="flex flex-column input-container">
            <label>Gender</label>
            <select name="gender" [(ngModel)]="tdcInfo.gender" [disabled]="!this.forms.PERSONAL">
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
              <option value="NOT_LISTED">Not listed</option>
              <option value="NOT_ANSWER">Prefer not to answer</option>
            </select>
          </div>
        </div>
        <div class="flex flex-row flex-wrap" style="margin-top: 24px" *ngIf="tdcInfo">
          <!-- BIRTH -->
          <div class="flex flex-column input-container">
            <label>Date of birth</label>
            <input type="text" [(ngModel)]="tdcInfo.birthday" name="birthday" readonly disabled>
          </div>
          <!-- PHONE -->
          <div class="flex flex-column">
            <label>Primary phone number</label>
            <div class="flex flex-row">
              <select name="phone_country_code" class="phone-code-selector" #phoneCountryCode='ngModel' [(ngModel)]="tdcInfo.phone.phone_country_code" [disabled]="!this.forms.PERSONAL" required>
                <option *ngFor="let country of dataService.countryList"  [value]="country.icaoCode">{{ country.icaoCode }} +{{ country.phoneCode }}</option>
              </select>
              <input type="text" class="phone-input" #phoneNumber='ngModel' required maxlength="11" minlength="6" [ngClass]="{'invalid-input': validator.hasGeneralErrors(personalDatForm, phoneNumber) || invalidNumberPhone}"
                     [(ngModel)]="tdcInfo.phone.number" name="primary_phone_number" [disabled]="!this.forms.PERSONAL">
            </div>
            <span class="form-error" *ngIf="validator.hasIndividualError(personalDatForm, phoneNumber, 'required') || validator.hasIndividualError(personalDatForm, phoneCountryCode, 'required')">This Field is Required</span>
            <span class="form-error" *ngIf="validator.hasIndividualError(personalDatForm, phoneNumber, 'maxlength')">Phone number must have a maximum of 11 digits.</span>
            <span class="form-error" *ngIf="validator.hasIndividualError(personalDatForm, phoneNumber, 'minlength')">Phone number must have a minimum of 6 digits.</span>
            <span class="form-error" *ngIf="invalidNumberPhone">Invalid number phone.</span>
          </div>
        </div>
        <!-- ADDRESS -->
        <div class="flex flex-row flex-wrap" style="margin-top: 24px" *ngIf="tdcInfo">
          <!-- Address Line 1 -->
          <div class="flex flex-column input-container">
            <label>Address Line 1</label>
            <input type="text" #address1='ngModel' [(ngModel)]="tdcInfo.address.address1" [ngClass]="{'invalid-input': validator.hasGeneralErrors(personalDatForm, address1)}"
                   name="addres_line_1" [disabled]="!this.forms.PERSONAL" required>
            <span class="form-error" *ngIf="validator.hasIndividualError(personalDatForm, address1, 'required')">This Field is Required</span>
          </div>
          <!-- Address Line 2 -->
          <div class="flex flex-column input-container">
            <label>Address Line 2</label>
            <input type="text" [(ngModel)]="tdcInfo.address.address2" name="addres_line_2" [disabled]="!this.forms.PERSONAL">
          </div>
          <!-- Postal Code -->
          <div class="flex flex-column input-container">
            <label>Postal Code</label>
            <input type="text" #postalCode="ngModel" [(ngModel)]="tdcInfo.address.postal_code" [ngClass]="{'invalid-input': validator.hasGeneralErrors(personalDatForm, postalCode)}"
                   name="postal_code" [disabled]="!this.forms.PERSONAL" minlength="3" maxlength="12" required>
            <span class="form-error" *ngIf="validator.hasIndividualError(personalDatForm, postalCode, 'required')">This Field is Required</span>
            <span class="form-error" *ngIf="validator.hasIndividualError(personalDatForm, postalCode, 'maxlength')">Postal Code must have a maximum of 12 characters.</span>
            <span class="form-error" *ngIf="validator.hasIndividualError(personalDatForm, postalCode, 'minlength')">Postal Code must have a minimum of 3 characters.</span>
          </div>
        </div>
        <div class="flex flex-row flex-wrap" style="margin-top: 24px" *ngIf="tdcInfo">
          <!-- COUNTRY -->
          <div class="flex flex-column input-container">
            <label>Country</label>
            <select name="country" [(ngModel)]="tdcInfo.address.address_country_code" (change)="setSubCountryCodeDefault(tdcInfo.address.address_country_code)" [disabled]="!this.forms.PERSONAL">
              <option *ngFor="let country of dataService.countryList" [value]="country.icaoCode"> {{ country.name }}</option>
            </select>
          </div>
          <!-- STATE -->
          <div class="flex flex-column input-container" *ngIf="isSpecialCountry(tdcInfo.address.address_country_code) else specialCountries">
            <label>State/Province</label>
            <!-- DEPENDING ON COUNTRY SHOW SELECTOR OR INPUT -->
            <input type="text" *ngIf="tdcInfo.address.address_sub_country_code else subCountryName"  [(ngModel)]="tdcInfo.address.address_sub_country_code" name="state" [disabled]="!this.forms.PERSONAL">
            <!-- If response has sub country name, show him, instead show sub country code -->
            <ng-template #subCountryName>
              <input type="text"  [(ngModel)]="tdcInfo.address.address_sub_country_name" name="state" [disabled]="!this.forms.PERSONAL">
            </ng-template>
          </div>
          <ng-template #specialCountries>
            <div class="flex flex-column input-container">
              <label>State</label>
              <select name="state" [(ngModel)]="tdcInfo.address.address_sub_country_code" [disabled]="!this.forms.PERSONAL">
                <option *ngFor="let sub of dataService.subCountryDictionary[tdcInfo.address.address_country_code]" [value]="sub.sub_country_code"> {{ sub.sub_country_name }}</option>
              </select>
            </div>
          </ng-template>
          <!-- CITY -->
          <div class="flex flex-column input-container">
            <label>City</label>
            <input type="text" #city='ngModel' [(ngModel)]="tdcInfo.address.city" name="city" [disabled]="!this.forms.PERSONAL"
                   [ngClass]="{'invalid-input': validator.hasGeneralErrors(personalDatForm, city)}" required>
            <span class="form-error" *ngIf="validator.hasIndividualError(personalDatForm, city, 'required')">This Field is Required</span>
          </div>
        </div>
        <!-- BUTTON CONTROLS -->
        <div class="form-footer">
          <button type="submit" [disabled]="!this.forms.PERSONAL">Save</button>
          <button type="button" (click)="saveChanges('PERSONAL', personalDatForm)" [disabled]="!this.forms.PERSONAL">Dismiss</button>
        </div>
      </form>
    </div>
  </div>
  <!-- ACCOUNT -->
  <div class="rounded-block box" style="margin-top: 12px">
    <div class="box-header">
      <div class="box-header-title"><span>Account Settings</span></div>
      <div style="cursor: pointer;" (click)="editToggle('ACCOUNT')"><button>Edit</button></div>
    </div>
    <div class="box-body">
      <form #accountDataForm='ngForm' (ngSubmit)="onSubmit(accountDataForm, 'ACCOUNT')" class="">
        <div class="flex flex-row flex-wrap" *ngIf="tdcInfo">
          <!-- EMAIL ACCOUNT -->
          <div class="flex flex-column">
            <label>Account Email</label>
            <input type="email" #emailAccount='ngModel' [(ngModel)]="tdcInfo.email.email_address" name="email"
                   [ngClass]="{'invalid-input': validator.hasGeneralErrors(accountDataForm, emailAccount)}" [disabled]="!forms.ACCOUNT" required>
            <span class="form-error" *ngIf="validator.hasIndividualError(accountDataForm, emailAccount, 'required')">This Field is Required</span>
          </div>
          <!-- PASSWORD -->
          <div class="flex flex-column justify-content-end" style="width: 350px">
            <button type="button" (click)="resetPassword()">Password Reset</button>
          </div>
          <!-- ACCOUNT CREDIT (not showing)-->
          <div class="flex flex-column" *ngIf="false">
            <label>Account Credit</label>
            <input type="text" *ngIf="tdcInfo.liability_summary else creditzero" name="credit" value="{{ tdcInfo.liability_summary[0].current_balance | currency:dataService.currencyCode }}" readonly disabled>
          </div>
        </div>
        <!-- BUTTON CONTROLS -->
        <div class="form-footer">
          <button type="submit" [disabled]="!this.forms.ACCOUNT">Save</button>
          <button type="button" (click)="saveChanges('ACCOUNT', accountDataForm)" [disabled]="!this.forms.ACCOUNT">Dismiss</button>
        </div>
      </form>
    </div>
  </div>
  <!-- PROTECTION DATA -->
  <div class="rounded-block box" style="margin-top: 12px">
    <div class="box-header">
      <div class="box-header-title"><span>Data Protection</span></div>
      <div style="cursor: pointer;" (click)="editToggle('PROTECTION')"><button>Edit</button></div>
    </div>
    <div class="box-body">
      <form #dpuDataForm='ngForm' (ngSubmit)="onSubmit(dpuDataForm, 'PROTECTION')" class="" *ngIf="tdcInfo">
        <div class="dpu-unit" *ngFor="let dpu_unit of tdcInfo.data_protection">
          <!-- EMAIL ACCOUNT -->
          <div class="flex flex-column">
            <label class="bold">{{dpu_unit.data_protection_unit.code}}</label>
            <label>{{dpu_unit.data_protection_unit.message}}</label>
            <div class="checkbox-list-container flex justify-content-between w-100 flex-wrap">
              <div class="checkbox-container flex">
                <input type="checkbox" [(ngModel)]="dpu_unit.email_channel"
                       [name]="dpu_unit.data_protection_unit.code+'-email_channel'"
                       [id]="dpu_unit.data_protection_unit.code+'-email_channel'"
                       [disabled]="!forms.PROTECTION">
                <label [for]="dpu_unit.data_protection_unit.code+'-email_channel'">EMAIL</label>
              </div>
              <div class="checkbox-container flex">
                <input type="checkbox" [(ngModel)]="dpu_unit.mail_channel"
                       [name]="dpu_unit.data_protection_unit.code+'-mail_channel'"
                       [id]="dpu_unit.data_protection_unit.code+'-mail_channel'"
                       [disabled]="!forms.PROTECTION">
                <label [for]="dpu_unit.data_protection_unit.code+'-mail_channel'">MAIL</label>
              </div>
              <div class="checkbox-container flex">
                <input type="checkbox" [(ngModel)]="dpu_unit.phone_channel"
                       [name]="dpu_unit.data_protection_unit.code+'-phone_channel'"
                       [id]="dpu_unit.data_protection_unit.code+'-phone_channel'"
                       [disabled]="!forms.PROTECTION">
                <label [for]="dpu_unit.data_protection_unit.code+'-phone_channel'">PHONE </label>
              </div>
              <div class="checkbox-container flex">
                <input type="checkbox" [(ngModel)]="dpu_unit.sms_text_channel"
                       [name]="dpu_unit.data_protection_unit.code+'-sms_text_channel'"
                       [id]="dpu_unit.data_protection_unit.code+'-sms_text_channel'"
                       [disabled]="!forms.PROTECTION">
                <label [for]="dpu_unit.data_protection_unit.code+'-sms_text_channel'">SMS-TEXT </label>
              </div>
            </div>
            <label class="mt-2">
              Please read our Privacy Policy (www.chelseafc.com/privacy) to learn more about Chelsea FC and its group companies, how we use your information and how to change your mind about receiving marketing from us.
            </label>
          </div>
        </div>
        <!-- BUTTON CONTROLS -->
        <div class="form-footer">
          <button type="submit" [disabled]="!this.forms.PROTECTION">Save</button>
          <button type="button" (click)="saveChanges('PROTECTION', dpuDataForm)" [disabled]="!this.forms.PROTECTION">Dismiss</button>
        </div>
      </form>
    </div>
  </div>
</div>


<!-- NG TEMPLATE -->
<ng-template #creditzero>
  <input type="text" value="{{ 0 | currency:dataService.currencyCode }}" readonly disabled>
</ng-template>
