import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { User } from '../models';
import { DPUdata, TDCInfoModel } from '../models/user.model';
import { NgForm } from '@angular/forms';
import { DataService } from '../services/data.service';
import { ModalsService } from '../modals/modals.service';
import { ConnectionService } from '../services/connection.service';
import { FormValidatorService } from '../services/form-validator.service';
import { ToastService } from '../services/toast.service';
import { AccountManagementDataType } from './account-management-data.type';
import { AppConfiguration } from '../models/appconfiguration.model';
import { APP_CONFIG } from '../configuration/app.configuration';

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss'],
})
export class AccountManagementComponent implements OnInit {
  user: User;
  editable: boolean;
  tdcInfo: TDCInfoModel;
  tdcInfoPristine: TDCInfoModel;
  validatePhoneNumberRegex = /^\+?[1-9][0-9]{7,14}$/;
  invalidNumberPhone: boolean;
  forms = {
    PERSONAL: false,
    ACCOUNT: false,
    PROTECTION: false,
  };

  constructor(
    private auth: AuthService,
    public dataService: DataService,
    public validator: FormValidatorService,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    private modalService: ModalsService,
    private connection: ConnectionService,
    private toast: ToastService
  ) {
    this.editable = false;
  }

  get team() {
    return this.appConfig.clientName;
  }

  ngOnInit(): void {
    this.dataService.isLoaderActive = true;
    this.auth.getUserLogged(true).then(
      (user: User) => {
        this.tdcInfo = this.validateTDCInfo(JSON.parse(JSON.stringify(user.tdc_info)));
        this.tdcInfoPristine = JSON.parse(JSON.stringify(this.tdcInfo));
        this.user = JSON.parse(JSON.stringify(user));
        this.dataService.isLoaderActive = false;
      },
      error => {
        console.error(error);
      }
    );
  }

  onSubmit(form: NgForm, targetForm: AccountManagementDataType): void {
    if (form.invalid) {
      return;
    }
    // tslint:disable-next-line: no-string-literal
    if (!this.validatePhoneNumberRegex.exec(form.form.value['primary_phone_number'])) {
      this.invalidNumberPhone = true;
    }
    this.invalidNumberPhone = false;
    this.connection.putCustomerData(this.tdcInfo, targetForm).subscribe(
      () => {
        form.form.markAsPristine();
        this.toast.showSuccess('Changes has been saved');
        this.editToggle(targetForm);
      },
      error => console.log(error)
    );
  }

  editToggle(form: AccountManagementDataType) {
    this.forms[form] = !this.forms[form];
  }

  resetPassword(): void {
    const emailTarget = this.user.email;
    // Mostramos dialogo para hacer doble check de enviar el email
    const title = 'Continue?';
    const message = `We will send an email to ${emailTarget} with instructions how to reset your password.`;
    this.modalService.info(title, message, 'Continue', () => {
      this.sendPasswordResetEmail(emailTarget);
    });
  }

  private sendPasswordResetEmail(email: string): void {
    this.auth.postResetPassword(email).subscribe(
      () => {
        this.modalService.info('Password Reset', 'Your request has been submitted.');
      },
      error => console.log(error)
    );
  }

  dismiss(form: AccountManagementDataType): void {
    this.editToggle(form);
    this.invalidNumberPhone = false;
    this.tdcInfo = JSON.parse(JSON.stringify(this.tdcInfoPristine));
  }

  saveChanges(target: AccountManagementDataType, form: NgForm): void {
    const title = 'Continue?';
    const message = `Unsaved data will be lost, do you want to discard them ?`;
    if (form.pristine) {
      this.dismiss(target);
    } else {
      this.modalService.info(title, message, 'Accept', () => {
        this.dismiss(target);
      });
    }
  }

  // Si faltan valores lo inicializa
  private validateTDCInfo(data: TDCInfoModel): TDCInfoModel {
    // Validate if phone exist
    if (!data.phone) {
      data.phone = { phone_country_code: 'GBR', number: '00000000000' };
    }
    // Validate if address exist
    if (!data.address) {
      data.address = {
        address1: '',
        address_country_code: 'GBR',
        address_sub_country_code: '',
        city: '',
        postal_code: '0000',
      };
    }

    if (!data.data_protection) {
      data.data_protection = [];
      const dpu: DPUdata = {
        data_protection_unit: {
          code: `CHELSEA_FC`,
          message: `Please let us know if you would like to be contacted by ${this.team} and its group companies with news and promotional
          information about ${this.team}, its official sponsors and partners (www.${this.team}.com/partners) and
          their products and services where we have the relevant contact details:`,
          id: 1001,
        },
        id: null,
        email_channel: false,
        mail_channel: false,
        phone_channel: false,
        sms_text_channel: false,
      };
      data.data_protection.push(dpu);
    }

    return data;
  }

  // Vamos a validar si es un pais 'especial' utilizando el json de sub_countrys
  isSpecialCountry(countryCode: string): boolean {
    return !this.dataService.subCountryDictionary[countryCode];
  }

  setSubCountryCodeDefault(countryCode: string): void {
    this.tdcInfo.address.address_sub_country_code = this.dataService.subCountryDictionary[countryCode]
      ? this.dataService.subCountryDictionary[countryCode][0].sub_country_code
      : '';
  }
}
