<div id="header-container" class="flex">
    <div><span class="title">{{appConfig.clientName}}</span><span class="subtitle"> | {{appConfig.applicationName}}</span></div>
    <div class="userdata-container">
        <div *ngIf="userData"><span>Welcome, </span><span class="bold">{{userData.tdc_info.first_name}} </span><span class="bold">{{userData.tdc_info.last_name}}</span></div>
        <div *ngIf="userData"><span>Supporter Number: </span><span class="bold">{{userData.tdc_info.id}} </span></div>
        <!-- <div *ngIf="userData.tdc_info.memberships[0]"><span>{{userData.tdc_info.memberships[0].description}}</span></div> -->
        <div *ngIf="userData.tdc_info.memberships[0] && userData.tdc_info.memberships[0]?.loyalty_program"><span>Loyalty Point Balance: </span><span class="bold">{{userData.tdc_info.memberships[0].loyalty_program.current_point_balance}}</span></div>
        <div *ngIf="userData.tdc_info.memberships[0]"><span style="font-style: italic;">{{userData.tdc_info.memberships[0].membership_level.description}}</span></div>
    </div>
</div>
