import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-adyen-payment-modal.ts',
  templateUrl: './adyen-payment-modal.component.html',
  styleUrls: ['./adyen-payment-modal.component.scss'],
})
export class AdyenPaymentModalComponent {
  @Input() sessionId: string;
  @Input() sessionData: string;
  @Input() returnUrl: string;

  constructor(public bsModalRef: BsModalRef) {
  }

  close() {
    this.bsModalRef.hide();
  }
}
