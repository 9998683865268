import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root',
})
export class CheckEventResolverService implements Resolve<'TICKETS' | 'MEMBERSHIPS'> {
  constructor(private dataService: DataService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const eventId = parseInt(route.params.event, 10);
    if (this.dataService.eventsList[eventId]) {
      // this.dataService.eventsList[eventId].inventoried = true
      return this.dataService.eventsList[eventId].inventoried ? 'TICKETS' : 'MEMBERSHIPS';
    } else {
      this.router.navigate(['buy-tickets']);
      return undefined;
    }
  }
}
