import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { ModalsService } from "src/app/modals/modals.service";
import { AuthService } from "src/app/services/auth.service";
import { ConnectionService } from "src/app/services/connection.service";
import { DataService } from "src/app/services/data.service";
import { User } from "../../models";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-select-tickets',
  templateUrl: './select-tickets.component.html',
  styleUrls: ['./select-tickets.component.scss'],
})
export class SelectTicketsComponent implements OnInit, OnDestroy {
  userData: User;
  associations = [];
  subscriptionList: Array<Subscription> = [];
  customerTickets = {};
  customerTicketsArray = [];
  customerAssociations = {};

  customerAssociationsInterfaceStatus = {};

  selectedTickets = {};

  ticketsCount = 0;

  parseInt(str) {
    return parseInt(str);
  }

  constructor(
    public dataService: DataService,
    private router: Router,
    private modalService: ModalsService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private connection: ConnectionService
  ) {}

  ngOnInit(): void {
    // Get user data
    const getUserLogged$Subscription = this.auth.getUserLogged$().subscribe(
      (response) => {
        console.log(response);
        this.userData = response as User;
      },
      (error) => {
        console.error(error);
      }
    );
    this.subscriptionList.push(getUserLogged$Subscription);
    const checkSelectedAssociations = () => {
      // Check selected associations
      console.log(this.activatedRoute.snapshot.params);
      if (this.activatedRoute.snapshot.params.associations) {
        this.associations =
          this.activatedRoute.snapshot.params.associations.split(",");
      }
      this.connection.getReservations(this.associations).subscribe(response => {
        this.customerTickets = response;
        this.customerTicketsArray = Object.entries(response);
        this.customerTicketsArray.forEach(element => {
          this.customerAssociationsInterfaceStatus[element[0]] = true;
          this.selectedTickets[element[0]] = {};
        });
        console.log('Customer tickets: ', this.customerTickets);
        console.log(this.dataService.customerAssociationsFull);
      });
    };

    // Get customer associations / friends list
    if (!Object.keys(this.dataService.customerAssociationsFull).length) {
      const getCustomerAssociationsSubscription = this.connection
        .getCustomerAssociations(true)
        .subscribe(
          (response) => {
            console.log(response);
            if (response && response.length) {
              response.forEach((association) => {
                this.dataService.customerAssociationsFull[
                  association.associate_id
                ] = association;
                this.dataService.customerAssociations[
                  association.associate_id
                ] = association;
              });
              this.customerAssociations =
                this.dataService.customerAssociationsFull;
            }
            checkSelectedAssociations();
          },
          (error) => {
            console.error(error);
          }
        );
      this.subscriptionList.push(getCustomerAssociationsSubscription);
    } else {
      this.customerAssociations = this.dataService.customerAssociationsFull;
      checkSelectedAssociations();
    }
  }

  selectTicket(customerId, ticketId) {
    if (this.selectedTickets[customerId][ticketId]) {
      delete this.selectedTickets[customerId][ticketId];
      this.ticketsCount--;
    } else {
      if (this.ticketsCount < 10) {
        this.selectedTickets[customerId][ticketId] = true;
        this.ticketsCount++;
      } else {
        this.modalService.info(
          "Limit reached",
          "A maximum of 10 renewals per transaction is allowed."
        );
      }
    }
    console.log(this.selectedTickets);
  }

  continue() {
    const orders = {};
    console.log(this.selectedTickets);
    Object.entries(this.selectedTickets).forEach(customer => {
      if (Object.keys(customer[1]).length) {
        orders[customer[0]] = Object.keys(customer[1]);
      }
    });
    const title = 'Continue?';
    const message =
      'By clicking continue, your seats will be placed on hold and you will be redirected to the checkout page.';
    const accept = 'Continue';
    this.modalService.info(title, message, accept, () => {
      this.connection.postReservationTransaction(orders).subscribe(
        response => {
          console.log(response);
          this.auth.getUserLogged(true).then(response => {
            console.log(response);
            if (response['reservation_transactions'] && response['reservation_transactions'].length) {
              this.router.navigate([
                '/renew-tickets/checkout',
                { transaction: response['reservation_transactions'][0].id },
              ]);
            }
          });
        },
        error => {
          console.log(error);
        }
      );
    });
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
