import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DVMService } from '../../../services/dvm.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  @Input() visibleSection: string;
  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private dvmService: DVMService) {}

  ngOnInit(): void {}

  backToTopview(): void {
    this.dvmService.changeMap('blockmap2d_custom').then(() => {
      this.dvmService.minimapViewer.unselectAll();
      document.getElementById('minimap').classList.remove('visible');
      let scaling = this.dvmService.minimapViewer.min_scaling_factor;
      this.dvmService.minimapViewer.goTo([0, 0], scaling);
      this.back.emit(true);
    });
  }
}
