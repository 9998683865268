import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { APP_CONFIG } from 'src/app/configuration/app.configuration';
import { AppConfiguration } from 'src/app/models/appconfiguration.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  userData;
  private subscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) public appConfig: AppConfiguration,
    public dataService: DataService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.subscription = this.auth.getUserLogged$().subscribe(
      response => {
        this.userData = response;
      },
      error => {
        console.error(error);
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
