import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdyenDropInComponent } from './adyen-drop-in/adyen-drop-in.component';



@NgModule({
  declarations: [AdyenDropInComponent],
  exports: [AdyenDropInComponent],
  imports: [
    CommonModule
  ]
})
export class AdyenModule { }
