<div class="login-container">
  <div class="flex login-header">
    <div class="main-logo-container">
      <img src="{{ appConfig.logoUrl }}" alt="{{ appConfig.clientName }}" />
    </div>
    <div style="margin-left: 24px">
      <span class="title" [ngClass]="{ dark: appConfig.darkTheme }">{{ appConfig.clientName }}</span
      ><span class="subtitle"> | {{ appConfig.applicationName }}</span>
    </div>
  </div>
  <div *ngIf="!requestSent" class="login-form-container">
    <form [formGroup]="newAccountForm" (ngSubmit)="saveUser()" autocomplete="off">
      <div class="row">
        <h4 class="titleNewAccount">New Account</h4>
        <h6 class="subtitleNewAccount">Personal Data</h6>
      </div>

      <!-- PERSONAL DATA -->
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="firstName">First Name</label>
          <input
            [ngClass]="{
              ' form-control is-invalid':
                (firstName?.hasError('required') || firstName?.hasError('maxlength')) && (firstName?.dirty || submitted)
            }"
            formControlName="firstName"
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            autocomplete="off" />
          <span class="error" *ngIf="firstName?.hasError('required') && (firstName?.dirty || submitted)"
            >Field is Required</span
          >
          <span class="error" *ngIf="firstName?.hasError('maxlength') && firstName?.dirty"
            >FirstName can be max 30 characters long.</span
          >
        </div>
        <div class="col-md-4 mb-3">
          <label for="lastName">Last Name</label>
          <input
            [ngClass]="{
              'form-control is-invalid':
                (lastName?.hasError('required') || lastName?.hasError('maxlength')) && (lastName?.dirty || submitted)
            }"
            type="text"
            formControlName="lastName"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            autocomplete="off" />
          <span class="error" *ngIf="lastName?.hasError('required') && (lastName?.dirty || submitted)"
            >Field is Required</span
          >
          <span class="error" *ngIf="lastName?.hasError('maxlength') && lastName?.dirty"
            >LastName can be max 30 characters long.</span
          >
        </div>
        <div class="col-md-4 mb-3">
          <label for="gender">Gender</label>
          <select
            [ngClass]="{ 'form-control is-invalid': gender?.hasError('required') && (gender?.touched || submitted) }"
            name="gender"
            formControlName="gender">
            <option value="null" disabled>Select Gender</option>
            <option value="MALE">Male</option>
            <option value="FEMALE">Female</option>
            <option value="NOT_LISTED">Not listed</option>
            <option value="NOT_ANSWER">Prefer not to answer</option>
          </select>
          <span class="error" *ngIf="gender?.hasError('required') && (gender?.touched || submitted)"
            >Field is Required</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="birthday">Date of Birth</label>
          <input
            [ngClass]="{ 'form-control is-invalid': birthday?.hasError('required') && (birthday?.dirty || submitted) }"
            type="date"
            formControlName="birthday"
            id="birthday"
            name="birthday"
            placeholder="05/05/1990" />
          <span class="error" *ngIf="birthday?.hasError('required') && (birthday?.dirty || submitted)"
            >Field is Required</span
          >
        </div>
        <div formGroupName="phone" class="col-md-4 mb-3">
          <label for="phone">Primary Phone Number</label>
          <div class="row">
            <div class="col-md-4">
              <select name="phone_country_code" formControlName="phone_country_code" class="phone-code-selector">
                <option *ngFor="let country of dataService.countryList" [value]="country.icaoCode">
                  {{ country.icaoCode }} +{{ country.phoneCode }}
                </option>
              </select>
              <span class="error" *ngIf="phone_country_code?.hasError('required') && phone_country_code?.touched"
                >Field is Required</span
              >
            </div>
            <div class="col-md-8">
              <input
                [ngClass]="{
                  'form-control is-invalid':
                    (number?.hasError('required') || number?.hasError('maxlength') || number?.hasError('pattern')) &&
                    (number?.dirty || submitted)
                }"
                type="text"
                formControlName="number"
                id="number"
                name="number"
                placeholder="666777888"
                autocomplete="off" />
              <span class="error" *ngIf="number?.hasError('required') && (number?.dirty || submitted)"
                >Field is Required</span
              >

              <span class="error" *ngIf="number?.hasError('pattern') && number?.dirty">
                Telephone must have <strong>only numbers. </strong>
              </span>
              <span class="error" *ngIf="number?.hasError('maxlength') && number?.dirty"
                >Telephone can be max 25 characters long.</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3"></div>
      </div>
      <div formGroupName="address">
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="addressLine1">Address Line 1</label>
            <input
              [ngClass]="{
                'form-control is-invalid':
                  (addressLine1?.hasError('required') || addressLine1?.hasError('maxlength')) &&
                  (addressLine1?.dirty || submitted)
              }"
              type="text"
              formControlName="address1"
              id="addressLine1"
              name="addressLine1"
              placeholder="Address Line 1"
              autocomplete="off" />
            <span class="error" *ngIf="addressLine1?.hasError('required') && (addressLine1?.dirty || submitted)"
              >Field is Required</span
            >

            <span class="error" *ngIf="addressLine1?.hasError('maxlength') && addressLine1?.dirty"
              >Address1 can be max 50 characters long.</span
            >
          </div>
          <div class="col-md-4 mb-3">
            <label for="addressLine2">Address Line 2</label>
            <input
              [ngClass]="{ 'form-control is-invalid': addressLine2?.hasError('maxlength') && addressLine2?.dirty }"
              type="text"
              formControlName="address2"
              id="addressLine2"
              name="addressLine2"
              placeholder="Address Line 2"
              autocomplete="off" />
            <span class="error" *ngIf="addressLine2?.hasError('maxlength') && addressLine1?.dirty"
              >Address2 can be max 50 characters long.</span
            >
          </div>
          <div class="col-md-4 mb-3">
            <label for="postalCode">Postal Code</label>
            <input
              [ngClass]="{
                'form-control is-invalid':
                  (postalCode?.hasError('required') || postalCode?.hasError('maxlength')) &&
                  (postalCode?.dirty || submitted)
              }"
              type="text"
              formControlName="postalCode"
              id="postalCode"
              name="postalCode"
              placeholder="Postal Code"
              autocomplete="off" />
            <span class="error" *ngIf="postalCode?.hasError('required') && (postalCode?.dirty || submitted)"
              >Field is Required</span
            >
            <span class="error" *ngIf="postalCode?.hasError('maxlength') && postalCode?.dirty"
              >Postal Code can be max 20 characters long.</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="country">Country</label>
            <select formControlName="address_country_code" name="country" (change)="setSubCountryCodeDefault($event)">
              <option *ngFor="let country of dataService.countryList" [value]="country.icaoCode">
                {{ country.name }}
              </option>
            </select>
          </div>
          <div *ngIf="listStatesSpecial.length > 0; else notSpecial" class="col-md-4 mb-3">
            <label for="country">State</label>
            <select formControlName="address_sub_country_code" name="country">
              <option *ngFor="let sub of listStatesSpecial" [value]="sub.sub_country_code">
                {{ sub.sub_country_name }}
              </option>
            </select>
          </div>
          <ng-template #notSpecial>
            <div class="col-md-4 mb-3">
              <label for="country">County / State / Province</label>
              <input
                [ngClass]="{
                  'form-control is-invalid':
                    address_sub_country_name?.hasError('maxlength') && address_sub_country_name?.dirty
                }"
                formControlName="address_sub_country_name"
                type="text"
                id="country"
                name="country"
                placeholder="County / State / Province"
                autocomplete="off" />

              <span
                class="error"
                *ngIf="address_sub_country_name?.hasError('maxlength') && address_sub_country_name?.dirty"
                >State/Province can be max 20 characters long.</span
              >
            </div>
          </ng-template>
          <div class="col-md-4 mb-3">
            <label for="cityy">City</label>
            <input
              [ngClass]="{
                'form-control is-invalid':
                  (city?.hasError('required') || city?.hasError('maxlength')) && (city?.dirty || submitted)
              }"
              formControlName="city"
              type="text"
              id="cityy"
              name="cityy"
              placeholder="City"
              autocomplete="nope" />
            <span class="error" *ngIf="city?.hasError('required') && (city?.dirty || submitted)"
              >Field is Required</span
            >
            <span class="error" *ngIf="city?.hasError('maxlength') && city?.dirty"
              >City can be max 15 characters long.</span
            >
          </div>
        </div>
      </div>

      <!-- ACCOUNT SETTINGS -->
      <div class="row">
        <h6 class="subtitleNewAccount">Account Settings</h6>
      </div>
      <div class="row">
        <div formGroupName="email" class="col-md-4 mb-3">
          <label for="email">Account Email</label>
          <input
            formControlName="email_address"
            style="color: white"
            type="text"
            id="email"
            name="email"
            value="gpryce@tickets.com" />
        </div>
        <div formGroupName="password" class="col-md-4 mb-3">
          <label for="password">Password</label>
          <input
            [ngClass]="{
              'form-control is-invalid':
                (password?.hasError('required') || password?.hasError('minlength')) && (password?.touched || submitted)
            }"
            formControlName="password"
            type="password"
            id="password"
            name="password"
            placeholder="******"
            autocomplete="new-password" />
          <span class="error" *ngIf="password?.hasError('required') && (password?.dirty || submitted)"
            >Field is Required</span
          >
          <span class="error" *ngIf="password?.hasError('minlength') && password?.touched"
            >Password should be at least 6 characters long</span
          >
        </div>
        <div formGroupName="password" class="col-md-4 mb-3">
          <label for="repeatPassword">Re-type Password</label>
          <input
            [ngClass]="{
              'form-control is-invalid':
                (repeatPassword?.hasError('required') ||
                  repeatPassword?.hasError('minlength') ||
                  repeatPassword?.hasError('passwordMismatch')) &&
                (repeatPassword?.touched || submitted)
            }"
            formControlName="repeatPassword"
            type="password"
            id="repeatPassword"
            autocomplete="off"
            name="repeatPassword"
            placeholder="******" />
          <span class="error" *ngIf="repeatPassword?.hasError('required') && (repeatPassword?.dirty || submitted)"
            >Field is Required</span
          >
          <span class="error" *ngIf="repeatPassword?.hasError('minlength') && repeatPassword?.touched"
            >Password should be at least 6 characters long</span
          >
          <span class="error" *ngIf="repeatPassword?.hasError('passwordMismatch') && repeatPassword?.touched"
            >Password and repeat password must be equals</span
          >
        </div>
      </div>

      <!-- DATA PROTECTION -->
      <div class="row">
        <h6 class="subtitleNewAccount">Data Protection</h6>
        <h6 class="subtitleNewAccount">CHELSEA FC</h6>
        <p>
          Please let us know if you would like to be contacted by Chelsea FC and its group companies with news and
          promotional information about Chelsea FC, its official sponsors and partners (www.chelseafc.com/partners) and
          their products and services where we have the relevant contact details:
        </p>
      </div>
      <div formGroupName="dataProtection" class="row">
        <div class="col-md-12">
          <div class="checkbox-list-container flex justify-content-between w-100 flex-wrap">
            <div class="checkbox-container flex">
              <input formControlName="email_channel" id="email_channel" name="email_channel" type="checkbox" />
              <label for="email_channel">EMAIL</label>
            </div>
            <div class="checkbox-container flex">
              <input formControlName="mail_channel" type="checkbox" />
              <label>MAIL</label>
            </div>
            <div class="checkbox-container flex">
              <input formControlName="phone_channel" type="checkbox" />
              <label>PHONE</label>
            </div>
            <div class="checkbox-container flex">
              <input formControlName="sms_text_channel" type="checkbox" />
              <label>SMS-TEXT</label>
            </div>
          </div>
        </div>
      </div>
      <p class="mt-2">
        Please read our Privacy Policy (www.chelseafc.com/privacy) to learn more about Chelsea FC and its group companies, how we use your information and how to change your mind about receiving marketing from us.
      </p>
      <div class="row">
        <div class="col-md-3">
          <button type="submit" class="submit">Submit</button>
        </div>
        <div class="col-md-2 backLogin">
          <a [routerLink]="'/login'">Back to login</a>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="requestSent">
    <div class="login-msj-success">
      <p>You have registered successfully. Check your email to activate the account</p>   
    </div>
    <div style="text-align: center; margin-top: 24px;">
      <a [routerLink]="'/login'">Back to login</a>
    </div>
  </div>
 
</div>

<div class="login-bg" [ngStyle]="{ 'background-image': backgroundUrl }"></div>
<div class="overlay" [ngClass]="{ dark: appConfig.darkTheme }"></div>
