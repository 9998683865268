import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConnectionService } from '../../../services/connection.service';
import { TraitDataModel, TraitModel } from '../../../models/';
import { NgForm } from '@angular/forms';
import { ToastService } from '../../../services/toast.service';
import { FormValidatorService } from '../../../services/form-validator.service';
import { ModalsService } from '../../../modals/modals.service';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss'],
})
export class BankAccountComponent implements OnInit {
  @Input() conn: ConnectionService;
  @Input() patronId: number;
  @Output() isVerifided = new EventEmitter<boolean>(false);
  traitData: TraitDataModel = null;
  traitDataPristine: TraitDataModel = null;
  trait: TraitModel = null;
  editable = false;
  pageText = {
    bankDataText: ``,
    noBankDataText: ``,
    modalText: ``,
  };

  constructor(
    private toast: ToastService,
    public validator: FormValidatorService,
    private modalService: ModalsService,
    private data: DataService
  ) {}

  ngOnInit(): void {
    this.preparePageTexts();
    this.conn.getPatronTrait(this.patronId).subscribe((response: TraitModel) => {
      if (response.hasOwnProperty('traitData')) {
        this.traitData = response.traitData;
        this.traitDataPristine = JSON.parse(JSON.stringify(this.traitData));
        this.trait = response;
        this.isVerifided.emit(response.traitData.booleanData);
      }
    });
  }

  onSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    // tslint:disable-next-line: variable-name
    const _traitData = {
      memoData: form.value.memoData,
      integerData: parseInt(form.value.integerData, 10),
      booleanData: false,
      stringData: `${parseInt(form.value.stringData, 10)}`,
    };
    // modal time
    const title = 'Continue?';
    const message = this.pageText.modalText;
    this.modalService.info(
      title,
      message,
      'Accept',
      () => {
        this.saveBankDetails(_traitData);
      },
      null,
      null,
      'modal-lg'
    );
  }

  private saveBankDetails(data: TraitDataModel): void {
    const traitObservable = !this.trait
      ? this.conn.postPatronTrait(data)
      : this.conn.putPatronTrait(data, this.trait.id);
    traitObservable.subscribe(response => {
      this.editable = false;
      this.toast.showSuccess('Account Bank Updated Correctly');
      this.trait = response;
      this.traitData = response.traitData;
      this.traitDataPristine = JSON.parse(JSON.stringify(this.traitData));
      this.isVerifided.emit(response.traitData.booleanData);
    });
  }

  newBankAccount(): void {
    this.traitData = {
      booleanData: false,
      integerData: null,
      memoData: '',
      stringData: '',
    };
    this.editable = true;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  cancelEdit(): void {
    this.editable = false;
    this.traitData = JSON.parse(JSON.stringify(this.traitDataPristine));
  }

  // Diferentes textos si para chelsea o para demos
  preparePageTexts(): void {
    this.pageText.noBankDataText = `You have not submitted your nominated bank account details.<br>
    Please click below and add in details for a UK bank account.
    Once submitted you will need to provide the Chelsea FC Ticket office team with proof of your bank account before any payment can be made.
    To verify, please can you send a copy of either a bank statement or a voided cheque confirming the details you have put on your account, along with your season ticket number to
    <a href="mailto:tickets@chelseafc.com">tickets@chelseafc.com</a><br>
    For Non-UK supporters (or those without a UK bank account) please contact the ticket office team.`;
    this.pageText.bankDataText = `Supporters with overseas bank accounts need to email <a href="mailto:tickets@chelseafc.com">tickets@chelseafc.com</a> with a copy of their bank statement providing bank name, SWIFT BIC and IBAN details for us to verify. <br>
    If Supporters with UK bank accounts details are showing as Not Verified, please can you send a copy of either a bank statement or a voided cheque confirming the details you have put on your account, along with your season ticket number to <a href="mailto:tickets@chelseafc.com">tickets@chelseafc.com</a>`;
    this.pageText.modalText = `You are submitting your bank details which will be listed as Not Verified.  Please send a copy of either a bank statement or a voided cheque confirming the details you have put on your account,
    'along with your season ticket number to <a href="mailto:tickets@chelseafc.com">tickets@chelseafc.com</a> so it can be verified`;
    if (!this.data.isChelseaFC()) {
      this.pageText.noBankDataText = `
        You have not submitted your nominated bank account details.<br>
        Please click below and add in details for a UK bank account.
        Once submitted you will need to provide the River City FC Ticket office team with proof of your bank account before any payment can be made.
        To verify, please can you send a copy of either a bank statement or a voided cheque confirming the details you have put on your account,
        along with your season ticket number to <a href="mailto:rivercityfc@tickets.com">rivercityfc@tickets.com<a>`;
      this.pageText.bankDataText = `Supporters with overseas bank accounts need to email <a href="mailto:rivercityfc@tickets.com">rivercityfc@tickets.com</a> with a copy of their bank statement providing bank name, SWIFT BIC and IBAN details for us to verify. <br>
      If Supporters with UK bank accounts details are showing as Not Verified, please can you send a copy of either a bank statement or a voided cheque confirming the details you have put on your account, along with your season ticket number to <a href="mailto:rivercityfc@tickets.com">rivercityfc@tickets.com</a>`;
      this.pageText.modalText = `You are submitting your bank details which will be listed as <span class="font-red">Not Verified</span>.
       Please send a copy of either a bank statement or a voided cheque confirming the details you have put on your account, along with your season ticket number to rivercityfc@tickets.com so it can be verified.`;
    }
  }
}
