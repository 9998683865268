<div class="pb-container container">
    <div class="content-title" style="margin-bottom: 24px;"><span>Event Schedule</span></div>
    <div class="flex f-row flex-wrap">
        <div class="badge badge-pill" *ngFor="let venue of venuesAvailables | keyvalue" [class.badge-active]="venue.value.active" (click)="onSelectVenue(venue.key)">
            {{ venue.value.name }}
        </div>
    </div>
    <div class="list-header-container desktop">
        <div class="list-element bold"><span>Event</span></div>
        <div class="list-element bold"><span>Venue</span></div>
        <div class="list-element bold"><span>Date & Time</span></div>
        <div class="list-element-button"><span></span></div>
    </div>
    <div>
        <ng-container *ngFor="let event of filteredEventsList">
            <div class="list-element-container-wrap rounded-block" *ngIf="eventIsAlreadyPick(event.id)">
                <div class="mobile">
                    <div class="list-element bold"><span>{{event.name}}</span></div>
                    <div class="list-element"><span>{{event.venue}}</span></div>
                </div>
                <div class="list-element-container">
                    <div class="list-element bold desktop"><span>{{event.name}}</span></div>
                    <div class="list-element desktop"><span>{{event.venue}}</span></div>
                    <div class="list-element"><span>{{event.date | date: 'dd/MM/yyyy hh:mm a'}}</span></div>
                    <div *ngIf="event.single_ticket_sale_allowed; else soldOut" [routerLink]="['/buy-tickets/select-friends', {event: event.id, venue: event.venue, transaction: fromTransaction}]" class="list-element-button"><button>Select</button></div>
                    <ng-template #soldOut>
                        <div class="list-element-button"><button [disabled]="true">Unavailable</button></div>
                    </ng-template>
                </div>
                <div class="information-element-container">
                    <div style="text-align: center; margin-right: 24px; padding-left: 7px" class="bold"><span>INFORMATION:&nbsp;</span></div>
                    <div *ngIf="event.description" [innerHTML]="event.description"></div>
                </div>
            </div>
        </ng-container>
    </div>
</div>