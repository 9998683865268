import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from 'src/app/configuration/app.configuration';
import { AppConfiguration } from 'src/app/models/appconfiguration.model';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  backgroundUrl: string = '';
  code: string | null = null;
  errorMsj: string = '';

  constructor(@Inject(APP_CONFIG) public appConfig: AppConfiguration, private route: ActivatedRoute) {
    this.backgroundUrl = `url(${this.appConfig.backgroundImageUrl})`;

    this.route.queryParams.subscribe(params => {
      if (params && params['code']) {
        this.code = params['code'];
      }
    });
  }

  ngOnInit(): void {
    this.mapError();
  }

  mapError() {
    if (this.code) {
      switch (this.code) {
        case 'LINK_EXPIRED':
          this.errorMsj = `The link you have followed has expired or is no longer valid. <br/> <br/>
            If you have previously completed your registration, please go back to the login page, and sign in with your reistered details.  You will also have the option to request a password reset. <br/> <br/>
            If you did not previously complete your registration in time, please go back to the login page and click on Register / Create an account to restart the process.`;
          break;

        default:
          this.errorMsj = 'Error unknown';
          break;
      }
    }
  }
}
