import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-add-association-modal',
  templateUrl: './add-association-modal.component.html',
  styleUrls: ['./add-association-modal.component.scss'],
})
export class AddAssociationModalComponent implements OnInit {
  title = 'Add association';
  closeBtnName = 'Close';
  closeFunction: any;
  acceptBtnName = 'Send friend request';
  acceptFunction: any;

  email: string;
  sent = false;

  constructor(public bsModalRef: BsModalRef, private connection: ConnectionService) {}

  ngOnInit(): void {}

  accept() {
    if (this.email) {
      this.connection.postCustomerAssociation(this.email).subscribe(
        response => {
          console.log(response);
          this.sent = true;
        },
        error => console.error(error)
      );
    }
  }

  close() {
    this.bsModalRef.hide();
  }
}
