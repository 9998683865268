import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalData } from '../models/modal-data.model';
import { AddAssociationModalComponent } from './add-association-modal/add-association-modal.component';
import { ModalsComponent } from './modals.component';
import { PaymentRedirectModalComponent } from './payment-redirect-modal/payment-redirect-modal.component';
import { AdyenPaymentModalComponent } from './adyen-payment-modal.ts/adyen-payment-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  bsModalRef: BsModalRef;

  constructor(public modalService: BsModalService) {}

  private prepareObject(object) {
    for (const i in object) {
      if (object[i] === undefined) {
        delete object[i];
      }
    }
  }

  info(
    title,
    message,
    acceptBtnName?,
    acceptFunction?,
    closeBtnName?,
    closeFunction?,
    modalParentClass?,
    ignoreBackdropClick?
  ) {
    const initialState: ModalData = {
      title,
      content: message,
      closeBtnName,
      closeFunction,
      acceptBtnName,
      acceptFunction,
    };
    modalParentClass = modalParentClass ? modalParentClass : '';
    modalParentClass += '';
    modalParentClass += ' modal-dialog-centered';
    this.prepareObject(initialState);
    if (ignoreBackdropClick) {
      this.bsModalRef = this.modalService.show(ModalsComponent, {
        class: modalParentClass,
        initialState,
        ignoreBackdropClick: true,
      });
    } else {
      this.bsModalRef = this.modalService.show(ModalsComponent, { class: modalParentClass, initialState });
    }
  }

  addAssociationModal() {
    const modalParentClass = 'modal-dialog-centered';
    this.bsModalRef = this.modalService.show(AddAssociationModalComponent, { class: modalParentClass });
  }

  paymentRedirectModal(fiservFormData, user) {
    const initialState = {
      fiservFormData,
      userData: user,
    };
    const modalParentClass = 'modal-dialog-centered';
    this.bsModalRef = this.modalService.show(PaymentRedirectModalComponent, {
      class: modalParentClass,
      initialState,
      ignoreBackdropClick: true,
    });
  }

  adyenPaymentModal(sessionId, sessionData, returnUrl) {
    const initialState = {
      sessionId,
      sessionData,
      returnUrl
    };
    const modalParentClass = 'modal-dialog-centered';
    this.bsModalRef = this.modalService.show(AdyenPaymentModalComponent, {
      class: modalParentClass,
      initialState,
      ignoreBackdropClick: true,
    });
  }
}
