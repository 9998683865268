<div class="full-container-wbar">
  <a
    [routerLink]="['/exchange/select-a-friends']"
    [queryParams]="{ event: eventId, venue: this.dataService?.lastVenue }"
    style="margin-left: 16px"
    >Go back</a
  >
  <div class="flex wrap-mobile" style="height: 100%">
    <div class="rounded-block map-block">
      <div
        *ngIf="eventId && dataService.exchangeEventsList && dataService.exchangeEventsList[eventId]"
        class="rounded-block-header-container f-column alignItemBase">
        <div class="content-title">
          <span>{{ dataService.exchangeEventsList[eventId].venue }}</span>
        </div>
        <div class="content-subtitle">
          <span
            >Before selecting a seat, please select a valid supporter for the ticket (i.e. Adult for Adult Tickets,
            Junior for Junior or Adult Tickets and Senior for Senior or Adult Tickets). Adult tickets cannot be
            purchased in the East Lower stand without a Junior or Senior ticket. On your mobile device to access the
            supporters you have selected please click on the tickets button. Loyalty points are NOT awarded for tickets
            purchased on the ticket exchange.</span
          >
          <ul>
            <li>
              Adult tickets cannot be purchased in the East Lower stand without a Junior or Senior ticket.
            </li>
            <li>
              Ambulant and Non-Ambulant tickets may only be purchased by supporters with a matching Ambulant / Non-Ambulant membership type.
            </li>
          </ul>
        </div>
      </div>
      <div class="flex wrap-mobile pricescales-container">
        <app-top-bar *ngIf="showTopbar" (back)="hideTopBar($event)" [visibleSection]="visibleSection"></app-top-bar>
        <div id="viewer-container"></div>
        <div class="map-interface-buttons-container">
          <button id="map-interface-button-plus" class="rounded-block map-interface-button">+</button>
          <button id="map-interface-button-minus" class="rounded-block map-interface-button">-</button>
        </div>
        <div id="minimap" (click)="backToTopview()">
          <div id="viewer-container-minimap"></div>
        </div>
        <app-map-legend *ngIf="showLegendMap" [client]="configurationService.client"></app-map-legend>
      </div>
    </div>
    <div class="rounded-block sub-interface-block desktop">
      <div class="sub-interface-container">
        <div>
          <!-- EVENT -->
          <div
            *ngIf="eventId && dataService.exchangeEventsList && dataService.exchangeEventsList[eventId]"
            class="rounded-block-header-container">
            <div style="display: flex; justify-content: start">
              <div
                *ngIf="dataService.exchangeEventsList[eventId].description"
                class="popover-placeholder"
                [popper]="tooltipcontent"
                [popperTrigger]="'hover'"
                [popperPlacement]="'bottom'"
                [popperApplyClass]="'popperStyle'">
                <button class="icon-container">
                  <div class="icon plus">i</div>
                </button>
              </div>
              <span class="content-title event-title">{{ dataService.exchangeEventsList[eventId].name }}</span>
            </div>
            <popper-content #tooltipcontent>
              <p>
                {{ dataService.exchangeEventsList[eventId].description }}
              </p>
            </popper-content>
            <div>
              <div class="content-subtitle">
                <span>{{ dataService.exchangeEventsList[eventId].date | date : 'dd/MM/yyyy hh:mm a' }}</span>
              </div>
            </div>
          </div>
          <!-- EVENT END -->
          <!-- TICKETS -->
          <div *ngIf="dataService.customerAssociations" style="margin-top: 16px">
            <div
              *ngFor="let customerId of peopleIdList"
              class="rounded-block list-element-container pointer ticket-card"
              (click)="selectPurchaser(customerId)"
              [ngClass]="{
                selected: selectedCustomer === customerId,
                disabled: !this.patronFailLock[customerId] && existFailedLock
              }">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  width: 100%;
                  align-items: baseline;
                ">
                {{ dataService.customerAssociations[customerId].tdc_info.first_name }}
                {{ dataService.customerAssociations[customerId].tdc_info.last_name }}
                <span>{{
                  showCustomerBuyerType(dataService.customerAssociations[customerId].tdc_info.birthday) | titlecase
                }}</span>
              </div>
              <ng-container
                *ngFor="let holdCode of this.virtualCart.basket[customerId] | keyvalue"
                style="margin: 0 auto 0 0">
                <div *ngFor="let seat of holdCode.value.seats_assigned" class="w-100 text-start">
                  <span>{{ simplifySeatId(seat) }}</span>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- END TICKETS -->
        </div>
        <div class="continue-button-container">
          <div class="rounded-block total-price-container">
            <span>Subtotal: </span>
            <span class="bold">{{ virtualCart.total_price | currency : dataService.currencyCode }}</span>
          </div>
          <button [disabled]="!validateCart()" (click)="createTransaction()">Continue</button>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="bottom-interface">
        <div class="continue-button-container">
          <div *ngIf="displayMobileBasket" class="display-mobile-basket">
            <div
              *ngIf="eventId && dataService.exchangeEventsList && dataService.exchangeEventsList[eventId]"
              class="rounded-block-header-container flex-column">
              <div class="content-title flex alignItemCenter w-100">
                <span>{{ dataService.exchangeEventsList[eventId].name }}</span>
                <button
                  *ngIf="dataService.exchangeEventsList[eventId].description"
                  class="extras-modal-selection-button icon-container icon-i-mobile selected"
                  style="margin-left: 15px"
                  tooltip="{{ dataService.exchangeEventsList[eventId].description }}">
                  <div class="icon plus">i</div>
                </button>
              </div>
              <div class="flex w-100 justify-content-start">
                <div class="content-subtitle">
                  <span>{{ dataService.exchangeEventsList[eventId].date | date : 'dd/MM/yyyy hh:mm a' }}</span>
                </div>
                <div class="content-subtitle" style="margin: 0 15px"><span>-</span></div>
                <div class="content-subtitle">
                  <span>{{ dataService.exchangeEventsList[eventId].venue }}</span>
                </div>
              </div>
            </div>
            <!-- customer and associations info  -->
            <div *ngIf="dataService.customerAssociations" style="margin-top: 16px">
              <!-- customer and associations -->
              <div
                *ngFor="let humanId of peopleIdList"
                class="rounded-block list-element-container"
                (click)="selectPurchaser(humanId)"
                [ngClass]="{ selected: selectedCustomer === humanId }">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    align-items: baseline;
                  ">
                  {{ dataService.customerAssociations[humanId].tdc_info.first_name }}
                  {{ dataService.customerAssociations[humanId].tdc_info.last_name }}
                  <ng-container
                    *ngFor="let holdCode of this.virtualCart.basket[humanId] | keyvalue"
                    style="margin: 0 auto 0 0">
                    <div *ngFor="let seat of holdCode.value.seats_assigned" class="w-100 text-start">
                      <span>{{ simplifySeatId(seat) }}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="displayMobileBasket" class="rounded-block total-price-container">
            <span>Subtotal: </span>
            <span class="bold">{{ virtualCart.total_price | currency : dataService.currencyCode }}</span>
          </div>
          <div class="flex">
            <button
              [ngClass]="{ selected: displayMobileBasket }"
              (click)="toggleMobileBasket()"
              style="margin-right: 8px">
              Tickets
            </button>
            <button (click)="createTransaction()" [disabled]="!validateCart()">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- tootl tip test -->
<ng-template #tooltiptest>
  <button
    class="extras-modal-selection-button icon-container icon-i-mobile selected"
    style="margin-left: 15px"
    tooltip="Tooltip for test">
    <div class="icon plus">i</div>
  </button>
</ng-template>
