<!-- SIDE MENU -->
<div class="desktop">
  <div id="side-menu-hidden"></div>
  <div
    id="side-menu"
    class="flex f-column spb alignItemStart"
    [ngClass]="{ open: isMenuOpen }"
    (mouseenter)="toggleMenu(true)"
    (mouseleave)="toggleMenu(false)">
    <div style="width: 100%">
      <div routerLink="/" class="main-logo-container">
        <img src="{{ appConfig.logoUrl }}" alt="{{ appConfig.clientName }}" />
      </div>
      <a routerLink="/buy-tickets" routerLinkActive="active" class="flex center menu-link">
        <div class="flex j-center icon-container">
          <div class="icon icon-letter icon-letter-white">B</div>
        </div>
        <div *ngIf="isMenuOpen" class="flex j-start">
          <div class="menu-text">Buy Tickets</div>
        </div>
      </a>
      <a routerLink="/buy-packages" routerLinkActive="active" class="flex center menu-link">
        <div class="flex j-center icon-container">
          <div class="icon icon-letter icon-letter-white">P</div>
        </div>
        <div *ngIf="isMenuOpen" class="flex j-start">
          <div class="menu-text">Buy Packages</div>
        </div>
      </a>
      <a routerLink="/renew-tickets" routerLinkActive="active" class="flex center menu-link">
        <div class="flex j-center icon-container">
          <div class="icon icon-letter-white icon-letter">R</div>
        </div>
        <div *ngIf="isMenuOpen" class="flex j-start">
          <div class="menu-text">RESERVED TICKETS</div>
        </div>
      </a>
      <a routerLink="/exchange" routerLinkActive="active" class="flex center menu-link">
        <div class="flex j-center icon-container">
          <div class="icon icon-letter-white icon-letter">T</div>
        </div>
        <div *ngIf="isMenuOpen" class="flex j-start">
          <div class="menu-text">TICKET EXCHANGE</div>
        </div>
      </a>
      <a routerLink="/friends-family" routerLinkActive="active" class="flex center menu-link">
        <div class="flex j-center icon-container">
          <div class="icon icon-letter icon-letter-white">F</div>
        </div>
        <div *ngIf="isMenuOpen" class="flex j-start">
          <div class="menu-text">Friends & Family</div>
        </div>
      </a>
      <a routerLink="/my-account" routerLinkActive="active" class="flex center menu-link">
        <div class="flex j-center icon-container">
          <div class="icon icon-letter icon-letter-white">M</div>
        </div>
        <div *ngIf="isMenuOpen" class="flex j-start">
          <div class="menu-text">My Account</div>
        </div>
      </a>
    </div>
    <div style="width: 100%">
      <a (click)="openCart()" class="flex center menu-link" *ngIf="cartWaiting">
        <div class="flex j-center icon-container">
          <div class="icon i-cart-desktop">
            <img src="./assets/cart.svg" class="cart" alt="cart-icon" height="38px" width="38px" />
          </div>
        </div>
        <div class="flex j-start">
          <div class="menu-text">Open Cart Waiting</div>
        </div>
      </a>
      <a routerLink="/information" routerLinkActive="active" class="flex center menu-link">
        <div class="flex j-center icon-container">
          <div class="icon information icon-white"></div>
        </div>
        <div *ngIf="isMenuOpen" class="flex j-start">
          <div class="menu-text">Information</div>
        </div>
      </a>
      <a (click)="logout()" class="flex center menu-link">
        <div class="flex j-center icon-container">
          <div class="icon power icon-white"></div>
        </div>
        <div *ngIf="isMenuOpen" class="flex j-start">
          <div class="menu-text">Log Out</div>
        </div>
      </a>
    </div>
  </div>
</div>

<div class="mobile">
  <div id="bottom-menu">
    <div style="width: 100%; height: 100%; padding: 0 16px" class="flex f-row center spb">
      <div routerLink="/" style="width: 50px">
        <img src="{{ appConfig.logoUrl }}" alt="{{ appConfig.clientName }}" />
      </div>
      <a (click)="openCart()" class="flex center menu-link i-mobile" *ngIf="cartWaiting">
        <div class="flex j-center icon-container">
          <div class="icon i-cart">
            <img src="./assets/cart.svg" alt="cart-icon" />
          </div>
        </div>
      </a>

      <app-button-menu></app-button-menu>
    </div>
  </div>
  <div id="overlay-menu">
    <div class="overlay-container">
      <!-- LOGO -->
      <!--      <div class="main-logo-container">-->
      <!--        <img src={{appConfig.logoUrl}} alt={{appConfig.clientName}}>-->
      <!--      </div>-->
      <!-- LINKS -->
      <div class="container-links">
        <a routerLink="/buy-tickets" class="flex center menu-link">
          <div class="flex j-center icon-container bg-icon-container" routerLinkActive="active">
            <div class="icon icon-letter icon-letter-white">B</div>
          </div>
          <div class="flex j-start">
            <div class="menu-text">Buy Tickets</div>
          </div>
        </a>
        <a routerLink="/buy-packages" class="flex center menu-link">
          <div class="flex j-center icon-container bg-icon-container" routerLinkActive="active">
            <div class="icon icon-letter icon-letter-white">P</div>
          </div>
          <div class="flex j-start">
            <div class="menu-text">Buy Packages</div>
          </div>
        </a>
        <a routerLink="/renew-tickets" class="flex center menu-link">
          <div class="flex j-center icon-container bg-icon-container" routerLinkActive="active">
            <div class="icon icon-letter-white icon-letter">R</div>
          </div>
          <div class="flex j-start">
            <div class="menu-text">RESERVED TICKETS</div>
          </div>
        </a>
        <a routerLink="/exchange" class="flex center menu-link">
          <div class="flex j-center icon-container bg-icon-container" routerLinkActive="active">
            <div class="icon icon-letter-white icon-letter">T</div>
          </div>
          <div class="flex j-start">
            <div class="menu-text">TICKET EXCHANGE</div>
          </div>
        </a>
        <a routerLink="/friends-family" class="flex center menu-link">
          <div routerLinkActive="active" class="flex j-center icon-container bg-icon-container">
            <div class="icon icon-letter icon-letter-white">F</div>
          </div>
          <div class="flex j-start">
            <div class="menu-text">Friends & Family</div>
          </div>
        </a>
        <a routerLink="/my-account" class="flex center menu-link">
          <div class="flex j-center icon-container bg-icon-container" routerLinkActive="active">
            <div class="icon icon-letter icon-letter-white">M</div>
          </div>
          <div class="flex j-start">
            <div class="menu-text">My Account</div>
          </div>
        </a>
        <div class="line"></div>
        <a routerLink="/information" class="flex center menu-link">
          <div class="flex j-center icon-container" routerLinkActive="active">
            <div class="icon information icon-white"></div>
          </div>
          <div class="flex j-start">
            <div class="menu-text">Information</div>
          </div>
        </a>
        <a (click)="logout()" class="flex center menu-link">
          <div class="flex j-center icon-container">
            <div class="icon power icon-white"></div>
          </div>
          <div class="flex j-start">
            <div class="menu-text">Log Out</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

<!--<div class="mobile">-->
<!--    <div id="bottom-menu">-->
<!--        <div style="width: 100%; height: 100%;" class="flex f-row center spb">-->
<!--            <a routerLink="/buy-tickets" routerLinkActive="active" class="flex center j-center menu-link">-->
<!--              <div class="flex j-center icon-container">-->
<!--                <div class="icon icon-letter icon-letter-white">B</div>-->
<!--              </div>-->
<!--            </a>-->
<!--            <a routerLink="/renew-tickets" routerLinkActive="active" class="flex center j-center menu-link">-->
<!--              <div class="flex j-center icon-container">-->
<!--                <div class="icon icon-letter-white icon-letter">R</div>-->
<!--              </div>-->
<!--            </a>-->
<!--            <a routerLink="/friends-family" routerLinkActive="active" class="flex center j-center menu-link">-->
<!--              <div class="flex j-center icon-container">-->
<!--                <div class="icon icon-letter-white icon-letter">F</div>-->
<!--              </div>-->
<!--            </a>-->
<!--            <a routerLink="/my-account" routerLinkActive="active" class="flex center j-center menu-link">-->
<!--                <div class="flex j-center icon-container">-->
<!--                    <div class="icon icon-letter-white icon-letter">M</div>-->
<!--                </div>-->
<!--            </a>-->
<!--            <a routerLink="/information" routerLinkActive="active" class="flex center j-center menu-link">-->
<!--                <div class="flex j-center icon-container">-->
<!--                    <div class="icon information icon-white"></div>-->
<!--                </div>-->
<!--            </a>-->
<!--            <a (click)="logout()" class="flex center j-center menu-link">-->
<!--                <div class="flex j-center icon-container">-->
<!--                    <div class="icon power icon-white"></div>-->
<!--                </div>-->
<!--            </a>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
