<div style="margin-bottom: 24px;">
  <div class="content-title"><span>My Bank Details</span></div>
</div>
<div class="rounded-block box" style="width: 60%">
  <form #traitDataForm='ngForm' (ngSubmit)="onSubmit(traitDataForm)" *ngIf="traitData else noBankAccount">
    <div class="flex f-column">
      <div class="flex f-row justify-content-between margin-between-rows wrap">
        <div class="flex f-column w-45">
          <label>UK Bank Account number</label>
          <input type="text" [maxLength]="8" #integerData='ngModel' (keypress)="numberOnly($event)" required [(ngModel)]="traitData.integerData" name="integerData" [disabled]="!editable" [ngClass]="{'invalid-input': validator.hasGeneralErrors(traitDataForm, integerData)}">
          <span class="form-error" *ngIf="validator.hasIndividualError(traitDataForm, integerData, 'required')">This Field is Required</span>
          <span class="form-error" *ngIf="validator.hasIndividualError(traitDataForm, integerData, 'maxlength')">Max length is 8 numbers</span>
        </div>
        <div class="flex f-column w-45">
          <label>UK Bank Sort Code</label>
          <input type="text" required #stringData="ngModel" (keypress)="numberOnly($event)" [(ngModel)]="traitData.stringData" [maxLength]="6" name="stringData" [disabled]="!editable" [ngClass]="{'invalid-input': validator.hasGeneralErrors(traitDataForm, stringData)}">
          <span class="form-error" *ngIf="validator.hasIndividualError(traitDataForm, stringData, 'required')">This Field is Required</span>
        </div>
      </div>
      <div class="flex f-column margin-between-rows">
        <div class="flex f-column" style="margin-bottom: 12px">
          <label>Account name</label>
          <input type="text" #memoData="ngModel" required [(ngModel)]="traitData.memoData" name="memoData" [disabled]="!editable" [ngClass]="{'invalid-input': validator.hasGeneralErrors(traitDataForm, memoData)}">
          <span class="form-error" *ngIf="validator.hasIndividualError(traitDataForm, memoData, 'required')">This Field is Required</span>
        </div>
        <div class="flex f-row">
          <span>Verification</span>
          <span class="font-green" style="margin-left: 5px" *ngIf="traitData.booleanData else notVerifiedAccount">Verified</span>
          <ng-template #notVerifiedAccount>
            <span class="font-red" style="margin-left: 5px">Not Verified</span>
          </ng-template>
        </div>
        <div class="flex f-row" style="margin-top: 12px">
          <span class="text-verified-info" [innerHTML]="pageText.bankDataText"></span>
        </div>
      </div>
      <div class="flex f-row">
        <button type="submit" *ngIf="editable">Save</button>
        <button type="button" *ngIf="editable" (click)="cancelEdit()" style="margin-left: 5px">Cancel</button>
        <button type="button" *ngIf="!editable" (click)="editable = true">Edit</button>
      </div>
    </div>
  </form>

  <!-- Si el usuario no tiene dada de alta ningun trait mostramos texto -->
  <ng-template #noBankAccount>
    <div class="w-100 flex h-100 f-column alignItemCenter">
      <span [innerHTML]="pageText.noBankDataText"></span>
      <button class="w-fit-content" style="margin-top: 24px" (click)="newBankAccount()">Add Bank Account</button>
    </div>
  </ng-template>
</div>
