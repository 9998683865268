import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { EventModel, User } from '../../models';
import { AuthService } from '../../services/auth.service';
import { DVMService } from '../../services/dvm.service';
import { ConfigurationService } from '../../services/configuration.service';
import { ModalsService } from '../../modals/modals.service';
import { ConnectionService } from '../../services/connection.service';
import { forkJoin } from 'rxjs';
import { first, take } from 'rxjs/operators';
import {
  AvailabilitySeatmapModel,
  AvailabilitySeatMapPricesModel,
  AvailabilitySectionModel,
  FailedLockModel,
} from '../models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SelectAPriceModalComponent } from '../../modals/select-a-price-modal/select-a-price-modal.component';

interface dvmNode {
  id: string;
  type: string;
  state: 'available' | 'unavailable' | 'selected';
  tag: string;
  groups: Array<string>;
}

@Component({
  selector: 'app-select-a-seat',
  templateUrl: './select-a-seat.component.html',
  styleUrls: ['./select-a-seat.component.scss'],
})
export class SelectASeatComponent implements OnInit, OnChanges {
  peopleIdList: Array<string>;
  user: User;
  eventId: number;
  patronFailLock: { [key: number]: boolean } = {};
  existFailedLock: boolean = false;
  currentTransaction: number;
  event: EventModel;
  visibleSection: string;
  virtualCart: {
    basket: {
      [key: string]: {
        [key: string]: { seats_assigned: string[]; buyer_type: number; price: AvailabilitySeatMapPricesModel };
      };
    };
    total_price: number;
  } = { basket: {}, total_price: 0 };
  selectedCustomer: string;
  showTopbar = false;
  showLegendMap = false;
  availableSeatsHash;
  clientHoldCodes: { [key: string]: { code: string; name: string; color: string; buyer_type: string } };
  listFamilyEnclosureSection = ['ELS4', 'ELN6'];
  // ejemplo { 'ADULT': { 'S_L1': {.....}, 'S_L2': {....}, 'SENIOR': { 'S_L4': {.....} } } }
  availabilitySectionByBuyerType: { [key: string]: { [key: string]: AvailabilitySectionModel } } = {};
  availabilitySeatmapByHoldCode: { [key: string]: { [key: string]: AvailabilitySeatmapModel } } = {};
  buyerTypeRequest = {
    ADULT: true, // la disponibilidad de adult se pide siempre ya que tanto junior como senior PUEDEN comprar buyer type Adult.
  };
  bsModalRef: BsModalRef;

  displayMobileBasket = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dvmService: DVMService,
    private modalService: ModalsService,
    private bsModal: BsModalService,
    private connection: ConnectionService,
    public dataService: DataService,
    private auth: AuthService,
    public configurationService: ConfigurationService
  ) {}

  ngOnInit(): void {
    // Check selected associations
    if (this.activatedRoute.snapshot.queryParamMap.has('people')) {
      this.peopleIdList = this.activatedRoute.snapshot.queryParamMap.get('people').split(';');
      // Initialize cart
      this.peopleIdList.forEach(person => {
        // by looking at the birth years of the different fans, we will decide what availability to request.
        let alien = this.dataService.customerAssociations[person];
        this.buyerTypeRequest[
          this.dataService.getCustomerBuyerTypeExchange(alien.tdc_info.birthday, this.configurationService.client)
        ] = true;
        console.log(this.buyerTypeRequest);
        // start cart with placeholder
        this.virtualCart.basket[person] = {};
        console.log(this.virtualCart.basket);
      });
    }
    // get hold codes
    this.clientHoldCodes = this.dataService.getExchangeHoldCodes(this.configurationService.client);
    //  Get Event from query param and after from dataService
    if (this.activatedRoute.snapshot.queryParamMap.has('event')) {
      this.eventId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('event'), 10);
      this.event = this.dataService.exchangeEventsList[this.eventId];
      this.initializeMap();
    }

    this.auth.getUserLogged$().subscribe(response => {
      if (typeof response === 'boolean') {
        this.router.navigate(['login']);
        return;
      }
      this.user = response;

      // TODO REMOVE, ONLY TEST PURPOSES
      // this.virtualCart.basket[this.peopleIdList[1]] = { '1507': { seats_assigned: ['S_SL4-11-120'], buyer_type: 'exchange ambulant adult'} };
      // this.increaseTotalAmount({ price: 75, id: 1507, name: 'exchange', code: 'exch'});
      // this.dvmService.viewer.addNodesToGroup(['S_SL4-11-120'], this.peopleIdList[1]);
    });
  }

  createTransaction(): void {
    let isvalid = true;
    // check enclosure family 2 https://mmcbcn.atlassian.net/browse/IT-2225
    const hasSeatOnFamilyArea = this.hasSeatOnFamilyArea();

    if (hasSeatOnFamilyArea) {
      isvalid = this.validateFamilyEnclosureLogicOnCreateTransaction();
    }

    if (isvalid) {
      const title = 'Continue?';
      const message =
        'By clicking continue, your selection will be place on hold and you will be redirected to the checkout page.';
      const accept = 'Continue';
      this.modalService.info(title, message, accept, () => {
        const list = [];
        // debemos convertir nuestro basket en el formato correcto para la request.
        // [ { customer: string: { "buyer_type_id": {assigned_seats: ["mmc_id"]} } } ]
        for (let entry of Object.entries(this.virtualCart.basket)) {
          let [key, value] = entry;
          if (this.existFailedLock && this.currentTransaction && !this.patronFailLock.hasOwnProperty(key)) {
            continue;
          }
          list.push({ customer: key, seats: Object.assign({}, value) });
        }
        this.connection.postExchangeTransaction(this.eventId, list, this.currentTransaction).subscribe(response => {
          if (response.failed_locks && response.failed_locks.length > 0) {
            this.currentTransaction = response.id;
            this.failedLocks(response.failed_locks);
          } else {
            this.auth.getUserLogged(true).then(session => {
              if (session['exchange_transactions'] && session['exchange_transactions'].length) {
                this.router.navigate(['/exchange/checkout'], {
                  queryParams: { transaction: session['exchange_transactions'][0].id },
                });
              }
            });
          }
        });
      });
    } else {
      const title = 'System Message';
      const message = `The rules of Family enclosure are not met.`;
      this.modalService.info(title, message, null);
    }
  }

  private initializeMap(): void {
    this.dvmService.restartDVM(null, true);
    // mini map callbacks
    this.dvmService.subscribeMinimapHandler('end_load', () => {
      this.dvmService.minimapViewer.setAvailable('section', this.dvmService.minimapViewer.getNodesByType('section'));
      this.dvmService.minimapViewer.scaleBy(1.3);
    });
    // central map callbacks
    this.dvmService.subscribeHandler('end_load', () => {
      this.dvmService.viewer.setAvailable('section', this.dvmService.viewer.getNodesByType('section'));
      this.dvmService.viewer.scaling_factor = 1;
      this.dvmService.viewer.bindInterfaceAction(document.getElementById('map-interface-button-plus'), 'zoom-in');
      this.dvmService.viewer.bindInterfaceAction(document.getElementById('map-interface-button-minus'), 'zoom-out');
      this.sectionAvailability();
    });
    this.dvmService.subscribeHandler('enter', action => {
      const node: { id: string; type: string; state: 'available' | 'unavailable' } = action.nodes[0];
      if (!node && node.state === 'unavailable') return;
      this.dvmService.viewer.hover(node.id);
      // TODO SHOW POPOVER
    });
    this.dvmService.subscribeHandler('leave', action => {
      const node: { id: string; type: string; state: 'available' | 'unavailable' } = action.nodes[0];
      if (!node || node.state === 'unavailable') return;
      this.dvmService.viewer.hover([]);
    });
    this.dvmService.subscribeHandler('click', action => {
      const node: dvmNode = action.nodes[0];
      if (!node || node.state === 'unavailable') return;
      if (node.type === 'section' && node.id !== this.dvmService.viewer.getMapId()) {
        this.dvmService.changeMap(node.id).then(() => {
          this.onChangeMap(node);
        });
      } else if (node.type === 'seat') {
        this.onClickSeat(node);
      }
    });
  }

  private onChangeMap(node: dvmNode): void {
    this.miniMapToggle(node);
    this.topbarToggle(node);
    this.legendMapToggle();
    this.seatMapAvailability(node.id);
  }

  // Funcion para controlar cuando clickas a un nodo en el seatmap
  private onClickSeat(node: dvmNode): void {
    if (!this.selectedCustomer) {
      // si no hay usuario previamente seleccioando, no se pueden seleccionar sillas
      const title = 'Warning!';
      const message =
        'Before selecting a seat, please select a valid supporter for the ticket (i.e. Adult for Adult Tickets, Junior for Junior or Adult Tickets and Senior for Senior or Adult Tickets). Adult tickets cannot be purchased in the East Lower stand without a Junior or Senior ticket. Loyalty points are NOT awarded for tickets purchased on the ticket exchange.';
      const accept = 'Accept';
      this.modalService.info(title, message, accept, null, null);
      return;
    }

    if (node.state === 'selected') {
      this.onUnselectSeat(node);
    } else {
      this.onSelectSeat(node);
    }
  }

  private sectionAvailability(): void {
    let forkJoinHash = {};
    // vamos preparando el fork join
    for (let entry of Object.entries(this.buyerTypeRequest)) {
      let [key, value] = entry;
      forkJoinHash[key] = this.connection.getEventAvailabilityExchange(this.eventId, key);
    }
    // ahora ejecutamos el fork join
    forkJoin(forkJoinHash)
      .pipe(first())
      .subscribe(response => {
        this.dvmService.viewer.setAvailability('section', []);
        // Rellenamos el objeto con todas las availability
        this.availabilitySectionByBuyerType = response;
        let availableSectionList = [];
        // Unimos todas las secciones en una para pasarla a DVM
        Object.values(response).forEach(item => availableSectionList.push(...Object.keys(item)));
        this.dvmService.viewer.setAvailable('section', availableSectionList);
      });
  }

  private seatMapAvailability(nodeId: string): void {
    // this.dvmService.viewer.setAvailable('seat', this.dvmService.viewer.getNodesByType('seat'));
    // return;
    this.connection
      .getEventSectionAvailabilityExchange(this.eventId, nodeId)
      .pipe(take(1))
      .subscribe(response => {
        this.availabilitySeatmapByHoldCode = response;
        let availableList = [];
        const labels = {};
        Object.entries(response).forEach(item => {
          let [key, value] = item;
          const label = this.dataService.getExchangeHoldCodes(this.configurationService.client)[key].label;
          let listOfKeys = [...Object.keys(value)];
          this.dvmService.viewer.setNodesTag(listOfKeys, key);
          for (const key of listOfKeys) {
            labels[key] = { text: label, size: 1 };
          }
          availableList.push(...listOfKeys);
        });
        // this.dvmService.viewer.setAvailable('seat', this.dvmService.viewer.getNodesByType('seat'));
        this.dvmService.viewer.setAvailability('seat', availableList);
        this.dvmService.viewer.setLabels(labels);
        const list = this.getAllSeatsFromCart();
        if (list.length) {
          this.dvmService.viewer.select(list);
        }
      });
  }

  // Selecciona al comprador para acto seguido seleccionar una sillar para él.
  selectPurchaser(id: string): void {
    if (this.selectedCustomer === id) {
      this.selectedCustomer = null;
    } else {
      this.selectedCustomer = id;
    }
  }

  showCustomerBuyerType(date: string): string {
    let client = this.configurationService.client;
    return this.dataService.getCustomerBuyerTypeExchange(date, client);
  }

  // hace zoom a la zona selecciona o por el contrario deselecciona el nodo
  private miniMapToggle(node: dvmNode): void {
    if (node.state === 'selected') {
      document.getElementById('minimap').classList.remove('d-block');
      this.dvmService.minimapViewer.unselect(node.id);
      this.dvmService.minimapViewer.goTo([0, 0]);
    } else {
      document.getElementById('minimap').classList.add('visible');
      this.dvmService.minimapViewer.select(node.id);
      this.dvmService.minimapViewer.goTo(node.id);
    }
  }

  private topbarToggle(node: dvmNode): void {
    if (node.state === 'selected') {
      this.showTopbar = false;
      this.visibleSection = undefined;
    } else {
      this.showTopbar = true;
      this.visibleSection = node.id;
    }
  }

  private legendMapToggle(): void {
    this.showLegendMap = !this.showLegendMap;
  }

  backToTopview(): void {
    this.dvmService.changeMap('blockmap2d_custom').then(() => {
      this.dvmService.minimapViewer.unselectAll();
      document.getElementById('minimap').classList.remove('visible');
      this.showTopbar = false;
      this.legendMapToggle();
      let scaling = this.dvmService.minimapViewer.min_scaling_factor;
      this.dvmService.minimapViewer.goTo([0, 0], scaling);
    });
  }

  hideTopBar(event): void {
    this.showTopbar = false;
    this.legendMapToggle();
  }

  // Las cosas que han de pasar cuando se selecciona una silla.
  private onSelectSeat(node: dvmNode): void {
    let seat = this.availabilitySeatmapByHoldCode[node.tag][node.id];
    // VALIDAMOS SI EL SUPPORTER TIENE EL BUYER TYPE CORRECTO PARA SELECCIONAR LA SILLA, PARA ELLO USAMOS EL HOLD CODE
    const humanBirthday = this.dataService.customerAssociations[this.selectedCustomer].tdc_info.birthday;
    // Con la fecha de cumpleaños del supporter, conseguimos el buyer type que le corresponde.
    // Junto al buyer type del hold code, lo comparamos. Como siempre, cualquiera puede pillar una silla adult.
    const buyerType = this.dataService.getCustomerBuyerTypeExchange(humanBirthday, this.configurationService.client);
    // Si el buyer type del hold code es difernete Adult y ademas el hold code del supporter es diferente, no podra seleccionar
    if (
      this.clientHoldCodes[node.tag].buyer_type !== 'ADULT' &&
      this.clientHoldCodes[node.tag].buyer_type !== buyerType
    ) {
      this.openCannotPickSeatModal();
      return;
    }
    this.dvmService.viewer.select(node.id);
    // seat.prices.push({ name: 'demo', id:99, price: 75, code: 'demo'});
    // Como el usuario cumple con los requisitos, seguimos con el proceso de seleccionar
    if (seat.prices.length > 1) {
      // path multiple precios para un mismo buyer type
      // abrimos el modal con la lista de precios
      const initialState = { priceList: seat.prices };
      this.bsModalRef = this.bsModal.show(SelectAPriceModalComponent, {
        class: 'modal-dialog-centered',
        initialState,
        ignoreBackdropClick: true,
      });
      this.bsModalRef.onHide.subscribe(() => {
        this.setSeatAndPrice(this.bsModalRef.content.selectedPrice, node.id);
      });
    } else {
      // path solo un precio
      this.setSeatAndPrice(seat.prices[0], node.id);
    }
  }

  // Las cosas que han de pasar cuando se deselecciona una silla.
  private onUnselectSeat(node: dvmNode): void {
    // antes de nada vemos si pertenece a la persona seleccionada
    if (node.groups.indexOf(this.selectedCustomer) === -1) {
      // show modal
      this.modalCannotRemoveSeat(node.id);
      return;
    }
    this.dvmService.viewer.unselect(node.id);
    // buscando quien tiene la silla y quitandosela
    const seat = this.availabilitySeatmapByHoldCode[node.tag][node.id];
    // la silla puede tener multiples precios veamos cual esta seleccionado
    let price: AvailabilitySeatMapPricesModel;
    if (seat.prices.length > 1) {
      // buscamos la silla
      seat.prices.forEach(item => {
        let priceObject = this.virtualCart.basket[this.selectedCustomer];
        if (priceObject.hasOwnProperty(item.id) && priceObject[item.id].seats_assigned.includes(node.id)) {
          price = priceObject[item.id].price;
        }
      });
    } else {
      price = seat.prices[0];
    }
    const buyerTypeByUser = this.virtualCart.basket[this.selectedCustomer][price.id];
    const seatIndex = buyerTypeByUser.seats_assigned.indexOf(node.id);
    buyerTypeByUser.seats_assigned.splice(seatIndex, 1);
    this.dvmService.viewer.removeNodesFromGroup([node.id], this.selectedCustomer);
    this.reduceTotalAmount(buyerTypeByUser.price.price); // TODO se tendra que guardar en el carro y pillarlo de ahi.
    if (!buyerTypeByUser.seats_assigned.length) {
      delete this.virtualCart.basket[this.selectedCustomer][price.id];
    }
  }

  // set seat and price
  private setSeatAndPrice(price: AvailabilitySeatMapPricesModel, nodeId: string): void {
    if (!this.virtualCart.basket[this.selectedCustomer].hasOwnProperty(price.id)) {
      this.virtualCart.basket[this.selectedCustomer][price.id] = {
        seats_assigned: [],
        buyer_type: price.id,
        price: price,
      };
    }
    this.virtualCart.basket[this.selectedCustomer][price.id].seats_assigned.push(nodeId);
    this.dvmService.viewer.addNodesToGroup([nodeId], this.selectedCustomer);
    this.increaseTotalAmount(price.price);
    console.log(this.virtualCart.basket);
  }

  private increaseTotalAmount(price: number): void {
    this.virtualCart.total_price += price;
  }

  private reduceTotalAmount(price: number): void {
    this.virtualCart.total_price -= price;
  }

  // En caso de fallos en los locks, debemos mostrar el modal y permitir al usuario seleccionar nuevas sillas
  private failedLocks(failedLocksList: Array<FailedLockModel>): void {
    this.selectedCustomer = null;
    this.existFailedLock = true;
    const title = 'Warning!';
    let message = '';
    let button = 'Accept';
    for (const failedLock of failedLocksList) {
      message += `${failedLock.name} with Patron id ${failedLock.associate_id} <br>`;
      message += `${failedLock.reason}<br><br>`;
    }
    message += 'Please select a new seat for this Supporters.';
    this.modalService.info(
      title,
      message,
      button,
      () => {
        failedLocksList.forEach(failedLock => {
          this.virtualCart.basket[failedLock.associate_id] = {};
          this.patronFailLock[failedLock.associate_id] = true;
        });
        this.backToTopview();
      },
      null
    );
  }

  private openCannotPickSeatModal(): void {
    // si no hay usuario previamente seleccioando, no se pueden seleccionar sillas
    const title = 'Warning!';
    const message = "Supporter don't meet requirement to select this seat. Please select another.";
    const accept = 'Accept';
    this.modalService.info(title, message, accept, null, null);
  }

  private modalCannotRemoveSeat(seatId: string): void {
    // si no hay usuario previamente seleccioando, no se pueden seleccionar sillas
    const seat = this.simplifySeatId(seatId);
    const title = 'Warning!';
    const message = `Selected Supporter does not own the seat you are trying to remove.<br>- ${seat}`;
    const accept = 'Accept';
    this.modalService.info(title, message, accept, null, null);
  }

  toggleMobileBasket() {
    this.displayMobileBasket = !this.displayMobileBasket;
  }

  simplifySeatId(seatId: string): string {
    let strArray = seatId.split('-');
    return `Section: ${strArray[0].split('_')[1]}, Row: ${strArray[1]}, Seat: ${strArray[2]}`;
  }

  getAllSeatsFromCart(): Array<string> {
    const list = [];
    for (let basket of Object.values(this.virtualCart.basket)) {
      Object.values(basket).forEach(holdCode => {
        list.push(...holdCode.seats_assigned);
      });
    }

    return list;
  }

  validateCart(): any {
    let valid = !(this.virtualCart.total_price === 0);
    const allSeats = this.getAllSeatsFromCart();
    if (allSeats.length) {
      let seat = allSeats.find(seat => this.listFamilyEnclosureSection.includes(this.getSectionFromSeatId(seat)));
      if (seat) {
        valid = this.validateFamilyEnclosureLogic();
      }
    }
    return valid;
  }

  private getSectionFromSeatId(seatId: string): string {
    return seatId.split('-')[0].split('_')[1];
  }

  private validateFamilyEnclosureLogic(): any {
    const listFamilyEnclosureIds = this.dataService.getExchangeFamilyAreaBuyerTypeIdList(
      this.configurationService.client
    );
    let valid = false;
    for (let basket of Object.keys(this.virtualCart.basket)) {
      // iterar por customers
      const holdCode = this.virtualCart.basket[basket];
      for (let hcKey of Object.keys(holdCode)) {
        // iterar sobre el hold code del pipiolo
        if (hcKey !== '1510' && hcKey !== '1511' && hcKey !== '1512') {
          // solo es si un hold code de adult se hace la historia
          const hcObject = holdCode[hcKey];
          const seat = hcObject.seats_assigned.find(seat =>
            this.listFamilyEnclosureSection.includes(this.getSectionFromSeatId(seat))
          );
          if (seat && !listFamilyEnclosureIds.includes(hcObject.buyer_type)) {
            valid = true;
          }
        }
      }
    }
    return valid;
  }

  // mira en todos los carros, a ver de que secciones tenemos las sillas para aplicar las reglas de enclosure family o no
  private hasSeatOnFamilyArea(): any {
    const listFamilyEnclosureIds = this.dataService.getExchangeFamilyAreaBuyerTypeIdList(
      this.configurationService.client
    );
    let valid = false;
    for (let basket of Object.keys(this.virtualCart.basket)) {
      // iterar por customers
      const holdCode = this.virtualCart.basket[basket];
      for (let hcKey of Object.keys(holdCode)) {
        // iterar sobre el hold code del pipiolo
        const hcObject = holdCode[hcKey];
        const seat = hcObject.seats_assigned.find(seat =>
          this.listFamilyEnclosureSection.includes(this.getSectionFromSeatId(seat))
        );
        if (seat) {
          valid = true;
        }
      }
    }

    return valid;
  }

  // Validamos que entre todos los usuarios cumplan las reglas de enclosure family https://mmcbcn.atlassian.net/browse/IT-2225
  // por cada junior pueden añadir 2 adults.
  // por cada senior pueden añadir 1 adult.
  // se puede combinar entre si: 2 junior + 2 senior => 6 adults allowed.
  private validateFamilyEnclosureLogicOnCreateTransaction(): boolean {
    const bytJuniorList = this.dataService.getExchangeFamilyEnclosureBuyerTypeJunior(this.configurationService.client);
    const bytSeniorList = this.dataService.getExchangeFamilyEnclosureBuyerTypeSenior(this.configurationService.client);
    const bytAdultList = this.dataService.getExchangeFamilyAreaBuyerTypeIdList(this.configurationService.client);
    const buyerTypeCounter = {}; // iniciamos los buyer types a 0
    bytAdultList.forEach(key => (buyerTypeCounter[key] = 0)); // hay 3 tipos de adults, iniciamos los 3 a 0
    bytJuniorList.forEach(key => (buyerTypeCounter[key] = 0)); // hay 3 tipos de junior, iniciamos los 3 a 0
    bytSeniorList.forEach(key => (buyerTypeCounter[key] = 0)); // hay 3 tipos de seniors, iniciamos los 3 a 0
    let valid = false;
    for (let basket of Object.keys(this.virtualCart.basket)) {
      // iterar por customers
      const buyerType = this.virtualCart.basket[basket];
      for (let [btKey, btValue] of Object.entries(buyerType)) {
        // iterar sobre el buyer type del pipiolo
        buyerTypeCounter[btKey] += btValue.seats_assigned.length;
      }
    }
    // sumamos todos los juniors
    let totalJuniors = 0;
    bytJuniorList.forEach(key => (totalJuniors += buyerTypeCounter[key]));
    let allowedAdults = totalJuniors * 2;
    // sumamos todos los seniors
    let totalSenior = 0;
    bytSeniorList.forEach(key => (totalSenior += buyerTypeCounter[key]));
    allowedAdults += totalSenior;
    // sumamos todos los adults
    let totalAdults = 0;
    bytAdultList.forEach(key => (totalAdults += buyerTypeCounter[key]));
    // verificamos que la cantidad de adults NO este por encima de la permitida usando las reglas del enclosure family.
    if (allowedAdults >= totalAdults) {
      valid = true;
    }

    return valid;
  }

  ngOnChanges(changes: SimpleChanges): void {}
}
