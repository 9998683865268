<app-admin-login-banner *ngIf="isAdmin()" [adminUsername]="user.admin_takeover.username"></app-admin-login-banner>
<!--<app-admin-login-banner *ngIf="true" [adminUsername]="'superadmin'"></app-admin-login-banner>-->
<div class="flex">
    <app-menu></app-menu>
    <div style="width: 100%; height: 100%;">
        <app-header></app-header>
        <div class="layout-container">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
