import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG } from 'src/app/configuration/app.configuration';
import { AppConfiguration } from 'src/app/models/appconfiguration.model';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-confirm-association',
  templateUrl: './confirm-association.component.html',
  styleUrls: ['./confirm-association.component.scss'],
})
export class ConfirmAssociationComponent implements OnInit {
  backgroundUrl;

  token;

  requestSent = false;

  constructor(
    @Inject(APP_CONFIG) public appConfig: AppConfiguration,
    private connection: ConnectionService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.backgroundUrl = `url(${this.appConfig.backgroundImageUrl})`;
    if (this.activatedRoute.snapshot.params) {
      if (this.activatedRoute.snapshot.params.t) {
        this.token = this.activatedRoute.snapshot.params.t;
        this.confirmAssociation();
      }
    }
  }

  confirmAssociation() {
    if (this.token) {
      this.connection.postAddAssociationFinalize(this.token).subscribe(
        () => {
          this.requestSent = true;
        },
        () => {
          this.router.navigate(['/buy-tickets']);
        }
      );
    }
  }
}
