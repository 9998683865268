import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APP_CONFIG } from '../configuration/app.configuration';
import { DVM_CONFIG } from '../configuration/dvm.configuration';
import { DataService } from './data.service';
import { ConfigurationService } from './configuration.service';

// declare var DVM;

@Injectable({
  providedIn: 'root',
})
export class DVMService {
  viewer: D2M.Viewer;
  viewer3d: DVM.Viewer3d;
  minimapViewer: D2M.Viewer;
  viewerSubject: Subject<D2M.Viewer> = new Subject();
  viewer3dSubject: Subject<DVM.Viewer3d> = new Subject();
  minimapViewSubject: Subject<D2M.Viewer> = new Subject();
  configurationChangedSuject: Subject<any> = new Subject();
  isViewerSubjectInitialized = false;
  isViewer3dSubjectInitialized = false;
  isMinimapViewerSubjectInitialized = false;
  subscribedCallbacks = {};
  subscribed3dCallbacks = {};
  subscribedMinimapCallbacks = {};
  debug: boolean;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private config: ConfigurationService,
    @Inject(DVM_CONFIG) private DVMConfig,
    private data: DataService
  ) {
    this.viewerSubject.subscribe(viewer => (this.viewer = viewer));
    this.debug = true;
  }

  public restartDVM(venueId?: any, minimap?: boolean, mapId: string = null) {
    // tslint:disable-next-line: forin
    for (const event in this.subscribedCallbacks) {
      this.subscribedCallbacks[event].forEach(callback => {
        this.viewer.unsubscribe(event as any, callback);
      });
    }
    // tslint:disable-next-line: forin
    for (const event in this.subscribed3dCallbacks) {
      this.subscribed3dCallbacks[event].forEach(callback => {
        this.viewer3d.unsubscribe(event as any, callback);
      });
    }
    this.isViewerSubjectInitialized = false;
    this.isViewer3dSubjectInitialized = false;
    this.isMinimapViewerSubjectInitialized = false;
    if (venueId) {
      const viewerConfig = JSON.parse(JSON.stringify(this.DVMConfig));
      viewerConfig.venue_id = venueId;
      viewerConfig.map_id = mapId ? mapId : this.viewer.getMapId();
      this.initializeDVM('map_viewer', viewerConfig, minimap);
    } else {
      this.initializeDVM('map_viewer', this.DVMConfig, minimap);
    }
  }

  private initializeDVM(moduleName, config, minimap?: boolean) {
    DVM.loadModule('map_viewer', config)
      .then(viewer => {
        this.viewer = viewer;
        if (this.debug) {
          (window as any).viewer = viewer;
        }
        this.loadMap(config);
        if (!this.isViewerSubjectInitialized) {
          this.viewerSubject.next(this.viewer);
          this.isViewerSubjectInitialized = true;
        }
        viewer.flags.automatic_selection = false;
        viewer.flags.automatic_hover = false;
        viewer.flags.fixed_aspect_ratio = false;
      })
      .catch(err => {
        console.error(err);
      });
    // const viewer3dConfig = JSON.parse(JSON.stringify(config));
    // viewer3dConfig.container = 'viewer3d-container';
    // viewer3dConfig.plugins = ['navigation'];
    // DVM.loadModule('3d_viewer', viewer3dConfig)
    //     .then((viewer3d) => {
    //         this.viewer3d = viewer3d;
    //         if (this.debug) {
    //             (window as any).viewer3d = viewer3d;
    //         }
    //         if (!this.isViewer3dSubjectInitialized) {
    //             this.viewer3dSubject.next(this.viewer3d);
    //             this.isViewer3dSubjectInitialized = true;
    //         }
    //     })
    //     .catch((err) => {
    //         console.error(err);
    //     });
    if (minimap) {
      const viewerConfig: DVM.IDVMViewerInitializerOptions = JSON.parse(JSON.stringify(config));
      viewerConfig.container = 'viewer-container-minimap';
      DVM.loadModule('map_viewer', viewerConfig)
        .then(viewer => {
          this.minimapViewer = viewer;
          if (this.debug) {
            (window as any).minimapViewer = viewer;
          }
          this.loadMiniMap(viewerConfig);
          if (!this.isMinimapViewerSubjectInitialized) {
            this.minimapViewSubject.next(this.minimapViewer);
            this.isMinimapViewerSubjectInitialized = true;
          }
          viewer.flags.automatic_selection = false;
          viewer.flags.automatic_hover = false;
          viewer.flags.fixed_aspect_ratio = false;
          viewer.flags.panning = false;
          viewer.flags.zooming = false;
        })
        .catch();
    }
  }

  changeMapConfiguration(venueId) {
    if (this.viewer) {
      const viewerConfig = JSON.parse(JSON.stringify(this.DVMConfig));
      viewerConfig.venue_id = venueId;
      viewerConfig.map_id = this.viewer.getMapId();
      this.loadMap(viewerConfig, () => {
        this.configurationChangedSuject.next(true);
      });
    }
  }

  subscribeHandler(event: 'click' | 'end_load' | 'enter' | 'leave', callback) {
    const viewerSubjectSubscribe = this.viewerSubject.subscribe(viewer => {
      this.viewer.subscribe(event, callback);
      if (!this.subscribedCallbacks[event]) {
        this.subscribedCallbacks[event] = [];
      }
      this.subscribedCallbacks[event].push(callback);
      viewerSubjectSubscribe.unsubscribe();
    });
  }

  subscribeMinimapHandler(event: 'click' | 'end_load' | 'enter' | 'leave', callback): void {
    const viewerSubjectSubscribe = this.minimapViewSubject.subscribe(viewer => {
      this.minimapViewer.subscribe(event, callback);
      if (!this.subscribedMinimapCallbacks[event]) {
        this.subscribedMinimapCallbacks[event] = [];
      }
      this.subscribedMinimapCallbacks[event].push(callback);
      viewerSubjectSubscribe.unsubscribe();
    });
  }

  subscribe3dHandler(event: 'click_navigation_node', callback) {
    const viewer3dSubjectSubscribe = this.viewer3dSubject.subscribe(viewer3d => {
      this.viewer3d.subscribe(event, callback);
      if (!this.subscribed3dCallbacks[event]) {
        this.subscribed3dCallbacks[event] = [];
      }
      this.subscribed3dCallbacks[event].push(callback);
      viewer3dSubjectSubscribe.unsubscribe();
    });
  }

  public loadMap(loadOptions, callback?) {
    return this.viewer.loadMap(loadOptions).then(() => {
      this.applyStyles(this.viewer);
      if (callback) {
        callback();
      }
    });
  }

  public loadMiniMap(loadOptions, callback?) {
    return this.minimapViewer.loadMap(loadOptions).then(() => {
      this.applyStyles(this.minimapViewer);
      if (callback) {
        callback();
      }
    });
  }

  public load3DView(nodeId) {
    const loadOptions = JSON.parse(JSON.stringify(this.DVMConfig));
    loadOptions.view_id = nodeId;
    loadOptions.venue_id = this.viewer.getVenueId();
    this.viewer3d.loadView3d(loadOptions);
  }

  applyStyles(viewer) {
    const styles = viewer.getStyles();
    if (styles[0] && styles[0].section) {
      styles[0].section.available.normal.none.fillStyle = this.appConfig.accentColor;
      styles[0].section.available.hover.none.fillStyle = this.appConfig.hoverColor;
      styles[0].section.selected.normal.none.fillStyle = this.appConfig.hoverColor;
      styles[0].section.selected.hover.none.fillStyle = this.appConfig.hoverColor;
    }
    if (styles[0] && styles[0].seat) {
      // Debemos aplicar los estilos para cada hold code
      const holdCodes = this.data.getExchangeHoldCodes(this.config.client);
      for (let entry of Object.entries(holdCodes)) {
        let [key, code] = entry;
        styles[0].seat.available.normal[key] = {
          fillStyle: code.color,
          strokeStyle: code.color,
          lineWidth: 0.3,
          text: true,
          cursor: 'pointer',
        };
        styles[0].seat.available.hover[key] = {
          fillStyle: this.appConfig.hoverColor,
          strokeStyle: code.color,
          lineWidth: 0.3,
          text: true,
          cursor: 'pointer',
        };
      }
    }

    viewer.setStyles(styles);
  }

  private getNodeId(obj) {
    return obj.nodes.length > 0 ? obj.nodes[0].id : null;
  }

  public changeMap(node: string): Promise<void> {
    const loadOptions = JSON.parse(JSON.stringify(this.DVMConfig));
    loadOptions.map_id = node;
    loadOptions.venue_id = this.viewer.getVenueId();
    return this.loadMap(loadOptions);
  }
}
