import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { DataService } from '../../services/data.service';
import { ConnectionService } from '../../services/connection.service';

@Injectable({
  providedIn: 'root',
})
export class EventsResolverService implements Resolve<boolean> {
  constructor(private dataService: DataService, private router: Router, private connection: ConnectionService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (
      route.queryParamMap.has('event') &&
      this.dataService.exchangeEventsList[parseInt(route.queryParamMap.get('event'), 10)]
    ) {
      return true;
    } else {
      this.router.navigate(['exchange']);
      return false;
    }
  }
}
