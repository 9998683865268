<div class="login-container">
  <div class="flex login-header">
    <div class="main-logo-container">
      <img src="{{ appConfig.logoUrl }}" alt="{{ appConfig.clientName }}" />
    </div>
    <div style="margin-left: 24px">
      <span class="title" [ngClass]="{ dark: appConfig.darkTheme }">{{ appConfig.clientName }}</span
      ><span class="subtitle"> | {{ appConfig.applicationName }}</span>
    </div>
  </div>
  <div class="login-form-container">
    <!-- We show this form when the user enter for the first time to the REGISTER -->
    <form *ngIf="!token && !isCAEDBcode && !isCAETDCcode" #LoginForm="ngForm" (ngSubmit)="sendEmail()">
      <div *ngIf="!requestSent" class="form flex f-column">
        <p>
          Do you have a Season Ticket or Membership?<br />Enter your email and we will send you a confirmation email to
          register you account.
        </p>
        <p>If you are creating a new account, enter your email to start your registration.</p>
        <label for="InAccountId">Email</label>
        <input type="text" id="InAccountId" [(ngModel)]="username" name="username" autocomplete="false" required />
        <button type="submit" class="submit" [disabled]="!LoginForm.valid">Check Email</button>
      </div>
      <div *ngIf="isEmailIncorrect" class="emailNotValid">
        <p>Email not valid</p>
      </div>
      <div *ngIf="requestSent">
        <p>
          Your request has been submitted.<br />If a matching account is found an email will be sent. Check your inbox
          and follow the instructions to register your ticketing account.
        </p>
      </div>
    </form>

    <!--  We show this form we the user click in the link in his EMAIL -->
    <form *ngIf="token" #LoginForm="ngForm" (ngSubmit)="register()">
      <div *ngIf="!requestSent" class="form flex f-column">
        <p>Enter a new password:</p>
        <label for="InPassword">Password</label>
        <input type="password" id="InPassword" [(ngModel)]="password" name="password" required />
        <label for="InPassword">Confirm Password</label>
        <input type="password" id="InPassword" [(ngModel)]="password2" name="password" required />
        <button
          type="submit"
          class="submit"
          [disabled]="!LoginForm.valid || !(password && password2 && password === password2)">
          Link Account
        </button>
      </div>
      <div *ngIf="requestSent">
        <p>Account linked successfully!</p>
      </div>
    </form>

    <!-- The user exist in MMC PORTAL AND PV  -->
    <div *ngIf="isCAEDBcode" class="form flex f-column">
      <form *ngIf="!requestSent" (ngSubmit)="resetPassword()">
        <p>
          A record already exist with this email address. Please use the link below to reset your password if you have
          forgotten it
        </p>
        <button type="submit" class="submit resetPasswordBtn">Reset password</button>
      </form>
      <div *ngIf="requestSent">
        <p>
          Your request has been submitted.<br />Check your inbox and follow the instructions to reset your password.
        </p>
      </div>
    </div>

    <!-- We show this message if the user ONLY exist in PV -->
    <div *ngIf="isCAETDCcode" class="form flex f-column">
      <p>
        We have matched your email address with an existing record and haved emailed you a link to complete the
        registration proccess.
      </p>
    </div>
  </div>
  <div style="margin-top: 24px">
    <a [routerLink]="'/login'">Back to login</a>
  </div>
</div>
<div class="login-bg" [ngStyle]="{ 'background-image': backgroundUrl }"></div>
<div class="overlay" [ngClass]="{ dark: appConfig.darkTheme }"></div>
