import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss'],
})
export class ModalsComponent implements OnInit {
  title: string;
  content: string;
  closeBtnName = 'Close';
  closeFunction: any;
  acceptBtnName: string;
  acceptFunction: any;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}

  accept() {
    this.bsModalRef.hide();
    if (this.acceptFunction) {
      this.acceptFunction();
    }
  }

  close() {
    this.bsModalRef.hide();
    if (this.closeFunction) {
      this.closeFunction();
    }
  }
}
