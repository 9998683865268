import { Injectable } from '@angular/core';
import { ConnectionService } from './connection.service';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KickService {
  private interval: NodeJS.Timeout;
  // intervalTime = 900000; // 15 min in ms
  intervalTime = 600000; // 10 min in ms
  // private intervalTime = 1000;

  constructor(private conn: ConnectionService, private auth: AuthService) {}

  public startCounter(): void {
    this.interval = setInterval(() => {
      this.getEvictStatus().subscribe(response => {
        if (response.evict) {
          this.auth
            .logout()
            .toPromise()
            .then(() => {
              this.stopCounter();
              const url = location.href.match('^(?:https?:\\/\\/)?(?:[^@\\/\\n]+@)?(?:www\\.)?([^:\\/?\\n]+)');
              location.replace(url[0]);
            });
        }
      });
    }, this.intervalTime);
  }

  public stopCounter(): void {
    clearInterval(this.interval);
  }

  public getEvictStatus(): Observable<{ evict: boolean }> {
    return this.conn.getEvictStatus();
  }
}
