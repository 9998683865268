<div class="">
  <a
    [routerLink]="[
      '/buy-tickets/select-friends',
      { event: event.id, venue: this.dataService?.lastVenue, transaction: fromTransaction }
    ]"
    style="margin-left: 16px"
    >Go back</a
  >
  <div class="flex wrap-mobile" style="height: 100%">
    <div class="rounded-block map-block flex flex-column justify-content-between">
      <!-- CONTENT -->
      <div>
        <!-- INFO EVENT-->
        <div class="rounded-block-header-container">
          <div class="content-title" style="display: flex; justify-content: space-between">
            <div>
              <span>{{ event.name }}</span>
              <div class="content-subtitle">
                <span>{{ event.date | date : 'dd/MM/yyyy hh:mm a' }}</span>
              </div>
              <div class="content-subtitle">
                <span>{{ event.venue }}</span>
              </div>
            </div>
            <!--    <button *ngIf="event.description" class="extras-modal-selection-button icon-container" tooltip="{{ event.description }}">
              <div class="icon plus">i</div>
            </button> -->
            <div
              *ngIf="event.description"
              class="popover-placeholder"
              [popper]="tooltipcontent"
              [popperTrigger]="'hover'"
              [popperPlacement]="'left-start'"
              [popperApplyClass]="'popperStyle'">
              <button class="icon-container">
                <div class="icon plus">i</div>
              </button>
            </div>
          </div>
          <popper-content #tooltipcontent>
            <p>
              {{ event.description }}
            </p>
          </popper-content>
        </div>
        <!-- PRICE SCALE -->
        <div class="w-100 flex flex-column justify-content-start" style="margin-bottom: 24px">
          <div style="margin-bottom: 12px">
            <span>Select your membership level:</span>
          </div>
          <div class="">
            <ng-container *ngFor="let item of availabilityMemberships | keyvalue">
              <div
                class="rounded-block list-element-container flex flex-row justify-content-between"
                [ngClass]="{ selected: selectedMembership === item.key }"
                *ngIf="validatePriceScale(item.value.code)">
                <span>{{ item.value.name | titlecase }}</span>
                <button
                  (click)="selectMembership(item.key)"
                  *ngIf="selectedMembership !== item.key; else unselectMembershp">
                  Select
                </button>
                <ng-template #unselectMembershp>
                  <button class="" (click)="unselectMembership()">Unselect</button>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- BUYER TYPE LIST -->
        <div class="w-100 flex flex-column justify-content-start">
          <div style="margin-bottom: 12px">
            <span>Select your buyer type:</span>
          </div>
          <div class="" *ngIf="selectedMembership">
            <ng-container *ngFor="let item of availabilityMemberships[selectedMembership].prices | keyvalue">
              <div
                class="rounded-block list-element-container flex flex-row justify-content-between"
                [ngClass]="{ selected: selectedMembershipBuyerType.id === item.value.id }"
                *ngIf="validateBuyerType(item.value.code)">
                <div>
                  <span>{{ item.value.name | titlecase }}</span> -
                  <span>{{ item.value.price | currency : dataService.currencyCode }}</span>
                </div>
                <button
                  *ngIf="selectedMembershipBuyerType.id !== item.value.id; else unselectBT"
                  (click)="selectBuyerType(item.value)">
                  Select
                </button>
                <ng-template #unselectBT>
                  <button (click)="unselectBuyerType()">Unselect</button>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- bottom -->
      <div class="flex flex-row justify-content-end">
        <button *ngIf="selectedMembership && selectedMembershipBuyerType.name.length" (click)="checkout()">
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
