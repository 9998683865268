<div class="login-container">
  <div class="flex login-header">
    <div class="main-logo-container">
      <img src="{{ appConfig.logoUrl }}" alt="{{ appConfig.clientName }}" />
    </div>
    <div style="margin-left: 24px">
      <span class="title" [ngClass]="{ dark: appConfig.darkTheme }">{{ appConfig.clientName }}</span
      ><span class="subtitle"> | {{ appConfig.applicationName }}</span>
    </div>
  </div>
  <div class="login-form-container">
    <form #LoginForm="ngForm" (ngSubmit)="login()">
      <div class="form flex f-column">
        <label for="InAccountId">Email</label>
        <input type="email" id="InAccountId" [(ngModel)]="username" name="username" required />
        <label for="InPassword">Password</label>
        <input type="password" id="InPassword" [(ngModel)]="password" name="password" required />
        <button type="submit" class="submit" [disabled]="!LoginForm.valid">Log in</button>
      </div>
    </form>
    <div *ngIf="loginError" style="text-align: center; margin-top: 8px">
      <span>{{ msgError }}</span>
      <br>
      <span>Please refresh your browser and try again.</span>
    </div>
    <div style="margin-top: 24px; text-align: center">
      <div><a [routerLink]="'/register'">Register / Create an account</a></div>
      <div style="margin-top: 8px"><a [routerLink]="'/reset-password'">Forgot your password?</a></div>
      <br />
      <p>First time using this site? Please select register / create an account to get started</p>
    </div>
  </div>
</div>
<div class="login-bg" [ngStyle]="{ 'background-image': backgroundUrl }"></div>
<div class="overlay login-overlay" [ngClass]="{ dark: appConfig.darkTheme }"></div>
