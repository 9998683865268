import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Package, PackageAvailabilityResponse } from '../models/package.model';
import { ConnectionService } from './connection.service';
import { DataService } from './data.service';
import { tap } from 'rxjs/operators';
import { DeliveryMethod } from '../models/delivery-method.model';
import { FiservFormModel } from '../models/fiserv-form.model';

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  private myAppUrl: string = environment.apiUrl;

  constructor(private http: HttpClient, private connection: ConnectionService, private dataService: DataService) {}

  getListPackages(): Observable<Package[]> {
    const apiUrl: string = '/packages/';
    this.dataService.isLoaderActive = true;

    return this.http.get<Package[]>(`${this.myAppUrl}${apiUrl}`).pipe(
      tap({
        next: v => {
          this.dataService.isLoaderActive = false;
        },
        error: e => this.connection.requestErrorFunction(e),
      })
    );
  }

  getPackageAvailability(idPackage: number): Observable<PackageAvailabilityResponse> {
    const apirUrl: string = '/availability/';
    this.dataService.isLoaderActive = true;

    return this.http.get<PackageAvailabilityResponse>(`${this.myAppUrl}/packages/${idPackage}${apirUrl}`).pipe(
      tap({
        next: v => {
          this.dataService.isLoaderActive = false;
        },
        error: e => this.connection.requestErrorFunction(e),
      })
    );
  }

  getPackageMorfology(idPackage: number): Observable<any> {
    const apirUrl: string = '/morfology';
    this.dataService.isLoaderActive = true;

    return this.http.get<any>(`${this.myAppUrl}/packages/${idPackage}${apirUrl}`).pipe(
      tap({
        next: v => {
          this.dataService.isLoaderActive = false;
        },
        error: e => this.connection.requestErrorFunction(e),
      })
    );
  }

  savePackageTransaction(
    packageId: number,
    priceScale: number,
    customerAssociations: Array<any>,
    fromTransactionId: number = null
  ): Observable<any> {
    const apirUrl: string = '/package_transaction/';

    const body = this.getBodyPost(packageId, priceScale, customerAssociations, fromTransactionId);

    this.dataService.isLoaderActive = true;
    return this.http.post(`${this.myAppUrl}${apirUrl}`, body).pipe(
      tap({
        next: v => {
          this.dataService.isLoaderActive = false;
        },
        error: e => this.connection.requestErrorFunction(e),
      })
    );
  }

  getBodyPost(
    packageId: number,
    priceScale: number,
    customerAssociations: Array<any>,
    fromTransactionId: number = null
  ): Object {
    const body = {
      package: packageId,
      price_scale: priceScale,
      friends_family_accounts: customerAssociations,
    };
    if (fromTransactionId) {
      body['from_transaction'] = fromTransactionId;
    }

    return body;
  }

  deletePackageTransaction(id: number): Observable<any> {
    const apiUrl: string = '/package_transaction/';
    this.dataService.isLoaderActive = true;
    return this.http.delete(`${this.myAppUrl}${apiUrl}${id}/`).pipe(
      tap({
        next: v => {
          this.dataService.isLoaderActive = false;
        },
        error: e => this.connection.requestErrorFunction(e),
      })
    );
  }

  putPackageTransaction(transactionId: number, deliveryMethod: DeliveryMethod): Observable<any> {
    const apiUrl: string = '/package_transaction/';
    this.dataService.isLoaderActive = true;

    const body = this.getBodyPutTransaction(deliveryMethod);

    return this.http.put<any>(`${this.myAppUrl}${apiUrl}${transactionId}/`, body).pipe(
      tap({
        next: v => {
          this.dataService.isLoaderActive = false;
        },
        error: e => this.connection.requestErrorFunction(e),
      })
    );
  }

  getBodyPutTransaction(deliveryMethod: DeliveryMethod) {
    const body = {
      delivery_method_id: deliveryMethod.delivery_method_id,
      delivery_method_type: deliveryMethod.delivery_method_type,
      delivery_first_name: deliveryMethod.delivery_first_name,
      delivery_last_name: deliveryMethod.delivery_last_name,
    };

    if (deliveryMethod.delivery_method_type === 'EXTERNAL_TICKETS_AT_HOME') {
      body['delivery_email'] = deliveryMethod.delivery_email;
    } else if (
      deliveryMethod.delivery_method_type === 'POSTAL' ||
      deliveryMethod.delivery_method_type === 'POSTAL_TRACKABLE'
    ) {
      body['delivery_address1'] = deliveryMethod.delivery_address1;
      body['delivery_address2'] = deliveryMethod.delivery_address2;
      body['delivery_city'] = deliveryMethod.delivery_city;
      body['delivery_country_code'] = deliveryMethod.delivery_country_code;
      body['delivery_postal_code'] = deliveryMethod.delivery_postal_code;
      if (deliveryMethod.delivery_sub_country_code)
        body['delivery_sub_country_code'] = deliveryMethod.delivery_sub_country_code;
      if (deliveryMethod.delivery_sub_country_name)
        body['delivery_sub_country_name'] = deliveryMethod.delivery_sub_country_name;
    }

    return body;
  }

  getTransaction(transactionId: number): Observable<any> {
    const apiUrl: string = '/package_transaction/';
    this.dataService.isLoaderActive = true;

    return this.http.get(`${this.myAppUrl}${apiUrl}${transactionId}/`).pipe(
      tap({
        next: v => {
          this.dataService.isLoaderActive = false;
        },
        error: e => this.connection.requestErrorFunction(e),
      })
    );
  }

  patchTransaction(transactionId: number): Observable<any> {
    const apiUrl: string = '/package_transaction/';
    this.dataService.isLoaderActive = true;

    return this.http.patch(`${this.myAppUrl}${apiUrl}${transactionId}/`, {}).pipe(
      tap({
        next: v => {
          this.dataService.isLoaderActive = false;
        },
        error: e => this.connection.requestErrorFunction(e),
      })
    );
  }
}
