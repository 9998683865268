<div class="login-container">
    <div class="flex login-header">
        <div class="main-logo-container">
            <img src={{appConfig.logoUrl}} alt={{appConfig.clientName}}>
        </div>
        <div style="margin-left: 24px;"><span class="title" [ngClass]="{'dark': appConfig.darkTheme}">{{appConfig.clientName}}</span><span class="subtitle"> | {{appConfig.applicationName}}</span></div>
    </div>
    <div class="login-form-container">
        <div *ngIf="requestSent">
            <p>Your friend request was confirmed successfully!</p>
        </div>
    </div>
    <div style="margin-top: 24px;">
        <a [routerLink]="'/login'">Go back</a>
    </div>
</div>
<div class="login-bg" [ngStyle]="{'background-image': backgroundUrl}"></div>
<div class="overlay" [ngClass]="{'dark': appConfig.darkTheme}"></div>