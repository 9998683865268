<div style="margin: 16px">
  <div>
    <p>
      To purchase for your friends and family you must request permission by selecting Add and sending the request to
      their registered email address.<br />Once they have approved your request you will see them in the list below. You
      are able to add a maximum of 20 friends and family to your list.
    </p>
  </div>
</div>
<div class="full-container-wbar">
  <div class="row">
    <div class="col-lg-5 col-md-12 rounded-block half-block-custom">
      <div style="margin-bottom: 24px; display: flex; justify-content: space-between">
        <div class="bold"><span>Supporters you can purchase on behalf of:</span></div>
        <button (click)="addAssociationModal()" style="margin-left: 16px">+ Add</button>
      </div>
      <div>
        <div *ngFor="let association of customerAssociations" class="rounded-block list-element-container">
          <div class="flex flex-column">
            <span>{{ association.name }}</span>
            <span class="font-size-14">Supporter Number: {{ association.associate_id }}</span>
            <div
              *ngIf="association.tdc_info.memberships && association.tdc_info.memberships.length"
              class="list-element italic membership-level">
              <span
                >Loyalty Points Balance
                {{ association.tdc_info.memberships[0].loyalty_program?.current_point_balance }}</span
              >
            </div>
          </div>
          <button (click)="deleteAssociation(association.id)" class="delete-button">Delete</button>
        </div>
      </div>
    </div>
    <div
      class="col-lg-5 col-md-12 rounded-block half-block-custom"
      *ngIf="customerAssociationsInverse.length > 0; else noFriends">
      <div style="margin-bottom: 24px; height: 38px">
        <div class="bold"><span>Supporters who can purchase on your behalf:</span></div>
      </div>
      <div>
        <div class="rounded-block list-element-container" *ngFor="let friend of customerAssociationsInverse">
          <div class="flex flex-column">
            <span>{{ friend.name }}</span>
            <span class="font-size-14">Supporter Number: {{ friend.associate_id }}</span>
          </div>
          <button (click)="deletePermissionsAssociation(friend.associate_id, friend.id)" class="delete-button">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- NG TEMPLATES -->
<ng-template #noFriends>
  <div class="col-lg-5 col-md-12 rounded-block half-block-custom text-center">
    <span>No one can purchase on your behalf</span>
  </div>
</ng-template>
