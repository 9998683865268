<div class="pb-container container">
  <a [routerLink]="['/buy-tickets']">Go back</a>
  <div style="margin-bottom: 24px">
    <div class="content-title"><span>Reserved Tickets</span></div>
    <div class="content-subtitle">
      <span>Select friends or family you would like to claim and purchase tickets for:</span>
    </div>
  </div>
  <div *ngIf="userData">
    <div class="list-element-container rounded-block selected">
      <div class="list-element bold">
        <span>{{ userData.tdc_info.first_name }} </span><span>{{ userData.tdc_info.last_name }}</span>
      </div>
      <div class="list-element-button accent-color">Selected</div>
    </div>
  </div>
  <div *ngIf="customerAssociationsArray.length" class="bold" style="padding: 16px">
    <span>Renew tickets on behalf of:</span>
  </div>
  <div *ngIf="customerAssociationsArray.length">
    <div
      *ngFor="let association of customerAssociationsArray"
      class="list-element-container rounded-block"
      [ngClass]="{ selected: selectedFriends[association.associate_id] }"
      (click)="selectFriend(association.associate_id)">
      <div>
        <div class="list-element bold">
          <span>{{ association.tdc_info.first_name }} </span><span>{{ association.tdc_info.last_name }}</span>
        </div>
        <div>
          <span class="font-size-14">Supporter Number: {{ association.associate_id }}</span>
        </div>
        <div
          *ngIf="association.tdc_info.memberships && association.tdc_info.memberships.length"
          class="list-element italic membership-level">
          <span>{{ association.tdc_info.memberships[0].membership_level.description }}</span
          ><br />
          <span
            >Loyalty Points Balance:
            {{ association.tdc_info.memberships[0].loyalty_program?.current_point_balance }}</span
          >
        </div>
      </div>
      <div *ngIf="selectedFriends[association.associate_id]" class="list-element-button accent-color">Selected</div>
    </div>
  </div>
</div>
<div class="bottom-interface">
  <div style="margin-right: 16px">
    <span>{{ friendsSelectedCount }} friends selected</span>
  </div>
  <button (click)="continue()">Continue</button>
</div>
