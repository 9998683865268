<div>
    <div class="super-container">
      <div class="modal-header bold">
        <h4 class="pull-left">{{ title }}</h4>
      </div>
      <div *ngIf="!sent" class="modal-body">
        <p>Enter the email of the supporter you would like to purchase on behalf of:</p>
        <input [(ngModel)]="email" type="email">
      </div>
      <div *ngIf="sent" class="modal-body">
        <p>We have sent your friend request. If the associated email exists, you will see your new association in the list once the request has been accepted.</p>
      </div>
      <div class="modal-footer">
        <button *ngIf="closeBtnName" type="button" class="white-button" (click)="close()">{{closeBtnName}}</button>
        <button *ngIf="acceptBtnName && !sent" type="button" (click)="accept()" [disabled]="!email">{{acceptBtnName}}</button>
      </div>
    </div>
  </div>
