import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventModel } from '../../models';

@Component({
  selector: 'app-buy-generic',
  templateUrl: './buy-generic.component.html',
  styleUrls: ['./buy-generic.component.scss'],
})
export class BuyGenericComponent implements OnInit {
  fromTransaction: number;
  eventId: number;
  isAwayEvent: boolean; // Cuando un evento es en un away venue, no mostramos el mapa.
  associations = [];
  flowProcess: 'MEMBERSHIPS' | 'TICKETS'; // los memberships son 'non inventiored' events, no tienen asientos. No necesitamos mapa.
  event: EventModel;

  constructor(public dataService: DataService, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    // validate transaction param is number or null
    const transactionParam: any = this.activatedRoute.snapshot.params.transaction;
    this.fromTransaction = parseInt(transactionParam, 10) ? transactionParam : null;
    // Check event ID
    if (this.activatedRoute.snapshot.params.event) {
      this.eventId = parseInt(this.activatedRoute.snapshot.params.event, 10);
      this.event = this.dataService.eventsList[this.eventId];
      this.dataService.lastVenue = this.activatedRoute.snapshot.params.venue.toLowerCase();
      // Definimos que tipo de eventos es, los away no disponen de mapa, pero si price scale
      const availableStadiums = this.dataService.getVenues();
      this.isAwayEvent = !availableStadiums.includes(this.dataService.lastVenue);
      // Pillamos del query param la lista de asociaciones
      if (this.activatedRoute.snapshot.params.associations) {
        this.associations = this.activatedRoute.snapshot.params.associations.split(',');
      }
      // Usamos el resolver para saber que tipo de evento vamos a vender
      // si es membership, mostramos el proceso de compra para memberships, no dispone de mapa y no tiene price scale al uso.
      this.activatedRoute.data.subscribe(response => {
        this.flowProcess = response.processType;
      });
    } else {
      this.router.navigate(['buy-tickets']);
    }
  }
}
