<div class="login-container">
    <div class="flex login-header">
        <div class="main-logo-container">
            <img src={{appConfig.logoUrl}} alt={{appConfig.clientName}}>
        </div>
        <div style="margin-left: 24px;"><span class="title" [ngClass]="{'dark': appConfig.darkTheme}">{{appConfig.clientName}}</span><span class="subtitle"> | {{appConfig.applicationName}}</span></div>
    </div>
    <div class="login-form-container">

        <form *ngIf="!token" #LoginForm="ngForm" (ngSubmit)="sendEmail()">
            <div *ngIf="!requestSent" class="form flex f-column">
                <p>Enter your email address.<br>You will receive an email if the account exists. Please check your inbox or spam and follow the instructions.</p>
                <label for="InAccountId">Email</label>
                <input type="email" id="InAccountId" [(ngModel)]="username" name="username" required>
                <button type="submit" class="submit" [disabled]="!LoginForm.valid">Send Email</button>
            </div>
            <div *ngIf="requestSent">
                <p>Your request has been submitted.<br> <br> A matching account has been found with the email address provided. An email has been sent to you to complete the registration.  Check your inbox and follow the instructions to set your password.</p>
            </div>
        </form>

        <form *ngIf="token" #LoginForm="ngForm" (ngSubmit)="register()">
            <div *ngIf="!requestSent" class="form flex f-column">
                <p>Enter a new password:</p>
                <label for="InPassword">Password</label>
                <input type="password" id="InPassword" [(ngModel)]="password" name="password" required>
                <label for="InPassword">Confirm Password</label>
                <input type="password" id="InPassword" [(ngModel)]="password2" name="password" required>
                <button type="submit" class="submit" [disabled]="!LoginForm.valid || !(password && password2 && password === password2)">Reset my password</button>
            </div>
            <div *ngIf="requestSent">
                <p>New password created successfully!</p>
            </div>
        </form>

    </div>
    <div style="margin-top: 24px;">
        <a [routerLink]="'/login'">Back to login</a>
    </div>
</div>
<div class="login-bg" [ngStyle]="{'background-image': backgroundUrl}"></div>
<div class="overlay" [ngClass]="{'dark': appConfig.darkTheme}"></div>
