<div id="top-bar">
  <!-- back button -->
  <div class="icon-container cicle" (click)="backToTopview()">
    <span class="icon arrow-left-line2"></span>
  </div>
  <!-- section -->
  <div class="section-container">
    <span>{{ this.visibleSection }}</span>
  </div>
</div>
