<div class="list-element-container-wrap rounded-block" *ngIf="shouldNotShowEvent(event.id, event.venue)">
  <div class="mobile">
    <div class="list-element bold"><span>{{event.name}}</span></div>
    <div class="list-element"><span>{{event.venue}}</span></div>
  </div>
  <div class="list-element-container">
    <div class="list-element bold desktop"><span>{{event.name}}</span></div>
    <div class="list-element desktop"><span>{{event.venue}}</span></div>
    <div class="list-element"><span>{{event.date | date: 'dd/MM/yyyy hh:mm a'}}</span></div>
    <div routerLink="/exchange/select-a-friends" [queryParams]="{event: event.id, venue: event.venue, transaction: fromTransaction}" class="list-element-button"><button>Select</button></div>
  </div>
</div>
