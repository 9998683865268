import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FiservFormModel } from 'src/app/models/fiserv-form.model';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-payment-redirect-modal',
  templateUrl: './payment-redirect-modal.component.html',
  styleUrls: ['./payment-redirect-modal.component.scss'],
})
export class PaymentRedirectModalComponent implements OnInit {
  fiservFormData: FiservFormModel;
  userData;
  ready2Pay: boolean;

  constructor(public bsModalRef: BsModalRef, private connection: ConnectionService) {}

  ngOnInit(): void {}

  isTakeOver(): boolean {
    return this.userData.hasOwnProperty('admin_takeover');
  }
}
