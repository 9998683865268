import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class VwrRedirectInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req)
      .pipe(
        map(data => {
          return data;
        }),
        tap({
          next: (event) => {
          },
          error: (err: HttpErrorResponse) => {
            if (req.method === 'GET' && req.url.startsWith(environment.apiUrl)) {
              if (err.error?.error?.message.includes('JSON')) {
                location.reload();
              }
            }
          },
        })
      );
  }
}
