import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastr: ToastrService) {}

  config: any = {
    progressBar: true,
    progressAnimation: 'decreasing',
    positionClass: 'toast-top-right',
    preventDuplicates: true,
  };

  showSuccess(message, title?, config?): void {
    this.toastr.success(message, title, this.config);
  }
  showInfo(message, title?, config?): void {
    this.toastr.info(message, title, this.config);
  }
  showWarning(message, title?, config?): void {
    this.toastr.warning(message, title, this.config);
  }
  showError(message, title?, config?): void {
    this.toastr.error(message, title, this.config);
  }
}
