import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { UserIdleModule } from 'angular-user-idle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './layout/menu/menu.component';
import { HeaderComponent } from './layout/header/header.component';
import { LoginComponent } from './login/login.component';
import { RenewTicketsComponent } from './renew-tickets/renew-tickets.component';
import { BuyTicketsComponent } from './buy-tickets/buy-tickets.component';
import { FriendsFamilyComponent } from './friends-family/friends-family.component';
import { LayoutComponent } from './layout/layout.component';
import { EventsComponent } from './buy-tickets/events/events.component';
import { SelectFriendsComponent } from './buy-tickets/select-friends/select-friends.component';
import { SelectSeatsComponent } from './buy-tickets/select-seats/select-seats.component';
import { CheckoutComponent } from './buy-tickets/checkout/checkout.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CsrfInterceptor } from './auth/csrf.interceptor';
import { WithCredentialsInterceptor } from './auth/withcredentials.interceptor';
import { AuthService } from './services/auth.service';
import { SummaryComponent } from './summary/summary.component';
import { ModalsComponent } from './modals/modals.component';
import { DVM_CONFIG, DVMConfig } from './configuration/dvm.configuration';
import { RegisterComponent } from './login/register/register.component';
import { InformationComponent } from './information/information.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AddAssociationModalComponent } from './modals/add-association-modal/add-association-modal.component';
import { APP_CONFIG, AppConfig } from './configuration/app.configuration';
import { ConfigurationService } from './services/configuration.service';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { ConfirmAssociationComponent } from './friends-family/confirm-association/confirm-association.component';
import { PaymentRedirectModalComponent } from './modals/payment-redirect-modal/payment-redirect-modal.component';
import { CheckoutRenewComponent } from './renew-tickets/checkout/checkout.component';
import { SelectFriendsRenewComponent } from './renew-tickets/select-friends/select-friends.component';
import { SelectTicketsComponent } from './renew-tickets/select-tickets/select-tickets.component';
import { AdminLoginBannerComponent } from './admin-login-banner/admin-login-banner.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccountManagementComponent } from './account-management/account-management.component';
import { ToastrModule } from 'ngx-toastr';
import { ToastService } from './services/toast.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExchangeTicketsComponent } from './exchange-tickets/exchange-tickets.component';
import { MyEventsComponent } from './exchange-tickets/my-events/my-events.component';
import { MyTicketsComponent } from './exchange-tickets/my-tickets/my-tickets.component';
import { MembershipsComponent } from './buy-tickets/memberships/memberships.component';
import { BuyGenericComponent } from './buy-tickets/buy-generic/buy-generic.component';
import { ButtonMenuComponent } from './layout/button-menu/button-menu.component';
import { SelectAPathComponent } from './exchange-tickets/select-a-path/select-a-path.component';
import { SelectAEventComponent } from './exchange-tickets/select-a-event/select-a-event.component';
import { EventComponent } from './exchange-tickets/select-a-event/event/event.component';
import { SelectAFriendComponent } from './exchange-tickets/select-a-friend/select-a-friend.component';
import { SelectASeatComponent } from './exchange-tickets/select-a-seat/select-a-seat.component';
import { TopBarComponent } from './exchange-tickets/select-a-seat/top-bar/top-bar.component';
import { CheckoutExchangeComponent } from './exchange-tickets/checkout-exchange/checkout-exchange.component';
import { MapLegendComponent } from './exchange-tickets/select-a-seat/map-legend/map-legend.component';
import { SelectAPriceModalComponent } from './modals/select-a-price-modal/select-a-price-modal.component';
import { BankAccountComponent } from './exchange-tickets/my-events/bank-account/bank-account.component';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from './configuration/cookiebot.configuration';
import { NewAccountComponent } from './login/new-account/new-account.component';
import { ErrorComponent } from './shared/error/error.component';
import { AdyenModule } from './adyen/adyen.module';
import { AdyenPaymentModalComponent } from './modals/adyen-payment-modal.ts/adyen-payment-modal.component';
import { NgxPopperModule } from 'ngx-popper';
import { environment } from 'src/environments/environment';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { VwrRedirectInterceptor } from './shared/vwr-redirect.interceptor';
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    LoginComponent,
    RenewTicketsComponent,
    BuyTicketsComponent,
    FriendsFamilyComponent,
    LayoutComponent,
    EventsComponent,
    SelectFriendsComponent,
    SelectSeatsComponent,
    CheckoutComponent,
    SummaryComponent,
    ModalsComponent,
    RegisterComponent,
    InformationComponent,
    AddAssociationModalComponent,
    ResetPasswordComponent,
    ConfirmAssociationComponent,
    PaymentRedirectModalComponent,
    SelectFriendsRenewComponent,
    CheckoutRenewComponent,
    SelectTicketsComponent,
    AdminLoginBannerComponent,
    AccountManagementComponent,
    ExchangeTicketsComponent,
    MyEventsComponent,
    MyTicketsComponent,
    MembershipsComponent,
    BuyGenericComponent,
    ButtonMenuComponent,
    SelectAPathComponent,
    SelectAEventComponent,
    EventComponent,
    SelectAFriendComponent,
    SelectASeatComponent,
    TopBarComponent,
    CheckoutExchangeComponent,
    MapLegendComponent,
    SelectAPriceModalComponent,
    BankAccountComponent,
    NewAccountComponent,
    ErrorComponent,
    AdyenPaymentModalComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ModalModule.forRoot(),
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
    // and `ping` is 120 (2 minutes).
    UserIdleModule.forRoot({ idle: 1800, timeout: 1 }),
    TooltipModule,
    ToastrModule.forRoot(),
    NgxCookiebotModule.forRoot(CookiebotConfig),
    ReactiveFormsModule,
    AdyenModule,
    NgxPopperModule.forRoot({}),
    GoogleTagManagerModule.forRoot({
      id: environment.gtm.id,
    }),
  ],
  providers: [
    Title,
    AuthService,
    ToastService,
    { provide: DVM_CONFIG, useValue: DVMConfig },
    { provide: APP_CONFIG, useValue: AppConfig },
    { provide: HTTP_INTERCEPTORS, useClass: VwrRedirectInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService) => () => configService.init(),
      deps: [ConfigurationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
