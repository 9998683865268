import { Component, OnInit } from '@angular/core';
import { ModalsService } from '../modals/modals.service';
import { ConnectionService } from '../services/connection.service';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { CustomerAssociationInverseModel } from '../models';
import { environment } from '../../environments/environment';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-friends-family',
  templateUrl: './friends-family.component.html',
  styleUrls: ['./friends-family.component.scss'],
})
export class FriendsFamilyComponent implements OnInit {
  customerAssociations = [];
  customerAssociationsInverse: Array<CustomerAssociationInverseModel> = [];

  // Observables
  associationInverse$: Observable<any>;
  associationNormal$: Observable<any>;

  constructor(
    private connection: ConnectionService,
    public dataService: DataService,
    private modalService: ModalsService
  ) {}

  ngOnInit(): void {
    this.customerAssociations = Object.values(this.dataService.customerAssociations);
    this.getAssociations();
  }

  // El endpoint customer/associations/ lo utilizamos para obtener el 'inverse' (el normal no tiene los loyalty points)
  // Ejecutamos el customer/associations/complete/ para obtener los loyalty points
  getAssociations() {
    this.dataService.customerAssociations = {};
    this.dataService.customerAssociationsInverse = {};

    this.associationInverse$ = this.connection.getCustomerAssociations(false);
    this.associationNormal$ = this.connection.getCustomerAssociations(true);
    this.dataService.isLoaderActive = true;
    forkJoin([this.associationInverse$, this.associationNormal$]).subscribe({
      next: resp => {
        console.log(resp[0]);
        resp[0]['inverse'].forEach((association: any) => {
          this.dataService.customerAssociationsInverse[association.associate_id] = association;
        });
        resp[1].forEach((association: any) => {
          this.dataService.customerAssociations[association.associate_id] = association;
        });
        console.log(resp[1]);
        this.customerAssociationsInverse = Object.values(this.dataService.customerAssociationsInverse);
        this.customerAssociations = Object.values(this.dataService.customerAssociations);
        this.dataService.isLoaderActive = false;
        console.log(this.dataService.customerAssociations);
      },
      error: () => {
        this.dataService.isLoaderActive = false;
      },
    });
  }

  deleteAssociation(id) {
    const title = 'Are you sure?';
    const message =
      'Are you sure you want to delete your friend from the list? By removing your friend you will no longer be able to purchase tickets on behalf of this supporter.';
    const accept = 'Delete';
    const acceptFn = () => {
      console.log('delete association', id);
      this.connection.deleteCustomerAssociation(id).subscribe(
        () => {
          this.ngOnInit();
          this.messageSuccess();
        },
        error => console.error(error)
      );
    };
    const close = 'Cancel';
    this.modalService.info(title, message, accept, acceptFn, close);
  }

  addAssociationModal() {
    if (this.customerAssociations.length && this.customerAssociations.length >= 20) {
      this.modalService.info('Limit reached', 'You have reached the maximum of 20 friend associations.');
    } else {
      this.modalService.addAssociationModal();
    }
  }

  deletePermissionsAssociation(patronId: number, patronAssId: number): void {
    const title = 'Are you sure?';
    const message = 'Are you sure you want to delete your friend from the list?';
    const accept = 'Delete';
    const close = 'Cancel';
    const acceptFn = () => {
      this.connection.deleteCustomerAssociationInverse(patronId, patronAssId).subscribe(() => {
        this.messageSuccess();
        this.ngOnInit();
      });
    };
    this.modalService.info(title, message, accept, acceptFn, close);
  }

  messageSuccess() {
    let title = 'Completed';
    let message = 'You have removed the permissions correctly';
    this.modalService.info(title, message);
  }
}
