import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  private isRunning: boolean;

  constructor(private userIdle: UserIdleService, private router: Router) {
    this.isRunning = false;
  }

  public start(): any {
    this.userIdle.startWatching(); // Comenzamos a observar la actividad del usuario
    this.userIdle.onTimerStart().subscribe(() => {});
    this.isRunning = true;
  }

  private stopTimer(): void {
    this.userIdle.stopTimer();
  }

  public stop() {
    this.userIdle.stopWatching();
    this.isRunning = false;
  }

  private restartTimer() {
    this.userIdle.resetTimer();
  }

  public getIsRunning(): boolean {
    return this.isRunning;
  }

  public onTimeout(): Observable<boolean> {
    return this.userIdle.onTimeout();
  }
}
