import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { APP_CONFIG } from 'src/app/configuration/app.configuration';
import { AppConfiguration } from 'src/app/models/appconfiguration.model';
import { AuthService } from 'src/app/services/auth.service';
import { ModalsService } from '../../modals/modals.service';
import { User } from '../../models';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  user: User;
  isMenuOpen = false;
  cartWaiting = false;

  constructor(
    @Inject(APP_CONFIG) public appConfig: AppConfiguration,
    private auth: AuthService,
    private modalService: ModalsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.auth.getUserLogged$().subscribe(user => {
      if (typeof user === 'boolean') {
        return;
      }
      this.user = user;
      this.cartWaiting =
        this.user.sale_transactions.length > 0 ||
        this.user.membership_transactions.length > 0 ||
        this.user.exchange_transactions.length > 0 ||
        this.user.reservation_transactions.length > 0;
    });
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.toggleMenu(false);
        this.closeOverlyMobile();
      }
    });
  }

  toggleMenu(val) {
    this.isMenuOpen = val;
  }

  logout() {
    const title = 'Continue?';
    const message = 'Are you sure you want to log out?';
    const accept = 'Log Out';
    const close = 'Back';
    this.modalService.info(
      title,
      message,
      accept,
      () => {
        this.auth.logout().subscribe(
          response => {
            console.log(response);
            window.location.reload();
          },
          error => {
            console.error(error);
          }
        );
      },
      close
    );
  }

  closeOverlyMobile(): void {
    let visibleClass = 'visible';
    let buttonClass = 'change';
    const el = document.getElementById('overlay-menu');
    const burgerEl = document.getElementById('hamburguer');
    if (el.classList.contains(visibleClass)) {
      el.classList.remove(visibleClass);
      burgerEl.classList.remove(buttonClass);
    }
  }

  openCart(): void {
    if (this.user.sale_transactions.length > 0) {
      this.router.navigate([
        '/buy-tickets/checkout',
        { transaction: this.user.sale_transactions[0].id, flow: 'tickets' },
      ]);
    } else if (this.user.exchange_transactions.length > 0) {
      this.router.navigate(['/exchange/checkout'], {
        queryParams: { transaction: this.user.exchange_transactions[0].id },
      });
    } else if (this.user.membership_transactions.length > 0) {
      this.router.navigate([
        '/buy-tickets/checkout',
        { transaction: this.user.membership_transactions[0].id, flow: 'memberships' },
      ]);
    } else if (this.user.reservation_transactions.length > 0) {
      this.router.navigate(['/renew-tickets/checkout', { transaction: this.user.reservation_transactions[0].id }]);
    }
  }
}
