import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { AdyenService } from '../adyen.service';

@Component({
  selector: 'adyen-drop-in',
  templateUrl: './adyen-drop-in.component.html',
  styleUrls: ['./adyen-drop-in.component.scss'],
})
export class AdyenDropInComponent implements OnChanges {
  @Input() sessionId: string;
  @Input() sessionData: string;
  @Input() returnUrl: string;
  @ViewChild('hook', { static: true }) hook: ElementRef;

  constructor(private adyenService: AdyenService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // if we have sessionId and sessionData initialize payment session
    // https://docs.adyen.com/online-payments/build-your-integration?platform=Web&integration=Drop-in&version=5.43.0&tab=npm_recommended__1#initialize-the-payment-session
    if (changes.sessionId.currentValue && changes.sessionData.currentValue) {
      this.adyenService.startPaymentSession(this.sessionId, this.sessionData, this.returnUrl);
    }
  }
}
