<div>
    <div class="super-container">
      <div class="modal-body">
        <p>You will be redirected to an external website to make the payment.</p>
      </div>
      <div class="modal-footer">
        <div class="container" *ngIf="this.fiservFormData">
            <form id="checkoutForm" method="post" ngNoForm action='{{this.fiservFormData.formActionUrl}}'>
              <input type="hidden" name="txntype" value="{{this.fiservFormData.txntype}}"/>
              <input type="hidden" name="timezone" value="{{this.fiservFormData.timezone}}"/>
              <input type="hidden" name="txndatetime" value="{{this.fiservFormData.txndatetime}}"/>
              <input type="hidden" name="hash_algorithm" value="SHA256"/>
              <input type="hidden" name="hash" value="{{this.fiservFormData.hashExtended}}"/>
              <input type="hidden" name="storename" value="{{this.fiservFormData.storename}}"/>
              <input type="hidden" name="chargetotal" value="{{this.fiservFormData.amount}}"/>
              <input type="hidden" name="currency" value="{{this.fiservFormData.currency}}"/>
              <input type="hidden" name="oid" value="{{this.fiservFormData.oid}}"/>
              <input type="hidden" name="customParam_transaction" value="{{this.fiservFormData.transactionId}}"/>
              <input type="hidden" name="responseSuccessURL" value="{{this.fiservFormData.responseSuccessURL}}"/>
              <input type="hidden" name="responseFailURL" value="{{this.fiservFormData.responseFailURL}}"/>
              <input *ngIf="this.fiservFormData.transactionNotificationURL"
               type="hidden" name="transactionNotificationURL" value="{{this.fiservFormData.transactionNotificationURL}}"/>
              <input *ngIf="isTakeOver() else combinedpage" type="hidden" name="checkoutoption" value="classic" />
              <ng-template #combinedpage>
                <input type="hidden" name="checkoutoption" value="combinedpage" />
              </ng-template>
              <ng-container *ngIf="isTakeOver()">
                <input type="hidden" name="trxOrigin" value="MOTO">
                <input type="hidden" name="mode" value="payplus">
              </ng-container>
              <!-- <input type="hidden" name="customParam_client" value="{{this.configurationService.client}}"/> -->
              <input type="submit" class="button" value="Continue to payment">
            </form>
        </div>
      </div>
    </div>
  </div>
