import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guards
import { AuthGuard } from './auth/auth.guard';

// Components
import { BuyTicketsComponent } from './buy-tickets/buy-tickets.component';
import { CheckoutComponent } from './buy-tickets/checkout/checkout.component';
import { EventsComponent } from './buy-tickets/events/events.component';
import { SelectFriendsComponent } from './buy-tickets/select-friends/select-friends.component';
import { ConfirmAssociationComponent } from './friends-family/confirm-association/confirm-association.component';
import { FriendsFamilyComponent } from './friends-family/friends-family.component';
import { InformationComponent } from './information/information.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { LoginAuthGuard } from './login/login.guard';
import { RegisterComponent } from './login/register/register.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { CheckoutRenewComponent } from './renew-tickets/checkout/checkout.component';
import { RenewTicketsComponent } from './renew-tickets/renew-tickets.component';
import { SelectFriendsRenewComponent } from './renew-tickets/select-friends/select-friends.component';
import { SelectTicketsComponent } from './renew-tickets/select-tickets/select-tickets.component';
import { SummaryComponent } from './summary/summary.component';
import { AccountManagementComponent } from './account-management/account-management.component';
import { CheckEventResolverService } from './buy-tickets/check-event-resolver.service';
import { BuyGenericComponent } from './buy-tickets/buy-generic/buy-generic.component';
import { ExchangeTicketsComponent } from './exchange-tickets/exchange-tickets.component';
import { MyEventsComponent } from './exchange-tickets/my-events/my-events.component';
import { MyTicketsComponent } from './exchange-tickets/my-tickets/my-tickets.component';
import { SelectAPathComponent } from './exchange-tickets/select-a-path/select-a-path.component';
import { SelectAEventComponent } from './exchange-tickets/select-a-event/select-a-event.component';
import { SelectAFriendComponent } from './exchange-tickets/select-a-friend/select-a-friend.component';
import { SelectASeatComponent } from './exchange-tickets/select-a-seat/select-a-seat.component';
import { EventsResolverService } from './exchange-tickets/resolvers/events-resolver.service';
import { CheckoutExchangeComponent } from './exchange-tickets/checkout-exchange/checkout-exchange.component';
import { NewAccountComponent } from './login/new-account/new-account.component';
import { ErrorComponent } from './shared/error/error.component';
import { BuyPackagesComponent } from './buy-packages/buy-packages.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/my-account',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'buy-tickets',
        component: BuyTicketsComponent,
        children: [
          {
            path: '',
            component: EventsComponent,
            data: {
              title: 'Buy Tickets',
            },
          },
          {
            path: 'select-friends',
            component: SelectFriendsComponent,
            data: {
              title: 'Select Friends',
            },
          },
          {
            path: 'select-seats',
            component: BuyGenericComponent,
            resolve: { processType: CheckEventResolverService },
            data: {
              title: 'Select Seats',
            },
          },
          {
            path: 'checkout',
            component: CheckoutComponent,
            data: {
              title: 'Checkout',
            },
          },
        ],
      },
      {
        path: 'renew-tickets',
        component: RenewTicketsComponent,
        children: [
          {
            path: '',
            component: SelectFriendsRenewComponent,
            data: {
              title: 'Select Friends',
            },
          },
          {
            path: 'select-tickets',
            component: SelectTicketsComponent,
            data: {
              title: 'Select Tickets',
            },
          },
          {
            path: 'checkout',
            component: CheckoutRenewComponent,
            data: {
              title: 'Renew Checkout',
            },
          },
        ],
      },
      {
        path: 'friends-family',
        component: FriendsFamilyComponent,
        data: {
          title: 'Friends and Family',
        },
      },
      {
        path: 'my-account',
        component: AccountManagementComponent,
        data: {
          title: 'My Account',
        },
      },
      {
        path: 'summary/:status',
        component: SummaryComponent,
        data: {
          title: 'Summary',
        },
      },
      {
        path: 'information',
        component: InformationComponent,
        data: {
          title: 'Information',
        },
      },
      {
        path: 'exchange',
        component: ExchangeTicketsComponent,
        children: [
          {
            path: '',
            component: SelectAPathComponent,
            data: {
              title: 'Exchange Dashboard',
            },
          },
          {
            path: 'my-events',
            component: MyEventsComponent,
            data: {
              title: 'My Events',
            },
          },
          {
            path: 'tickets',
            component: MyTicketsComponent,
            data: {
              title: 'My Exchange Tickets',
            },
          },
          {
            path: 'select-a-event',
            component: SelectAEventComponent,
            data: {
              title: 'Select Event',
            },
          },
          {
            path: 'select-a-friends',
            component: SelectAFriendComponent,
            data: {
              title: 'Select Friend',
            },
          },
          {
            path: 'select-a-seat',
            component: SelectASeatComponent,
            resolve: { loadEvent: EventsResolverService },
            data: {
              title: 'Select Seat',
            },
          },
          {
            path: 'checkout',
            component: CheckoutExchangeComponent,
            data: {
              title: 'Exchange Checkout',
            },
          },
        ],
      },
      {
        path: 'buy-packages',
        component: BuyPackagesComponent,
        loadChildren: () => import('./buy-packages/buy-packages.module').then(m => m.BuyPackagesModule),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
    },
    canActivate: [LoginAuthGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register',
    },
    canActivate: [LoginAuthGuard],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password',
    },
    canActivate: [LoginAuthGuard],
  },
  {
    path: 'association',
    data: {
      title: 'Confirm Association',
    },
    component: ConfirmAssociationComponent,
  },
  {
    path: 'new-account',
    component: NewAccountComponent,
    data: {
      title: 'New Account',
    },
    canActivate: [LoginAuthGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      title: 'Error',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
