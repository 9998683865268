<div class="hamburger-container mobile">
  <div *ngIf="!open" class="menuMobile">
    <span class="menuTitle">MENU</span>
  </div>
  <div id="hamburguer" class="burg-button rounded-circle" #burg (click)="openOverlay(burg)">
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
  </div>
</div>
