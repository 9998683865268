import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '../configuration/app.configuration';
import { AppConfiguration } from '../models/appconfiguration.model';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
//import { ModalsService } from 'src/app/modals/modals.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  username;
  password;
  backgroundUrl;

  loginError = false;
  msgError: string = '';

  constructor(
    @Inject(APP_CONFIG) public appConfig: AppConfiguration,
    private auth: AuthService,
    private dataService: DataService,
    //private modalService: ModalsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.backgroundUrl = `url(${this.appConfig.backgroundImageUrl})`;
  }

  login() {
    // subscribe to the login method on the auth service
    this.dataService.isLoaderActive = true;
    this.auth.login({ username: this.username, password: this.password }).subscribe(
      success => {
        this.dataService.isLoaderActive = false;
        this.router.navigate(['/']);
      },
      error => {
        this.dataService.isLoaderActive = false;
        this.loginError = true;
        this.msgError = 'Username or password incorrect.';

        if (error.error && error.error.message) {
          this.msgError = error.error.message;
        }
        console.error(error);
      }
    );
  }
}
