<div class="pb-container container">
  <div style="margin-bottom: 24px">
    <a [routerLink]="['/exchange/']">Go back</a>
  </div>
  <div>
    <div style="margin-bottom: 24px">
      <app-bank-account
        (isVerifided)="isVerifidedAccount($event)"
        [conn]="connection"
        [patronId]="user.tdc_info.id"></app-bank-account>
    </div>
    <div>
      <div style="margin-bottom: 24px">
        <div class="content-title"><span>My Events</span></div>
        <div class="content-subtitle">
          <span
            >Please click on the View Tickets option to access your tickets. From here you can List/Delist your
            ticket</span
          ><br />
        </div>
      </div>
      <div class="list-header-container desktop">
        <div class="list-element bold"><span>Event</span></div>
        <div class="list-element bold"><span>Venue</span></div>
        <div class="list-element bold"><span>Date & Time</span></div>
        <div class="list-element-button"><span></span></div>
      </div>
      <div>
        <ng-container *ngFor="let event of myEventsList">
          <div class="list-element-container-wrap rounded-block" *ngIf="event.tickets_count > 0">
            <div class="mobile">
              <div class="list-element bold">
                <span>{{ event.name }}</span>
              </div>
              <div class="list-element">
                <span>{{ event.venue }}</span>
              </div>
            </div>
            <div class="list-element-container">
              <div class="list-element bold desktop">
                <span>{{ event.name }}</span>
              </div>
              <div class="list-element desktop">
                <span>{{ event.venue }}</span>
              </div>
              <div class="list-element">
                <span>{{ event.date | date : 'dd/MM/yyyy hh:mm a' }}</span>
              </div>
              <div
                *ngIf="isAccountVerifided"
                class="list-element-button"
                [routerLink]="['../tickets']"
                [queryParams]="{ event: event.id }">
                <button>View Tickets</button>
              </div>
              <div *ngIf="!isAccountVerifided" class="list-element">
                <span>You can only View / List Tickets once your account has been verified. Non-UK Supporters please contact us.</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
