import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { APP_CONFIG } from 'src/app/configuration/app.configuration';
import { SubCountryDataModel } from 'src/app/models';
import { AppConfiguration } from 'src/app/models/appconfiguration.model';
import { TDCInfoModel, UserTDCAddress, UserTDCPhone } from 'src/app/models/user.model';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/utils/utilities.service';
import { NewAccountService } from '../new-account.service';
import { Router } from '@angular/router';
import { DataProtection, NewAccount } from './new-account';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss'],
})
export class NewAccountComponent implements OnInit {
  backgroundUrl: string = '';
  tdcInfo: TDCInfoModel;
  listStatesSpecial: SubCountryDataModel[] = [];
  newAccountForm: UntypedFormGroup;
  submitted: boolean = false;
  email: string = '';
  requestSent: boolean = false;

  suscriptionNewAccount: Subscription;

  constructor(
    @Inject(APP_CONFIG) public appConfig: AppConfiguration,
    public dataService: DataService,
    private fb: UntypedFormBuilder,
    private utilitiesService: UtilitiesService,
    private newAccountService: NewAccountService,
    private router: Router,
    private connection: ConnectionService
  ) {
    this.backgroundUrl = `url(${this.appConfig.backgroundImageUrl})`;
    this.suscriptionNewAccount = this.newAccountService.getEmail().subscribe(data => {
      this.email = data;
    });

    this.newAccountForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(30)]],
      lastName: ['', [Validators.required, Validators.maxLength(30)]],
      birthday: ['', [Validators.required]],
      gender: [null, [Validators.required]],
      phone: this.fb.group({
        number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(25)]],
        phone_country_code: ['GBR', [Validators.required]],
      }),
      address: this.fb.group({
        address1: ['', [Validators.required, Validators.maxLength(50)]],
        address2: ['', [Validators.maxLength(50)]],
        postalCode: ['', [Validators.required, Validators.maxLength(20)]],
        address_country_code: ['GBR', [Validators.required]],
        address_sub_country_code: [''],
        address_sub_country_name: ['', Validators.maxLength(20)],
        city: ['', [Validators.required, Validators.maxLength(15)]],
      }),
      email: this.fb.group({
        email_address: [{ value: 'juancarlos@gmail.com', disabled: true }],
      }),
      password: this.fb.group(
        {
          password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
          repeatPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
        },
        {
          validator: this.utilitiesService.matchPassword('password', 'repeatPassword'),
        }
      ),
      dataProtection: this.fb.group({
        email_channel: [false],
        mail_channel: [false],
        phone_channel: [false],
        sms_text_channel: [false],
      }),
    });
  }

  ngOnInit(): void {
    this.setEmail();
  }

  setEmail() {
    if (this.email !== '') {
      this.newAccountForm.patchValue({
        email: {
          email_address: this.email,
        },
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  setSubCountryCodeDefault(event: any): void {
    const countryCode: string = event.target.value;

    if (this.dataService.subCountryDictionary[countryCode]) {
      this.listStatesSpecial = this.dataService.subCountryDictionary[countryCode];
      this.setState(this.listStatesSpecial);
    } else {
      this.setState(null);
      this.listStatesSpecial = [];
    }
  }

  setState(listStatesSpecial: SubCountryDataModel[] | null) {
    if (listStatesSpecial == null) {
      this.newAccountForm.patchValue({
        address: {
          address_sub_country_code: null,
        },
      });
    } else {
      this.newAccountForm.patchValue({
        address: {
          address_sub_country_code: this.listStatesSpecial[0].sub_country_code,
        },
      });
    }
  }

  saveUser() {
    this.submitted = true;
    if (this.newAccountForm.invalid) {
      return;
    }

    const newAccount: NewAccount = this.getUserObject();

    this.connection.newAccount(newAccount).subscribe(data => {
      this.requestSent = true;
      /*      setTimeout(() => {
        this.router.navigate(['/login'])
      }, 5000); */
    });
  }

  getUserObject(): NewAccount {
    const phone: UserTDCPhone = {
      number: this.number.value,
      phone_country_code: this.phone_country_code.value,
    };

    const address: UserTDCAddress = {
      address1: this.addressLine1.value,
      address2: this.addressLine2.value,
      postal_code: this.postalCode.value,
      address_country_code: this.address_country_code.value,
      address_sub_country_code: this.address_sub_country_code.value,
      address_sub_country_name: this.address_sub_country_name.value,
      city: this.city.value,
    };

    const dataprotection: DataProtection = {
      email_channel: this.email_channel?.value,
      mail_channel: this.mail_channel?.value,
      phone_channel: this.phone_channel?.value,
      sms_text_channel: this.sms_text_channel?.value,
    };

    const dataProtectionList: DataProtection[] = [];
    dataProtectionList.push(dataprotection);

    const newAccount: NewAccount = {
      first_name: this.firstName.value,
      last_name: this.lastName.value,
      gender: this.gender.value,
      birthday: this.birthday.value,
      phone: phone,
      password: this.password.value,
      email: {
        email_address: this.email,
      },
      address: address,
      dataprotection: dataProtectionList,
    };
    return newAccount;
  }

  get firstName() {
    return this.newAccountForm.get('firstName');
  }

  get lastName() {
    return this.newAccountForm.get('lastName');
  }

  get gender() {
    return this.newAccountForm.get('gender');
  }

  get birthday() {
    return this.newAccountForm.get('birthday');
  }

  get number() {
    return this.newAccountForm.get('phone').get('number');
  }

  get phone_country_code() {
    return this.newAccountForm.get('phone').get('phone_country_code');
  }

  get addressLine1() {
    return this.newAccountForm.get('address').get('address1');
  }

  get addressLine2() {
    return this.newAccountForm.get('address').get('address2');
  }

  get postalCode() {
    return this.newAccountForm.get('address').get('postalCode');
  }

  get city() {
    return this.newAccountForm.get('address').get('city');
  }

  get address_sub_country_name() {
    return this.newAccountForm.get('address').get('address_sub_country_name');
  }

  get address_country_code() {
    return this.newAccountForm.get('address').get('address_country_code');
  }

  get address_sub_country_code() {
    return this.newAccountForm.get('address').get('address_sub_country_code');
  }

  get password() {
    return this.newAccountForm.get('password').get('password');
  }

  get repeatPassword() {
    return this.newAccountForm.get('password').get('repeatPassword');
  }

  get email_channel() {
    return this.newAccountForm.get('dataProtection').get('email_channel');
  }

  get mail_channel() {
    return this.newAccountForm.get('dataProtection').get('mail_channel');
  }

  get phone_channel() {
    return this.newAccountForm.get('dataProtection').get('phone_channel');
  }

  get sms_text_channel() {
    return this.newAccountForm.get('dataProtection').get('sms_text_channel');
  }
}
